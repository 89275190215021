import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import imgClear from '../../../assets/images/clear-subtract.png';
import imgUpArrow from '../../../assets/images/new-dropdown-up-arrow.png';
import imgDownArrow from '../../../assets/images/new-dropdown-arrow.png';
import imgSuccess from '../../../assets/images/tick-blue.png';
import imgEmptyBucket from '../../../assets/images/img-empty-bucket.png';
import leftChevron from '../../../assets/images/left-chevron.png';
import beforeClear from '../../../assets/images/clear-white.png';
import beforeSuccess from '../../../assets/images/tick-white.png';
import rightChevron from '../../../assets/images/right-chevron.png';
import styles from './multidropdown.module.scss';
import { checkArrayNotNull, checkNotNull } from '../../../Helpers/common-helpers';
// import { checkNotNull } from '../../../Helpers/common-helpers';
import { createWorkOrderStrings, multiDropdownStrings } from '../../../constants/strings';
// import { multiDropdownStrings } from '../../../constants/strings';

function NoItems(childElement) {
  return (
    <div
      className={`${styles.additionallevel} d-flex flex-column justify-content-center align-items-center gap-3`}
    >
      <div>
        <img src={imgEmptyBucket} alt="V2" width="32px" height="24px" />
      </div>
      <div className={`${styles.additionaltxt}`}>
        {childElement === 'elements' ? createWorkOrderStrings.ADDITIONAL_WBS_TEXT : createWorkOrderStrings.ADDITIONAL_FLOC_TEXT}
      </div>
    </div>
  );
}
function MultiDropdown({
  data,
  selectedObject,
  name,
  updateValues,
  updateFlocValue,
  value,
  editable,
  required,
  childElement,
  childName,
  style,
}) {
  // const globalReducer = useSelector((state) => state.globalReducer);
  const selectedobjectValue = [
    { name: data[0][childName].split("-")[0], [childElement]: data, value: data[0][childName].split("-")[0] },
  ];

  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [selectedvalue, setSelectedValue] = useState(selectedobjectValue);
  const [dropdownData, setDropdownData] = useState([]);
  const [equipmentdata, setEquipmentdata] = useState([]);
  const [displaylevel, setDisplaylevel] = useState(false);
  const toggleDropdown = () => {
    setDropdownStatus(!dropdownStatus);
    if (
      !selectedvalue[selectedvalue.length - 1][childElement]
      && selectedvalue.length > 1
    ) {
      setDisplaylevel(true);
    }
    if (
      selectedvalue[selectedvalue.length - 1][childElement]?.filter(
        (item) => item.category !== multiDropdownStrings.FE,
      ).length === 0
    ) {
      setDisplaylevel(true);
    }
  };
  const filterObj = (filterData) => {
    if (childElement === 'elements') {
      setDropdownData(filterData);
      setDisplaylevel(false);
      setSelectedValue(selectedobjectValue);
    } else {
      setDisplaylevel(false);
      if (selectedObject?.value?.length > 1) {
        const x = filterData !== undefined
          ? filterData.filter((e) => selectedObject.value.includes(e.value))
          : '';

        if (x.length > 0) {
          selectedobjectValue.push(x[0]);
          setSelectedValue(selectedobjectValue);
        }
        const y = filterData !== undefined
          ? filterData.filter((e) => e.value === selectedObject.value)
          : '';
        if (filterData !== undefined) {
          for (let i = 0; i < filterData.length; i++) {
            if (y.length > 0) {
              const flocData = y[0][childElement]?.filter(
                (item) => item.category !== multiDropdownStrings.FE,
              );
              if (flocData !== undefined) {
                setDropdownData(flocData);
              }
            } else if (Array.isArray(filterData[i][childElement])) {
              filterObj(filterData[i][childElement]);
            }
          }
        }
      } else {
        setDropdownData(data);
        setSelectedValue(selectedobjectValue);
      }
    }
  };
  useEffect(() => {
    filterObj(data);
    // eslint-disable-next-line
  }, [data]);

  const itemClicked = (clickedData, clickedItem, index) => {
    if (index === 0) {
      setDisplaylevel(false);
      setDropdownData(clickedData);
      selectedvalue.splice(index + 1);
    } else {
      const clickedFilterObj = clickedData.filter((e) => e[childName] === clickedItem[childName]);
      for (let i = 0; i < clickedData.length; i++) {
        if (clickedFilterObj.length > 0) {
          if (Array.isArray(clickedFilterObj[0][childElement])) {
            const x = clickedFilterObj[0][childElement].filter(
              (item) => item.category !== multiDropdownStrings.FE,
            );
            setEquipmentdata(
              clickedFilterObj[0][childElement]?.filter(
                (item) => item.category === multiDropdownStrings.FE,
              ),
            );
            setDisplaylevel(false);
            setDropdownData(x);
            selectedvalue.splice(index + 1);
          } else {
            setDisplaylevel(true);
            selectedvalue.splice(index + 1);
          }
        } else if (Array.isArray(clickedData[i][childElement])) {
          itemClicked(clickedData[i][childElement], clickedItem, index);
        }
      }
    }
  };
  const clearFirstItem = () => {
    updateValues([], []);
    const obj = {
      key: multiDropdownStrings.FUNCTIONAL_LOCATION,
      value: '',
      name: multiDropdownStrings.FUNCTIONAL_LOCATION,
    };
    updateFlocValue(obj);
    filterObj(data);
    setDisplaylevel(false);
  };
  const displayValue = () => {
    const obj = {
      key: multiDropdownStrings.FUNCTIONAL_LOCATION,
      value: selectedvalue[selectedvalue.length - 1].value || selectedvalue[selectedvalue.length - 1].wbsElement,
      name: checkNotNull(selectedvalue[selectedvalue.length - 1][childName])
        ? selectedvalue[selectedvalue.length - 1][childName]
        : selectedvalue[selectedvalue.length - 1].label,
    };

    updateFlocValue(obj);
    setDropdownStatus(false);
    updateValues(selectedvalue, equipmentdata);
  };

  const onPress = (pressedItem) => {
    setSelectedValue(selectedvalue.concat(pressedItem));
    if (Array.isArray(pressedItem[childElement])) {
      setEquipmentdata(
        pressedItem[childElement].filter((item) => item.category === multiDropdownStrings.FE),
      );
      const x = pressedItem[childElement].filter(
        (item) => item.category !== multiDropdownStrings.FE,
      );
      setDropdownData(x);
      if (x.length === 0) {
        setDisplaylevel(true);
      }
    } else {
      setDropdownData([]);
      setDisplaylevel(true);
    }
  };
  function generateDropdownItems() {
    const x = dropdownData !== undefined
      ? dropdownData.map((item) => (
        (
          <div
            className="d-flex justify-content-between  pl-3 pt-1 pr-1 pb-1"
            onClick={() => onPress(item)}
            role="presentation"
            key={item[childName]}
          >
            {item[childName]}
            <img
              src={rightChevron}
              alt="RightChevron"
              height="28px"
              width="24px"
            />
          </div>
        )
      ))
      : '';
    return x;
  }
  const clearItem = () => {
    const obj = {
      key: multiDropdownStrings.FUNCTIONAL_LOCATION,
      value: '',
      name: '',
    };
    updateFlocValue(obj);
    setSelectedValue();
    filterObj(data);
    setEquipmentdata([]);
    setDisplaylevel(false);
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdownStatus(false);
      }}
    >
      <div className={`${styles.dropDownContainer} `} style={{ ...style }}>
        <p className={`${styles.labelText} p-0 m-0`}>
          {name}
          {editable && required && (
            <span className={`${styles.asterikRequired}`}>
              {multiDropdownStrings.ASTERIK}
            </span>
          )}
        </p>
        {editable && checkArrayNotNull(data) ? (
          <div
            className={`${styles.dropdownView} justify-content-between non-selectable ${dropdownStatus ? styles.dropdownPickerViewWidth : styles.dropdownViewWidth}`}
            style={{
              position: dropdownStatus ? 'absolute' : 'relative',
              boxShadow: `inset 0px 0px 5px ${dropdownStatus ? '#0578BE' : 'rgba(0, 0, 0, 0.25)'}`,
              ...style,
            }}
          >
            <div
              className="d-flex justify-content-between "
              onClick={toggleDropdown}
              role="presentation"
            >
              <p className={`${styles.ddvalueText} m-0 pl-1`}>
                {checkNotNull(selectedObject?.value) ? selectedObject?.name : ''}
              </p>
              <div className="flex-col pr-2">
                <img
                  src={dropdownStatus ? imgUpArrow : imgDownArrow}
                  alt="dropdown-arrow"
                  height={7}
                  width={11}
                  className="mb-2"
                />
              </div>
            </div>
            {dropdownStatus && (
              <div className={`${styles.dropdownList}`}>
                <div className={`${styles.middleitem}`}>
                  {selectedvalue.length === 0 ? (
                    <div className="d-flex flex-row align-items-center">
                      <div className={`${styles.noSelection}`}>
                        no selection
                      </div>
                      <div className="d-flex gap-3 mr-3 ml-auto">
                        <div onClick={clearItem} role="presentation" onKeyDown={(e) => { if (e.keyCode === 13) beforeClear(); }}>
                          <img
                            src={beforeClear}
                            alt="clear-icon"
                            height="18px"
                            width="17px"
                          // onKeyDown={(e) => { if (e.keyCode === 13) beforeClear(); }}
                          />
                        </div>
                        <div onClick={displayValue} role="presentation" onKeyDown={(e) => { if (e.keyCode === 13) displayValue(); }}>
                          <img
                            src={beforeSuccess}
                            alt="success icon"
                            height="18px"
                            width="17px"
                          // onKeyDown={(e) => { if (e.keyCode === 13) displayValue(); }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-row gap-3  ml-2">
                        <div onClick={clearFirstItem} role="presentation" onKeyDown={(e) => { if (e.keyCode === 13) clearFirstItem(); }}>
                          <img
                            className={`${styles.leftChevron}`}
                            src={leftChevron}
                            alt="leftchevron"
                            height="16px"
                            width="14px"
                          />
                        </div>
                        <div className={`${styles.links}`}>
                          {checkArrayNotNull(selectedvalue)
                            ? selectedvalue.map((item, index) => (
                              (
                                <span
                                  onClick={() => itemClicked(data, item, index)}
                                  role="presentation"
                                  key={item.value + index}
                                >
                                  {index === 0
                                    ? item.value.split('-')[index]
                                    : `- ${item.value ? item.value.split('-')[index] : item[childName]}`}
                                </span>
                              )
                            ))
                            : ''}
                        </div>
                      </div>
                      <div className="d-flex  flex-row gap-3 mr-3">
                        <div onClick={clearFirstItem} role="presentation" onKeyDown={(e) => { if (e.keyCode === 13) clearFirstItem(); }}>
                          <img
                            className={`${styles.leftChevron}`}
                            src={imgClear}
                            alt="clear-icon"
                            height="16px"
                            width="14px"
                          />
                        </div>
                        <div onClick={displayValue} role="presentation" onKeyDown={(e) => { if (e.keyCode === 13) displayValue(); }}>
                          <img
                            className={`${styles.leftChevron}`}
                            src={imgSuccess}
                            alt="success-icon"
                            height="16px"
                            width="14px"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="dropdown-divider ml-2 mr-2 mt-0" />
                {displaylevel ? <NoItems childElement={childElement} /> : generateDropdownItems()}
              </div>
            )}
          </div>
        ) : (
          <p className={`${styles.nameText} m-0`}>{value}</p>
        )}
      </div>
    </OutsideClickHandler>
  );
}

MultiDropdown.propTypes = {
  data: PropTypes.instanceOf(Array),
  editable: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  selectedObject: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  updateFlocValue: PropTypes.func,
  updateValues: PropTypes.func,
  value: PropTypes.string,
  childElement: PropTypes.string,
  childName: PropTypes.string,
  style: PropTypes.shape({}),
};

MultiDropdown.defaultProps = {
  data: [],
  editable: false,
  name: '',
  required: false,
  selectedObject: PropTypes.shape({
    name: '',
    value: '',
  }),
  updateFlocValue: () => { },
  updateValues: () => { },
  value: '',
  childElement: '',
  childName: '',
  style: {},
};

export default MultiDropdown;
