import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';
import { productCatalogStrings } from '../../../constants/strings';
import styles from './product-catalog-modal.styles';
import imgCloseModal from '../../../assets/images/close-modal@2x.png';
// import { appColors } from '../../../constants/colors';
import imgGoSelected from '../../../assets/images/go-selected@2x.png';
import imgGo from '../../../assets/images/go@2x.png';
import imgToolbox from '../../../assets/images/toolbox@2x.png';
import WorkOrderTile from '../work-order-tile/work-order-tile.component';
import CustomButton from '../custom-button';
import { HttpGet } from '../../../services/api-services';
import { MATERIAL_SEARCH } from '../../../constants/endpoints';
import { getURL, checkArrayNotNull} from '../../../Helpers/common-helpers';

function ProductCatalogModal({
  onProductCatalogSearch, plantId, closePCModal, index,
}) {
  const [productText, setProductText] = React.useState('');
  const [materialItems, setMaterialItems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [isLoading, setLoadingStatus] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageItems, setPageItems] = React.useState([]);
  const [fetchFailed, setFetchFailed] = React.useState(false);
  const resetState = () => {
    setMaterialItems([]);
    setPageCount(1);
    setCurrentPage(1);
    setPageItems([]);
    setFetchFailed(false);
  };
  const onTileClick = () => {
    onProductCatalogSearch(index, materialItems[index]);
  };
  const getRenderItem = (item) => {
    const onItemClick = () => {
      onTileClick();
    };
    return <WorkOrderTile item={item} onTileClick={onItemClick} />;
  };
  function generateList() {
    return pageItems.map((item) => getRenderItem(item));
  }
  const onSearchClick = async (pageNumber) => {
    try {
      setLoadingStatus(true);
      setFetchFailed(false);
      const apiURL = `${getURL(MATERIAL_SEARCH)}/${productText}/catalogs`;
      const params = {
        plant: plantId,
        page: pageNumber,
      };
      const result = await HttpGet(apiURL, params); 
      if (checkArrayNotNull(result.data.message.data.materials)) {
        const items = result.data.message.data.materials;
        // const pageCount = result.data.message.data.totalPages;
        setMaterialItems(items);
        setPageItems(items);
        setPageCount(result.data.message.data.totalPages);
        setLoadingStatus(false);
      } else {
        throw new Error("No Materials Found.")
      }   
     } catch (e) {
      resetState();
      setFetchFailed(true);
      setLoadingStatus(false);
    }
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onSearchClick(pageNumber);
    // setPageItems(materialItems.slice((pageNumber - 1) * 7, (pageNumber * 7)))
  };
  const onNextPress = () => {
    onPageChange(currentPage + 1);
  };
  const onPreviousPress = () => {
    onPageChange(currentPage - 1);
  };
  const onTextInputChange = (val) => {
    resetState();
    setProductText(val);
  };
  const generatePageUi = () => {
    const pageUiArray = [];
    for (let i = 1; i <= pageCount; i++) {
      const onPageItemClick = () => {
        onPageChange(i);
      };
      if (
        i === 1
        || i === pageCount
        || (currentPage === 1 && i <= 5)
        || (currentPage === 2 && i <= 5)
        || (currentPage === 3 && i <= 5)
        || currentPage === i
        || Math.abs(currentPage - i) <= 2
      ) {
        pageUiArray.push(
          <CustomButton
            value={`${i}`}
            outline={currentPage !== i}
            customStyle={{
              marginRight: 10,
              width: 45,
              height: 45,
              padding: 5,
              backgroundColor: currentPage !== i ? 'white' : '#0578be',
            }}
            customTextStyle={{ fontSize: 12 }}
            onPress={onPageItemClick}
          />,
        );
      } else if (i === 2 || i === pageCount - 1) {
        pageUiArray.splice(
          i - 1,
          0,
          <CustomButton
            value="..."
            outline
            customStyle={{
              marginRight: 10, width: 45, height: 45, padding: 5,
            }}
            customTextStyle={{ fontSize: 12 }}
          />,
        );
      }
    }
    return pageUiArray;
  };
  return (
    <Modal
      isOpen
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.90)',
          zIndex: 500000,
        },
        content: {
          backgroundColor: 'rgba(255, 255, 255, 0.0001)',
          borderWidth: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <div style={styles.modalContainer}>
        <div style={styles.modalView}>
          <div style={styles.titleView}>
            <p style={styles.titleText}>{productCatalogStrings.TITLE}</p>
            <div style={styles.closeButton} onClick={closePCModal} role="presentation">
              <img
                src={imgCloseModal}
                alt="close-modal"
                style={styles.imgClose}
              />
            </div>
          </div>
          {isLoading ? (
            <div style={styles.loaderContainer}>
              <Loader
                type="Oval"
                color="#0578BE"
                height={40}
                width={40}
                visible
              />

              <p
                style={{
                  marginTop: 20,
                  fontFamily: 'OpenSans-Regular',
                  fontSize: '20px',
                }}
                className="d-flex align-items-center justify-content-center"
              >
                {productCatalogStrings.SEARCH_MATERIAL}
              </p>
            </div>
          ) : (
            <div style={styles.contentView}>
              <div style={styles.searchContainer}>
                <p style={styles.searchText}>
                  {`${productCatalogStrings.SEARCH} `}
                  <span style={styles.description}>
                    {`${productCatalogStrings.SEARCH_INSTRUCTION}`}
                  </span>
                </p>
                <div>
                  <div style={styles.searchView}>
                    <div style={styles.textInputContainer}>
                      <input
                        type="text"
                        style={styles.textInput}
                        placeholder={productCatalogStrings.SEARCH_FOR_MATERIAL}
                        value={productText}
                        onChange={(e) => onTextInputChange(e.target.value)}
                        // returnKeyType="search"
                        // placeholderTextColor={
                        //   appColors.TEXT_DARK_GRAYISH_BLUE
                        // }
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onSearchClick(1);
                          }
                        }}
                      />
                    </div>
                    {productText.length < 5 ? (
                      <div style={styles.goButton}>
                        <img
                          src={imgGo}
                          alt="go-disabled"
                          style={styles.imgIcon}
                        />
                      </div>
                    ) : (
                      <div
                        style={styles.goButton}
                        onClick={() => onSearchClick(1)}
                        role="presentation"
                      >
                        <img
                          src={imgGoSelected}
                          alt="go"
                          style={styles.imgIcon}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={styles.resultsView}>
                <p style={styles.resultText}>
                  {`${productCatalogStrings.RESULTS} (${materialItems?.length || 0})`}
                </p>
              </div>
              {!fetchFailed ? (
                <>
                  <div
                    style={{
                      maxHeight: window.innerHeight / 2 - 50,
                      overflow: 'scroll',
                    }}
                  >
                    {generateList()}
                  </div>
                  {pageItems.length > 1 ? (
                    <div
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 10,
                        display: 'flex',
                      }}
                    >
                      <CustomButton
                        outline
                        disabled={currentPage === 1}
                        value={productCatalogStrings.PREVIOUS}
                        customStyle={{ width: 90, padding: 10 }}
                        onPress={onPreviousPress}
                      />
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {generatePageUi()}
                      </div>
                      <CustomButton
                        outline
                        value={productCatalogStrings.NEXT}
                        disabled={currentPage === pageCount}
                        customStyle={{ width: 90, padding: 10 }}
                        onPress={onNextPress}
                      />
                    </div>
                  ) : (
                    <div style={{ height: 100 }} />
                  )}
                </>
              ) : (
                <div style={styles.failureView}>
                  <img
                    src={imgToolbox}
                    alt="toolbox"
                    style={styles.toolboxIcon}
                  />
                  <p style={styles.failureText}>
                    {`${productText} ${productCatalogStrings.FAILURE_TEXT_1}`}
                  </p>
                  <p style={styles.failureText}>
                    {productCatalogStrings.FAILURE_TEXT_2}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ProductCatalogModal;

ProductCatalogModal.propTypes = {
  closePCModal: PropTypes.func,
  onProductCatalogSearch: PropTypes.func,
  plantId: PropTypes.string,
  index: PropTypes.number,
};

ProductCatalogModal.defaultProps = {
  closePCModal: () => { },
  onProductCatalogSearch: () => { },
  plantId: '',
  index: null,
};
