import React from 'react';
import info from '../../../assets/images/info.svg';
import './spares-management.css';
import download from '../../../assets/images/cloud-download.svg';
import externalRepairIcon from '../../../assets/images/external-spares.svg';
import { newPageStrings } from '../../../constants/strings';
import success from '../../../assets/images/success.png';
import trash from '../../../assets/images/delete-red.svg';

export const SapMatlRenderer = (params) => (
    <div className="d-flex gap-3">
        <p className="viewEntries" role="presentation" onClick={() => params.onSapMaterialClick(params.value)}>
            {params.value}
        </p>
        {params.value && <img src={info} alt="info" width="15px" height="15px" style={{ marginTop: '13px' }} />}
    </div>
);

export const TypeRenderer = (params) => (
    <p>{params.data.name.split('.')[params.data.name.split('.').length - 1]}</p>
);

export const ColourCellRenderer = (params) => (
    <div className="border2">
        <span>{(params.value)}</span>
    </div>
);

export const LinkRenderer = (params) => (
    <p className="viewEntries" onClick={() => params.onLinkClick(params)} role="presentation">
        {newPageStrings.VIEW_ENTRIES}
    </p>
);

export const ExternalRepairRenderer = (params) => (
    <div className="d-flex gap-3">
        <p className="viewEntries" role="presentation" onClick={() => params.onMaterialDataClick(params)}>
            {newPageStrings.VIEW_ENTRIES}
        </p>
        <img src={externalRepairIcon} alt="info" width="15px" height="15px" style={{ marginTop: 13 }} />
    </div>
);

export const DownloadRenderer = (params) => (
    <img
        src={download}
        onClick={() => params.onDownloadClick(params)}
        height="16px"
        width="16px"
        alt="Upload"
        role="presentation"
    />
);

export const QtyRequiredRenderer = (params) => (
    params.value && (
        <p>
            {params.value}
            <span className="valueDescription">{params.data.uom_id}</span>
        </p>
    )
);

export const QtyOnHandrenderer = (params) => (
    params.value && (
        <p>
            {params.value}
            <span className="valueDescription">{params.data.uom_id}</span>
        </p>
    )
);

export const NameRenderer = (params) => {
    const value = params.data?.stores_item?.split('/').length ? params.data.stores_item.split('/')[params.data.stores_item.split('/').length - 1] : params.data.name;
    return (
        <p>{value}</p>
    );
};

export const IconRenderer = (params) => (
    params.value ? (
        <img src={success} alt="success" height="18" width="18" />
    ) : (
        '-'
    )
);

export const DeliveryDateRenderer = (params) => {
    const year = params.data.deliveryDate.substring(0, 4);
    const month = params.data.deliveryDate.substring(4, 6);
    const day = params.data.deliveryDate.substring(6, 8);
    return <p>{day}-{month}-{year}</p>;
};
export const DeleteRendererforAdd = (params) => (
    <img
        src={trash}
        onClick={() => params.deleteAttachment(params.api.gos.gridOptions.gridId, params.rowIndex)}
        height="16px"
        width="16px"
        alt="Delete"
        role="presentation"
    />
);