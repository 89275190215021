import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import ListOperations from '../list-operations';
import SingleOperation from '../list-operations/single-operation.component';
import ConfirmationContent from './confirmation-content.component';
import { handleSingleConfirmation } from '../../pages/single-confirmation/single-confirmation.helper';
import { showLoader, hideLoader } from '../../../redux/actions/global.actions';
import { getWorkOrderResults } from '../../pages/work-confirm/work-confirm.helper';
import { useQueryClient } from 'react-query';
import { mountLocalWork } from '../../pages/week-schedule/week-schedule.helper';

export default function ConfirmationList({ orders = [] }) {
  return (
    <div style={{ height: 'calc(100% - 100px)' }}>
      {
        orders.map((data, index) => {
          const dateString = data[0] === format(new Date(), 'MM/dd/yyyy') ? `Today - ${format(new Date(), 'MMMM dd, yyyy')}` : format(new Date(data[0]), 'MMMM dd, yyyy');
          return (
            <SingleDay
              key={index}
              date={dateString}
              orders={data[1]}
              startOpen={data[0] === format(new Date(), 'MM/dd/yyyy')}
            />
          );
        })
      }
    </div>
  );
}

ConfirmationList.propTypes = {
  orders: PropTypes.array,
};

ConfirmationList.defaultProps = {
  orders: null,
};

function SingleDay({ date, startOpen, orders }) {
  const dispatch = useDispatch();
  const hasOperations = orders.length !== 0;
  const queryClient = useQueryClient();
  const globalReducer = useSelector(state => state.globalReducer);
  const handleSearch = async (woNumber = null) => {
    try {
      dispatch(showLoader('Fetching Confirmation Data'));
      const searchWOValue = woNumber;
      const data = await queryClient.fetchQuery({
        queryKey: searchWOValue,
        queryFn: async () => {
          const response = await getWorkOrderResults(globalReducer.plantId, searchWOValue);
          return response;
        },
        retry: 2,
        staleTime: 1000 * 60 * 30,
      });
      return data;
    } catch (error) {
      console.log(error, 'e');
    } finally {
      dispatch(hideLoader());
    }
  };
  const handleSingleConfirmationClick = async (orderDetails) => {
    try {
      let newData = await handleSearch(orderDetails.orderId.slice(-8));
      if(newData) {
        let operationsData = newData?.operations.filter(item => item.activity == orderDetails.operationActivity)[0];
        let confimationData = await mountLocalWork(globalReducer.userData, {...newData, systemStatus: newData.sysStatus}, operationsData, orderDetails);
        handleSingleConfirmation(dispatch, { ...confimationData , activity: orderDetails.operationActivity }, true);
      }

    } catch (e) {
      console.log(e, 'e');

    }
  }

  return (
    <ListOperations
      key={date + startOpen}
      collabsable
      startClose={!startOpen}
      title={{
        text: date,
        color: startOpen ? '#003366' : '#83929B',
      }}
    >
      {
        hasOperations
        && orders.map((order) => (
            <SingleOperation
              key={order.orderId + order.confirmationStartDate}
              onClick={() => handleSingleConfirmationClick(order)}
            >
              <ConfirmationContent
                data={{
                  title: `Order ${order.orderId} - ${order.shortDescription}`,
                  operation: `Operation ${order.operationActivity} - ${order.operationShortText}`,
                  createdDate: `${order.confirmationStartDate} - ${order.confirmationStartTime}`,
                  finishDate: `${order.confirmationFinishDate} - ${order.confirmationFinishTime}`,
                  longText: order.longText,
                }}
              />
            </SingleOperation>
          ))
      }
    </ListOperations>
  );
}

SingleDay.propTypes = {
  date: PropTypes.string,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  startOpen: PropTypes.bool,
};

SingleDay.defaultProps = {
  date: '',
  orders: [],
  startOpen: false,
};
