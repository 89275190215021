import React from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../../controls/title-header';

function ContentWithBreadcrumb({ customHeight, children, title }) {
  const style = {
    paddingTop: '100px',
  };

  if (customHeight) {
    style.height = customHeight;
  }
  return (
    <div className="row gx-0 no-gutters m-0 gx-0 gy-0" style={style}>
      {title && <TitleHeader value={title} />}
      {children}
    </div>
  );
}

ContentWithBreadcrumb.propTypes = {
  customHeight: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
};

ContentWithBreadcrumb.defaultProps = {
  children: null,
  customHeight: '',
  title: '',
};

export default ContentWithBreadcrumb;
