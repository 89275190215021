import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueries, useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  getWeekScheduleResults,
  getBacklogOrders,
  getFacilWorkOrders,
  getOrdersCreatedByMe,
} from '../../pages/week-schedule/week-schedule.helper';
import {
  groupScheduleData,
  groupScheduleDataByCreatedBy,
} from './week-schedule.helper';
import { loaderMessages, weekScheduleStrings, queryKeyStrings } from '../../../constants/strings';
import SCHEDULE from '../../pages/week-schedule/week-schedule.strings';
import ScheduleList from '../schedule-list';
import EmptyList from '../empty-list';
import styles from './week-schedule.module.scss';
import PageLoader from '../page-loader';
import { getSAPName, checkNotNull } from '../../../Helpers/common-helpers';
import { updateLastUpdatedTime } from '../../../redux/actions/global.actions';

function WeekSchedule({ tab }) {
  const globalReducer = useSelector((state) => state.globalReducer);
  const {
    plantId,
    userPersonalData,
    userData,
    facilPlannerData,
    lastUpdatedDate,
  } = globalReducer;
  const { personalNo, workCenter } = userPersonalData;
  const [activeLabel, setActiveLabel] = useState(
    facilPlannerData.length > 0 ? 'assignedToMyWc' : 'person',
  );
  const [loadingSchedule, setLoadingSchedule] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (facilPlannerData.length > 0) {
      setActiveLabel(weekScheduleStrings.ASSIGNED_TO_WC);
    } else {
      setActiveLabel(weekScheduleStrings.PERSON);
    }
  }, [facilPlannerData]);

  const facilSchedule = useQuery({
    queryKey: `${queryKeyStrings.FACILSCHEDULEV1}-${personalNo}-${userData?.mail}-${plantId}`,
    queryFn: async () => {
      const response = await getFacilWorkOrders(
        {
          plant: parseInt(plantId, 10),
        },
        facilPlannerData,
      );
      return response;
    },
    enabled:
      plantId !== null
      && personalNo !== ''
      && tab === weekScheduleStrings.MY_SCHEDULE
      && userData !== null
      && facilPlannerData.length > 0,
    staleTime: 1000 * 60 * 10,
    onSettled: () => setLoadingSchedule(''),
    cacheTime: 1000 * 60 * 10,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const personalSchedule = useQuery({
    queryKey: `${queryKeyStrings.PERSONALSCHEDULEV1}-${personalNo}-${userData?.mail}-${plantId}`,
    queryFn: async () => {
      const response = await getWeekScheduleResults({
        plant: parseInt(plantId, 10),
        pernr: parseInt(personalNo, 10),
      });
      return response;
    },
    enabled:
      plantId !== null
      && personalNo !== ''
      && tab === 'My Schedule'
      && userData !== null
      && facilPlannerData.length === 0,
    onSettled: () => setLoadingSchedule(''),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
    retry: 0,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 15,
    refetchOnWindowFocus: false,
  });
  const backlogSchedule = useQuery({
    queryKey: `${queryKeyStrings.BACKLOGSCHEDULE}-${personalNo}-${userData?.mail}-${plantId}`,
    queryFn: async () => {
      const response = await getBacklogOrders({
        workCenter: workCenter[0],
        plantId: parseInt(plantId, 10),
      });
      return response;
    },
    onError: () => setLoadingSchedule(''),
    onSettled: () => setLoadingSchedule(''),
    enabled: plantId !== null && personalNo !== '' && checkNotNull(workCenter) && checkNotNull(workCenter[0]),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const ordersCreatedByMe = useQuery({
    queryKey: `${queryKeyStrings.ORDERSCREATEDBYMEV1}-${personalNo}-${userData?.mail}-${plantId}`,
    queryFn: async () => {
      const response = await getOrdersCreatedByMe({
        // user:
        //   userPersonalData.sapUserID
        //   || getSAPName(userData.givenName, userData.surname),
        user: getSAPName(userData.displayName.split(' ')[0], userData.displayName.split(' ')[1]),
        plantId: parseInt(plantId, 10),
      });
      return response;
    },
    onSettled: () => setLoadingSchedule(''),
    enabled: plantId !== null && tab === 'My Schedule' && userData !== null,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
    retry: 0,
    refetchOnWindowFocus: false,
  });
  const workcenterSchedule = useQueries(
    workCenter.map((wc) => ({
      queryKey: wc,
      queryFn: async () => {
        try {
          let response = [];
          setLoadingSchedule('workCenterSchedule');
          response = await getWeekScheduleResults({
            wrkCenter: wc,
            plant: Number(plantId),
          });
          setLoadingSchedule('');
          return response;
        } catch (e) {
          setLoadingSchedule('');
          return e;
        }
      },
      enabled:
        plantId !== null
        && personalNo !== ''
        && checkNotNull(wc)
        && facilPlannerData.length === 0,
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60 * 10,
      retry: 0,
      refetchOnWindowFocus: false,
    })),
  );

  useEffect(() => {
    const dates = [
      lastUpdatedDate,
      facilSchedule.dataUpdatedAt,
      facilSchedule.errorUpdatedAt,
      personalSchedule.dataUpdatedAt,
      backlogSchedule.dataUpdatedAt,
      ordersCreatedByMe.dataUpdatedAt,
      personalSchedule.errorUpdatedAt,
      backlogSchedule.errorUpdatedAt,
      ordersCreatedByMe.errorUpdatedAt,
    ];
    dispatch(updateLastUpdatedTime(Math.max.apply(null, dates)));
    // eslint-disable-next-line
  }, [
    facilSchedule.dataUpdatedAt,
    personalSchedule.dataUpdatedAt,
    backlogSchedule.dataUpdatedAt,
    ordersCreatedByMe.dataUpdatedAt,
    facilSchedule.errorUpdatedAt,
    personalSchedule.errorUpdatedAt,
    backlogSchedule.errorUpdatedAt,
    ordersCreatedByMe.errorUpdatedAt,
    plantId,
  ]);

  useEffect(() => {
    if (personalSchedule.isFetching) {
      setLoadingSchedule('personalSchedule');
    } else if (loadingSchedule === 'personalSchedule') {
      setLoadingSchedule('');
    }
    // eslint-disable-next-line
  }, [personalSchedule.isFetching, loadingSchedule]);

  useEffect(() => {
    if (facilSchedule.isFetching) {
      setLoadingSchedule('facilSchedule');
    } else if (loadingSchedule === 'facilSchedule') {
      setLoadingSchedule('');
    }
    // eslint-disable-next-line
  }, [facilSchedule.isFetching, loadingSchedule]);

  useEffect(() => {
    if (ordersCreatedByMe.isFetching) {
      setLoadingSchedule('ordersCreatedByMe');
    } else if (loadingSchedule === 'ordersCreatedByMe') {
      setLoadingSchedule('');
    }
    // eslint-disable-next-line
  }, [ordersCreatedByMe.isFetching, loadingSchedule]);

  useEffect(() => {
    if (backlogSchedule.isFetching) {
      setLoadingSchedule('backlogSchedule');
    } else if (loadingSchedule === 'backlogSchedule') {
      setLoadingSchedule('');
    }
    // eslint-disable-next-line
  }, [backlogSchedule.isFetching, loadingSchedule]);

  // useEffect(() => {
  //   console.log(workcenterSchedule.filter((wc) => wc.isFetching === true), "wokcenter length")
  //   if (workcenterSchedule.filter((wc) => wc.isFetching === true).length !== 0) {
  //     setLoadingSchedule('workcenterSchedule');
  //   } else if (loadingSchedule === 'workcenterSchedule') {
  //     setLoadingSchedule('');
  //   }
  // }, [workcenterSchedule, loadingSchedule]);

  const query = () => {
    if (tab === weekScheduleStrings.MY_SCHEDULE) {
      if (facilPlannerData.length > 0) {
        if (activeLabel === weekScheduleStrings.CREATEDBYME) {
          return ordersCreatedByMe;
        }
        return facilSchedule;
      }
      if (activeLabel === weekScheduleStrings.PERSON) {
        return personalSchedule;
      }
      if (activeLabel === weekScheduleStrings.CREATEDBYME) {
        return ordersCreatedByMe;
      }
      return workcenterSchedule;
    }
    return backlogSchedule;
  };

  return (
    <>
      {tab === weekScheduleStrings.MY_SCHEDULE && (
        <FilterToogle
          activeLabel={activeLabel}
          disableFilter={workCenter[0] === null}
          onToogleCallback={(filter) => setActiveLabel(filter)}
          facilPlannerData={facilPlannerData}
        />
      )}
      {checkNotNull(loadingSchedule) ? (
        <PageLoader
          message={loaderMessages.WEEK_SCHEDULE}
          style={{ height: 350 }}
        />
      ) : (
        <ActiveList
          isFollowUpBacklog={tab === weekScheduleStrings.FOLLOW_UP_BACKLOG}
          facilPlannerData={
              tab === weekScheduleStrings.MY_SCHEDULE
              && facilPlannerData.length > 0
              && facilPlannerData
            }
          isFacilSchedule={
              tab === weekScheduleStrings.MY_SCHEDULE
              && facilPlannerData.length > 0
              && activeLabel === weekScheduleStrings.ASSIGNED_TO_WC
            }
            // query={
            //   tab === weekScheduleStrings.MY_SCHEDULE
            //     ? facilPlannerData.length > 0
            //       ? activeLabel === weekScheduleStrings.CREATEDBYME
            //         ? ordersCreatedByMe
            //         : facilSchedule
            //       : activeLabel === weekScheduleStrings.PERSON
            //         ? personalSchedule
            //         : activeLabel === weekScheduleStrings.CREATEDBYME
            //           ? ordersCreatedByMe
            //           : workcenterSchedule
            //     : backlogSchedule
            // }
          query={query()}
        />
      )}
    </>
  );
}

WeekSchedule.propTypes = {
  tab: PropTypes.string,
};
WeekSchedule.defaultProps = {
  tab: '',
};

function FilterToogle({
  activeLabel,
  disableFilter,
  onToogleCallback,
  facilPlannerData,
}) {
  const TOOGLE_DATA = {
    person: {
      label: weekScheduleStrings.ASSIGNED_TO_ME,
      visible: facilPlannerData?.length === 0,
      borderRadius: 'filter-toggle_borderRadius_first',
    },
    assignedToMyWc: {
      label: weekScheduleStrings.ASSIGNED_TO_MY_WORK_CENTERS,
      visible: facilPlannerData?.length > 0,
      borderRadius: 'filter-toggle_borderRadius_middle',
    },
    workcenter: {
      label: weekScheduleStrings.MY_WORK_CENTER,
      visible: facilPlannerData?.length === 0,
      borderRadius: 'filter-toggle_borderRadius_middle',
    },
    createdByMe: {
      label: weekScheduleStrings.CREATED_BY_ME,
      visible: true,
      borderRadius: 'filter-toggle_borderRadius_last',
    },
  };
  return (
    <div className="row gx-0 gx-0 my-3 ml-4">
      {Object.keys(TOOGLE_DATA).map((key) => {
        if (TOOGLE_DATA[key].visible) {
          return (
            <div
              className={`col-auto ${disableFilter && 'disabled'}`}
              key={key}
            >
              <span
                onClick={
                  key === 'workcenter' && disableFilter
                    ? () => { }
                    : () => onToogleCallback(key)
                }
                className={`${styles.filterToogle__text} ${
                  activeLabel === key ? styles.filterToogle__textActive : ''
                } ${
                  key === 'workcenter' && disableFilter
                    ? styles.filterToogle__textDisabled
                    : TOOGLE_DATA[key].borderRadius
                } p-2 `}
                role="presentation"
              >
                {TOOGLE_DATA[key].label}
              </span>
            </div>
          );
        }
        return '';
      })}
    </div>
  );
}

FilterToogle.propTypes = {
  activeLabel: PropTypes.string,
  disableFilter: PropTypes.bool,
  facilPlannerData: PropTypes.instanceOf(Array),
  onToogleCallback: PropTypes.func,
};

FilterToogle.defaultProps = {
  activeLabel: '',
  disableFilter: false,
  onToogleCallback: () => { },
  // facilPlannerData: PropTypes.shape({
  //   length: null,
  // }),
  facilPlannerData: [],
};

function ActiveList({
  query,
  facilPlannerData,
  isFacilSchedule,
  isFollowUpBacklog = false,
}) {
  const [allData, setallData] = useState(undefined);
  useEffect(() => {
    let newData;
    if (Array.isArray(query) && query[0].isSuccess) {
      newData = { ...query[0] };
      newData.data = [];
      query.forEach((q) => {
        newData.data = [...newData.data, ...q.data];
      });
    } else {
      newData = { ...query };
    }
    setallData(newData);
  }, [query]);

  if (allData?.isSuccess && allData?.data.length !== 0) {
    return (
      <ScheduleList
        isFacilSchedule={isFacilSchedule}
        revisions={
          isFollowUpBacklog
            ? groupScheduleDataByCreatedBy(allData.data)
            : groupScheduleData(allData.data)
        }
        facilPlannerData={facilPlannerData}
      />
    );
  }
  return <EmptyList message={SCHEDULE.emptyScreen.text} />;
}

ActiveList.propTypes = {
  facilPlannerData: PropTypes.bool,
  isFacilSchedule: PropTypes.bool,
  isFollowUpBacklog: PropTypes.bool,
  isSuccess: PropTypes.bool,
  // query: PropTypes.shape({
  //   forEach: PropTypes.func,
  // }),
  query: PropTypes.shape({
    status: PropTypes.string,
    forEach: PropTypes.func,
  }),
};

ActiveList.defaultProps = {
  query: {},
  facilPlannerData: [],
  isFacilSchedule: false,
  isFollowUpBacklog: false,
  isSuccess: false,
};

export default WeekSchedule;
