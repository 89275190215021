export const globalActionTypes = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  AAD_LOGIN_SUCCESS: 'AAD_LOGIN_SUCCESS',
  AAD_LOGOUT_SUCCESS: 'AAD_LOGOUT_SUCCESS',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_PLANT_ID: 'UPDATE_PLANT_ID',
  UPDATE_SELECTED_PAGE: 'UPDATE_SELECTED_PAGE',
  UPDATE_SIDE_NAV_STATUS: 'UPDATE_SIDE_NAV_STATUS',
  UPDATE_PROFILE_PIC: 'UPDATE_PROFILE_PIC',
  UPDATE_NEW_WO_CONFIRM: 'UPDATE_NEW_WO_CONFIRM',
  SET_LOGIN_REDIRECTION: 'SET_LOGIN_REDIRECTION',
  SET_PARENT_FOLLOW_UP_ORDER: 'SET_PARENT_FOLLOW_UP_ORDER',
  ADD_BREAD_CRUMB: 'ADD_BREAD_CRUMB',
  UPDATE_BREAD_CRUMB: 'UPDATE_BREAD_CRUMB',
  RESET_BREAD_CRUMB: 'RESET_BREAD_CRUMB',
  UPDATE_LAST_UPDATED_TIME: 'UPDATE_LAST_UPDATED_TIME',
  DELETE_BREAD_CRUMB: 'DELETE_BREAD_CRUMB',
  UPDATE_PERSONNAL_DATA: 'UPDATE_PERSONNAL_DATA',
  UPDATE_ACTIVE_TAB: 'UPDATE_ACTIVE_TAB',
  UPDATE_GLOBAL_SEARCH_ORDER: 'UPDATE_GLOBAL_SEARCH_ORDER',
  UPDATE_FACIL_PLANNER_DATA: 'UPDATE_FACIL_PLANNER_DATA',
  DATA_FROM_SPARES: 'DATA_FROM_SPARES',
  CURRENT_USER_DATA: 'CURRENT_USER_DATA',
};

export const sparesManagementTypes = {
  UPDATE_TRANSACTIONS: 'UPDATE_TRANSACTIONS',
};

export const createWorkOrdersTypes = {
  UPDATE_VALUE: 'UPDATE_VALUE',
  UPDATE_WRK_CTR_ITEMS: 'UPDATE_WRK_CTR_ITEMS',
  UPDATE_WO_VALUE: 'UPDATE_WO_VALUE',
  UPDATE_DROP_DOWN_ITEM: 'UPDATE_DROP_DOWN_ITEM',
  UPDATE_PICKER_OPEN_STATUS: 'UPDATE_PICKER_OPEN_STATUS',
  RESET_CREATE_WORK_ORDER_STATE: 'RESET_CREATE_WORK_ORDER_STATE',
  UPDATE_WO_SUCCESS_STATUS: 'UPDATE_WO_SUCCESS_STATUS',
  UPDATE_FUNC_LOC_2: 'UPDATE_FUNC_LOC_2',
  UPDATE_WO_FAILURE_STATUS: 'UPDATE_WO_FAILURE_STATUS',
  UPDATE_TYPE: 'UPDATE_TYPE',
  UPDATE_PARENT_ORDER_ID: 'UPDATE_PARENT_ORDER_ID',
  UPDATE_KITTING_STATUS: 'UPDATE_KITTING_STATUS',
  UPDATE_MULTI_SELECT: 'UPDATE_MULTI_SELECT',
  UPDATE_MATERIAL: 'UPDATE_MATERIAL',
  RESET_RESERVE_LINE_ITEM: 'RESET_RESERVE_LINE_ITEM',
  ADD_RESERVE_LINE_ITEM: 'ADD_RESERVE_LINE_ITEM',
  DELETE_RESERVE_LINE_ITEM: 'DELETE_RESERVE_LINE_ITEM',
  UPDATE_RESERVE_MATERIALS: 'UPDATE_RESERVE_MATERIALS',
  CHECKBOX_STATUS: 'CHECKBOX_STATUS',
  UPDATE_SECTIONAL_MULTI_SELECT: 'UPDATE_SECTIONAL_MULTI_SELECT',
  CLEAR_RESERVED_MATERIALS: 'CLEAR_RESERVED_MATERIALS',
  UPDATE_EQUIPMENT: 'UPDATE_EQUIPMENT',
  UPDATE_FLOC_VALUE: 'UPDATE_FLOC_VALUE',
  DATA_FROM_SPARES: 'DATA_FROM_SPARES',
};

export const eStoresTypes = {
  UPDATE_MATERIAL: 'E_STORES_UPDATE_MATERIAL',
  RESET_RESERVE_LINE_ITEM: 'E_STORES_RESET_RESERVE_LINE_ITEM',
  ADD_RESERVE_LINE_ITEM: 'E_STORES_ADD_RESERVE_LINE_ITEM',
  DELETE_RESERVE_LINE_ITEM: 'E_STORES_DELETE_RESERVE_LINE_ITEM',
  UPDATE_RESERVE_MATERIALS: 'E_STORES_UPDATE_RESERVE_MATERIALS',
  E_STORES_UPDATE_ORDER: 'E_STORES_UPDATE_ORDER',
  E_STORES_UPDATE_ATT_ORDER: 'E_STORES_UPDATE_ATT_ORDER',
  RESET_E_STORES_STATE: 'RESET_E_STORES_STATE',
  GET_WBS_DATA: 'GET_WBS_DATA',
  WBS_VALUE: 'WBS_VALUE',
};

export const reservationTypes = {

};

export const weekScheduleTypes = {
  UPDATE_SCHEDULE_ORDER: 'UPDATE_SCHEDULE_ORDER',
};

export const workConfirmationTypes = {
  UPDATE_WORK_CONFIRMATION: 'UPDATE_WORK_CONFIRMATION',
  RESET_WORK_CONFIRMATION: 'RESET_WORK_CONFIRMATION',
  UPDATE_PERSONNEL_LIST: 'UPDATE_PERSONNEL_LIST',
  UPDATE_LAST_CONFIRMATION: 'UPDATE_LAST_CONFIRMATION',
};

export const singleConfirmationTypes = {
  UPDATE_ORDER: 'UPDATE_ORDER',
  RESET_ORDER: 'RESET_ORDER',
  UPDATE_PERSONNEL: 'UPDATE_PERSONNEL',
  SET_FINAL_CONFIRMATION: 'SET_FINAL_CONFIRMATION',
  RESET_FINAL_CONFIRMATION: 'RESET_FINAL_CONFIRMATION',
};

export const inspSchedulingTypes = {
  UPDATE_INSPECTION_LIST: 'UPDATE_INSPECTION_LIST',
  UPDATE_BASIC_SCHEDULE: 'UPDATE_BASIC_SCHEDULE',
  UPDATE_DAILY_SCHEDULE: 'UPDATE_DAILY_SCHEDULE',
  DELETE_BASIC_SCHEDULE: 'DELETE_BASIC_SCHEDULE',
  UPDATE_CALENDAR_DATES: 'UPDATE_CALENDAR_DATES',
  UPDATE_DAILY_SCH_DB: 'UPDATE_DAILY_SCH_DB',
  UPDATE_BASIC_SCH_DB: 'UPDATE_BASIC_SCH_DB',
  UPDATED_DELETED_ELEMENTS: 'UPDATED_DELETED_ELEMENTS',
  UPDATED_DELETED_DAILY_SCH: 'UPDATED_DELETED_DAILY_SCH',
};

export const adminSetupTypes = {
  UPDATE_WC_ASSIGNMENTS_LIST: 'UPDATE_WC_ASSIGNMENTS_LIST',
  ADD_WC_ASSIGNMENT: 'ADD_WC_ASSIGNMENT',
  ADD_WC_ASSIGNMENTS: 'ADD_WC_ASSIGNMENTS',
  UPDATE_WC_ASSIGNMENT: 'UPDATE_WC_ASSIGNMENT',
  EDIT_WC_ASSIGNMENT: 'EDIT_WC_ASSIGNMENT',
  DELETE_WC_ASSIGNMENT: 'DELETE_WC_ASSIGNMENT',
  RESET_WC_ASSIGNMENT: 'RESET_WC_ASSIGNMENT',
  ADD_WORK_CENTERS: 'ADD_WORK_CENTERS',
  COPY_ASSIGNMENT: 'COPY_ASSIGNMENT',
};
