import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
// import {analytics} from '../../../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useDebounce } from 'use-debounce';
import styles from './single-confirmation.module.scss';
// Styles
// import '../../pages/work-confirm/work-confirm.styles.css';
// Strings, Helpers, Actions
import {
  loaderMessages,
  pages,
  singleConfirmationStrings,
  queryKeyStrings,
} from '../../../constants/strings';
import {
  getStartDate,
  getHourDecimal,
  submitWorkConfirmation,
  personnelTemplate,
} from '../work-confirm/work-confirm.helper';
import {
  showLoader,
  hideLoader,
  updateSelectedPage,
  updateParentFollowupOrder,
} from '../../../redux/actions/global.actions';
import { resetWorkConfirmValue } from '../work-confirm/work-confirm.actions';
import { updatePersonnel } from './single-confirmation.actions';
// Components
// import CustomHeader from '../../controls/custom-header';
import CustomModal from '../../controls/custom-modal';
import CustomButton from '../../controls/custom-button';
import LabeledField from '../../controls/labeled-field';
// import Dropdown from '../../controls/dropdown';
// Images
import IconRadioOn from '../../../assets/images/icon_radio-on.png';
import IconRadioOff from '../../../assets/images/icon_radio-off.png';
import IconSquaredRadioOn from '../../../assets/images/icon_squared-radio-on.png';
import IconSquaredRadioOff from '../../../assets/images/icon_squared-radio-off.png';
import IconPlus from '../../../assets/images/icon_plus.png';
import IconWarningFill from '../../../assets/images/warning-fill.png';
import IconSuccessFill from '../../../assets/images/success.png';
import SubHeader from '../../controls/sub-header/sub-header.component';
import FormInput from '../../controls/form-input/form-input.component';
import { checkNotNull } from '../../../Helpers/common-helpers';
import CONFIRMATION from './single-confirmation.strings';

import IconSuccess from '../../../assets/images/icon_success-white.png';
// import IconWarning from '../../../assets/images/warning.png';

import Check from '../../../assets/svgs/icon_check';
import DoubleArrowDown from '../../../assets/svgs/icon_double-arrow-down';
import Trash from '../../../assets/images/trash_icon.png';
// import Calendar from '../../../assets/svgs/icon_calendar';
// import SearchableDropdown from '../../controls/searchable-dropdown/';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
// import FailedPersonnel from '../../modals/FailedPersonnel';
import OrderAttachment from '../../modals/OrderAttachment';
// import ViewAttachment from '../../modals/ViewAttachment';
import mountAttachmentList from '../../modals/OrderAttachment/order-attachment.helper';
import GeneralError from '../../modals/GeneralError';
import OrderDetails from '../../controls/order-detail';
import AttachmentsIcon from '../../controls/attachment-icon';
import ContentField from '../../controls/content-field';
import DatePicker from '../../controls/date-picker';
import CustomDropdown from '../../controls/custom-dropdown/custom-dropdown.component';
import queryClient from '../../../utilities/queryClient';
// import ImmediateExecution from '../../controls/Immediate-Execution/immediate-execution';

const WORK_CONFIRMATION = {
  HEADER_TEXT: 'Confirmation - 12345678',
  OPERATIONS_TITLE: 'Select Your Operation',
  SUBMIT_TEXT: 'Submit Work Confirmation',
};

export function ImmediateExecution(props) {
  const {
    assignedPersonnelImmediateExecution,
    confirmationTextImmediateExecution,
    confirmationTypeImmediateExecution,
    activeSubmitImmediateExecution,
    clearAll,
    submitted,
  } = props;
  const globalReducer = useSelector((state) => state.globalReducer);
  const { personnel, onlyFinal, order, } = useSelector(
    (state) => state.singleConfirmationReducer,
  );

  const { userPersonalData } = globalReducer;

  const [confirmationType, setConfirmationType] = useState(null);
  const [quickRemarks, setQuickRemarks] = useState([]);
  const [confirmationText, setConfirmationText] = useState('');
  const [assignedPersonnel, setAssignedPersonnel] = useState([]);
  const [personnelAvailablelist, setPersonnelAvailablelist] = useState(personnel);
  useEffect(() => {
    if (clearAll) {
      const { surname, givenName } = globalReducer.userData;
      const localPersonnel = [];
      localPersonnel.push(personnelTemplate(userPersonalData.name, userPersonalData.personalNo));
      setConfirmationType(null);
      setQuickRemarks([]);
      setConfirmationText('');
      setAssignedPersonnel(localPersonnel);
    }
  }, [clearAll]);

  useEffect(() => {
    const { remarks } = CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks;
    const [PAR, FAR, DONE] = remarks;
    if (quickRemarks.length > 0) {
      let resetConfirmation = confirmationText
        .replace('FAR, ', '')
        .replace('PAR, ', '')
        .replace('Ok - No Action Required, ', '');
      if (quickRemarks.includes(DONE)) {
        resetConfirmation = `Ok - No Action Required, ${resetConfirmation}`;
      } else {
        if (quickRemarks.includes(FAR)) {
          resetConfirmation = `FAR, ${resetConfirmation}`;
        }
        if (quickRemarks.includes(PAR)) {
          resetConfirmation = `PAR, ${resetConfirmation}`;
        }
      }
      setConfirmationText(resetConfirmation);
    }
  }, [quickRemarks]);

  // useEffect(() => {
  //   const newList = [...(personnel || [])];
  //   assignedPersonnel?.forEach(({ value }) => {
  //     const index = newList.findIndex((item) => item.value === value);
  //     return index !== -1 && newList.splice(index, 1);
  //   });
  //   setPersonnelAvailablelist(newList);
  // }, [assignedPersonnel, personnel]);

  useEffect(() => {
    if (
      confirmationType !== null
      && quickRemarks.length > 0
      && assignedPersonnel?.length > 0
      && assignedPersonnel?.filter(
        ({ value, startDate }) => startDate === undefined || value === '',
      ).length === 0
    ) {
      assignedPersonnelImmediateExecution(assignedPersonnel);
      confirmationTextImmediateExecution(confirmationText);
      confirmationTypeImmediateExecution(confirmationType);
      // quickRemarksImmediateExecution(quickRemarks);
      activeSubmitImmediateExecution(true);
    } else {
      activeSubmitImmediateExecution(false);
    }
  }, [confirmationType, quickRemarks, assignedPersonnel]);

  useEffect(() => {
    if (!clearAll) {
      let personnelInfoData = [];
      personnelInfoData = order?.personnelInfo?.map((item) => ({
        label: `${item.name} - ${item.personnelNumber}`,
        value: item.personnelNumber,
        actualDuration: {
          decimal: 0,
          minutes: 0,
        },
        startDate: undefined,
        endDate: undefined,
      }))
      if(personnelInfoData.filter(data => data.value == userPersonalData.personalNo).length == 0 ){
        personnelInfoData.push(personnelTemplate(userPersonalData.name, userPersonalData.personalNo))
      }
      setAssignedPersonnel(personnelInfoData);
    }
  }, [order])

  const handleQuickRemarks = (remark) => {
    const index = quickRemarks.indexOf(remark);
    const doneText = CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[2];
    const localRemarks = [...quickRemarks];

    if (index === -1) {
      if (remark === doneText || localRemarks.includes(doneText)) {
        localRemarks.length = 0;
      }
      localRemarks.push(remark);
    } else {
      localRemarks.splice(index, 1);
    }
    setQuickRemarks(localRemarks);
  };

  const handlePersonnelChange = (object, index) => {
    const localPersonnel = [...assignedPersonnel];
    localPersonnel[index] = object;
    setAssignedPersonnel(localPersonnel);
  };

  const handleSpreadDuration = (duration, date) => {
    const currentPersonnel = [...assignedPersonnel].map((current) => ({
      ...current,
      actualDuration: duration,
      endDate: date,
    }));
    setAssignedPersonnel(currentPersonnel);
  };

  const handleAddPersonnel = () => {
    const currentPersonnel = assignedPersonnel ? [...assignedPersonnel] : [];
    currentPersonnel.push(personnelTemplate('', ''));
    setAssignedPersonnel(currentPersonnel);
  };

  const handleDeletePersonnel = (index) => {
    const currentPersonnel = [...assignedPersonnel];
    currentPersonnel.splice(index, 1);
    setAssignedPersonnel(currentPersonnel);
  };
  return (
    <>
      <SubHeader
        icon={
          checkNotNull(confirmationType) && quickRemarks.length > 0
            ? IconSuccessFill
            : IconWarningFill
        }
        headerContent={singleConfirmationStrings.WORK_CONFIRMATION_DETAILS}
      >
        <div className={`${styles.woinfosWrapper} col pl-4 ml-3 mr-3 pb-2`}>
          <LabeledField
            customStyle={{ marginBottom: '26px' }}
            label="Confirmation Type"
          >
            <div className="row gx-0">
              {CONFIRMATION.CONFIRMATION_DETAILS.type.types?.map((option) => (
                <CustomRadio
                  key={option}
                  customClass={`col-4 ${
                    onlyFinal
                      && option === singleConfirmationStrings.PARTIAL_CONFIRMATION
                      ? 'disabled'
                      : ''
                    }`}
                  onClick={() => setConfirmationType(option)}
                  icon={
                    confirmationType === option ? IconRadioOn : IconRadioOff
                  }
                  text={option}
                />
              ))}
            </div>
          </LabeledField>
          <LabeledField
            customStyle={{ marginBottom: '26px' }}
            label={CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.label}
          >
            <div className="row gx-0">
              {CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks.map(
                (remark) => (
                  <CustomRadio
                    key={remark}
                    customClass="col-4"
                    onClick={() => handleQuickRemarks(remark)}
                    icon={
                      quickRemarks.includes(remark)
                        ? IconSquaredRadioOn
                        : IconSquaredRadioOff
                    }
                    text={remark}
                  />
                ),
              )}
            </div>
          </LabeledField>
          <LabeledField
            fieldClass="pr-4"
            label={CONFIRMATION.CONFIRMATION_DETAILS.confirmation_label}
          >
            {/* <input
            className='confirmation-field col-12'
            type='text'
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          /> */}
            <FormInput
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </LabeledField>
        </div>
      </SubHeader>
      <SubHeader
        icon={
          assignedPersonnel?.length > 0
            && assignedPersonnel?.filter(
              ({ value, startDate }) => startDate === undefined || value === '',
            ).length === 0
            ? IconSuccessFill
            : IconWarningFill
        }
        headerContent={singleConfirmationStrings.PERSONNEL_DETAILS}
      >
        <div
          className={`${styles.woinfosWrapper} col border-bottom pl-4 ml-3 mr-3 pb-2`}
        >
          {assignedPersonnel?.map((personnelData, index) => {
            if (
              submitted
              // && failedPersonnel.includes(personnel.value)
            ) {
              return (
                <ConfirmedPersonnelItem
                  key={personnelData !== null && personnelData.value + index}
                  listValue={personnelData}
                />
              );
            }
            return (
              <PersonnelItem
                position={window.innerHeight}
                listValue={personnelData}
                index={index}
                key={personnelData !== null && personnelData.value + index}
                list={personnelAvailablelist}
                onChange={(item) => handlePersonnelChange(item, index)}
                onDelete={() => handleDeletePersonnel(index)}
                onSpread={() => handleSpreadDuration(
                  personnelData.actualDuration,
                  personnelData.endDate,
                )}
                firstRow={index === 0}
              />
            );
          })}
          {personnelAvailablelist.length > 0 && (
            <div className="row gx-0">
              <div className="col d-flex justify-content-end mr-1">
                <span onClick={() => handleAddPersonnel()} role="presentation">
                  <img src={IconPlus} alt="Icon for adding one personnel" />
                </span>
              </div>
            </div>
          )}
        </div>
      </SubHeader>
    </>
  );
}

ImmediateExecution.propTypes = {
  activeSubmitImmediateExecution: PropTypes.func,
  assignedPersonnelImmediateExecution: PropTypes.func,
  clearAll: PropTypes.bool,
  confirmationTextImmediateExecution: PropTypes.func,
  confirmationTypeImmediateExecution: PropTypes.func,
  // quickRemarksImmediateExecution: PropTypes.func,
  submitted: PropTypes.bool,
};
ImmediateExecution.defaultProps = {
  activeSubmitImmediateExecution: () => { },
  assignedPersonnelImmediateExecution: () => { },
  clearAll: false,
  confirmationTextImmediateExecution: () => { },
  confirmationTypeImmediateExecution: () => { },
  // quickRemarksImmediateExecution: () => { },
  submitted: false,
};

export default function WorkConfirmation() {
  const globalReducer = useSelector((state) => state.globalReducer);
  const {
    personalNo, name, sapUserID,
  } = globalReducer.userPersonalData;

  const { userData, plantId } = globalReducer;
  const { order, personnel } = useSelector(
    (state) => state.singleConfirmationReducer,
  );
  const {
    imageUrl,
    prtDocuments,
    mnWrkCtrId,
    orderId,
    orderType,
    personnelInfo,
  } = order;

  const dispatch = useDispatch();

  const [confirmationType, setConfirmationType] = useState(null);
  // const [quickRemarks, setQuickRemarks] = useState([]);
  const [confirmationText, setConfirmationText] = useState('');
  const [assignedPersonnel, setAssignedPersonnel] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  // const [failedPersonnel, setFailedPersonnel] = useState([]);
  // eslint-disable-next-line
  const [personnelAvailablelist, setPersonnelAvailablelist] = useState(personnel);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  // const [currentAttachment, setCurrentAttachment] = useState(undefined);
  // const [showAttachmentViewModal, setShowAttachmentViewModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [submitSucess, setSubmitSucess] = useState(false);
  // const [submitFail, setSubmitFail] = useState(false);
  // const [submitFailMessage, setSubmitFailMessage] = useState('');
  const [submitError, setsubmitError] = useState({
    show: false,
    message: '',
  });
  const [clearState, setClearState] = useState(false);
  useEffect(() => {
    const localPersonnel = [];
    let splitedName = ['', ''];

    const orderedList = [
      ...personnel.filter(({ workCenter }) => workCenter === order.workCenter),
      ...personnel.filter(({ workCenter }) => workCenter !== order.workCenter),
    ];
    dispatch(updatePersonnel(orderedList));
    setPersonnelAvailablelist(orderedList);

    if (personalNo.length > 1 && name.length > 1) {
      splitedName = name.split(' ');
      localPersonnel.push(
        personnelTemplate(`${splitedName[1]}, ${splitedName[0]}`, personalNo),
      );
    }

    if (personnelInfo) {
      personnelInfo?.forEach(
        ({ personnelName, personnelNumber }) => personnelNumber !== globalReducer.userPersonalData.personalNo
          && localPersonnel.push(
            personnelTemplate(personnelName, personnelNumber),
          ),
      );
    }
    setAssignedPersonnel(localPersonnel);

    if (imageUrl) {
      setAttachments([...attachments, ...imageUrl]);
    }
    if (prtDocuments) {
      // let prtModDocuments = prtDocuments.map(item => {
      //   return {
      //     ...item,
      //     docFile: item.docFile.concat(`${ globalReducer.userData.mail.split('@')[0]}/${orderId}`)
      //   }
      // })
      setAttachments([...attachments, ...prtDocuments]);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     confirmationType !== null &&
  //     quickRemarks.length > 0 &&
  //     assignedPersonnel.length > 0 &&
  //     assignedPersonnel.filter(
  //       ({ value, startDate }) => startDate === undefined || value === '',
  //     ).length === 0
  //   ) {
  //     setActiveSubmit(true);
  //   } else {
  //     setActiveSubmit(false);
  //   }
  // }, [confirmationType, quickRemarks, assignedPersonnel]);

  // const handleQuickRemarks = remark => {
  //   let index = quickRemarks.indexOf(remark);
  //   let doneText = CONFIRMATION.CONFIRMATION_DETAILS.quick_remarks.remarks[2];
  //   let localRemarks = [...quickRemarks];

  //   if (index === -1) {
  //     if (remark === doneText || localRemarks.includes(doneText)) {
  //       localRemarks.length = 0;
  //     }
  //     localRemarks.push(remark);
  //   } else {
  //     localRemarks.splice(index, 1);
  //   }
  //   setQuickRemarks(localRemarks);
  // };

  // const handlePersonnelChange = (object, index) => {
  //   const localPersonnel = [...assignedPersonnel];
  //   localPersonnel[index] = object;
  //   setAssignedPersonnel(localPersonnel);
  // };

  // const handleSpreadDuration = (duration, date) => {
  //   let currentPersonnel = [...assignedPersonnel].map(current => {
  //     return { ...current, actualDuration: duration, endDate: date };
  //   });
  //   setAssignedPersonnel(currentPersonnel);
  // };

  // const handleAddPersonnel = () => {
  //   let currentPersonnel = [...assignedPersonnel];
  //   currentPersonnel.push(personnelTemplate('', ''));
  //   setAssignedPersonnel(currentPersonnel);
  // };

  // const handleDeletePersonnel = (index) => {
  //   let currentPersonnel = [...assignedPersonnel];
  //   currentPersonnel.splice(index, 1);
  //   setAssignedPersonnel(currentPersonnel);
  // };

  const handleSubmit = async () => {
    const body = {
      orderId,
      plantId: `${globalReducer.plantId}`,
      operation: order.activity,
      mainWorkCenter: mnWrkCtrId,
      userName: sapUserID,
      finalConf:
        confirmationType === singleConfirmationStrings.FINAL_CONFIRMATION
          ? 'X'
          : '',
      confText: confirmationText,
      personalDetails: assignedPersonnel.map((personnelData) => ({
        startDate: personnelData.startDate,
        endDate: personnelData.endDate,
        actualDuration: personnelData.actualDuration.decimal,
        personalNumber: personnelData.value,
      })),
    };
    dispatch(showLoader(loaderMessages.WORK_CONFIRMATION));
    const fireBaseObj = { ...body };
    fireBaseObj.personalDetails = undefined;
    fireBaseObj.operation = JSON.stringify(body.operation);
    // if (config.ENVIRONMENT === 'PROD') {
    //   await analytics.logEvent('work_confirmation', {
    //     ...fireBaseObj,
    //   });
    // }
    try {
      const postWorkConfirmationResponse = await submitWorkConfirmation(body);
      if (postWorkConfirmationResponse.status === 200) {
        setSubmitSucess(true);
        queryClient.invalidateQueries(
          `${queryKeyStrings.PERSONALSCHEDULEV1}-${personalNo}-${globalReducer.userData?.mail}-${plantId}`,
        );
        queryClient.invalidateQueries(`${queryKeyStrings.ordersCreatedByMeV1}-${personalNo}-${userData?.mail}-${plantId}`);
        queryClient.invalidateQueries(`${queryKeyStrings.backlogSchedule}-${personalNo}-${userData?.mail}-${plantId}`);
        queryClient.invalidateQueries(`${queryKeyStrings.personalScheduleV1}-${personalNo}-${userData?.mail}-${plantId}`);
        queryClient.invalidateQueries(`${queryKeyStrings.wcScheduleV2}-${personalNo}-${userData?.mail}-${plantId}`);
        queryClient.invalidateQueries(`${queryKeyStrings.facilScheduleV1}-${personalNo}-${userData?.mail}-${plantId}`);
        queryClient.invalidateQueries(`${queryKeyStrings.lastConfirmationsV1}-${plantId}`);
      } else {
        setsubmitError({
          show: true,
          message:
            postWorkConfirmationResponse.data.message.data.orders[0].message,
        });
      }
    } catch (error) {
      console.log(error.message);
      setsubmitError({
        show: true,
        message: error
          ? error.message
          : singleConfirmationStrings.SOMETHING_WENT_WRONG,
      });
    }
    dispatch(hideLoader());
  };

  const handleLogOut = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.clear();
    window.location.reload();
  };

  const createFollowupOrder = () => {
    dispatch(updateSelectedPage(pages.FOLLOWUP_ORDER));
    dispatch(updateParentFollowupOrder(order));
  };
  const handleResetNavigation = () => {
    dispatch(resetWorkConfirmValue());
    setSubmitted(false);
    dispatch(updateSelectedPage(pages.CONFIRM));
    // setAssignedPersonnel([]);
    // setConfirmationType(null);
    // setQuickRemarks([]);
    // setConfirmationText('');
    // setActiveSubmit(false);
    // setShowMore(false);
    // setSubmitSucess(false);
  };
  useEffect(() => {
    if (clearState) {
      setClearState(false);
    }
  }, [clearState]);

  const handleClearState = () => {
    setClearState(true);
  };
  // const handleOpenImage = url => {
  //   setShowAttachmentModal(false);
  //   setCurrentAttachment(url);
  //   setShowAttachmentViewModal(true);
  // };

  return (
    <ContentWithBreadcrumb>
      <div className="col ">
        <div className={`${styles.headerSticky}`}>
          <h1 className={`${styles.headerTitle}`}>
            {`${singleConfirmationStrings.WORK_ORDER} # ${order.orderId}`}
            <div
              style={{ display: 'inline-flex' }}
              onClick={() => setShowAttachmentModal(true)}
              role="presentation"
            >
              <AttachmentsIcon length={attachments.length} />
            </div>
          </h1>
          <div className="d-flex flex-direction-row pl-4">
            <ContentField>
              <p className={`${styles.contentFieldText} p-0 m-0`}>
                {order.shortDescription || order.wlongText}
              </p>
            </ContentField>
            <ContentField bootStrapClass="ml-2">
              <p className={`${styles.contentFieldText} p-0 m-0`}>
                <span className={`${styles.opHeading}`}>
                  {singleConfirmationStrings.OPERATION}
                </span>
                {' '}
                {order.description}
              </p>
            </ContentField>
          </div>
        </div>
        <OrderDetails
          userData={globalReducer.userData}
          order={{
            ...order,
            peopleAssigned: order.personnelInfo?.length,
          }}
        />
        <ImmediateExecution
          assignedPersonnelImmediateExecution={(data) => setAssignedPersonnel(data)}
          confirmationTextImmediateExecution={(data) => setConfirmationText(data)}
          confirmationTypeImmediateExecution={(data) => setConfirmationType(data)}
          // quickRemarks_ImmediateExecution={(data) => setQuickRemarks(data)}
          activeSubmitImmediateExecution={(data) => setActiveSubmit(data)}
          clearAll={clearState}
          submitted={submitted}
        />

        <div className="row gx-0 mx-3">
          <div className="col d-flex justify-content-end  mt-2 mb-4">
            <CustomButton
              value="Clear All"
              onPress={handleClearState}
              customStyle={{
                backgroundColor: 'white',
                color: '#C74A34',
                marginRight: 20,
              }}
            />
            <CustomButton
              disabled={!activeSubmit}
              onPress={handleSubmit}
              value={WORK_CONFIRMATION.SUBMIT_TEXT}
            />
          </div>
        </div>
      </div>
      {submitSucess && (
        <CustomModal
          customWidth="350px"
          actions={{ cancel: () => setSubmitSucess(false) }}
          header={{ title: IconSuccess, bgColor: '#99CC68' }}
          buttons={(
            <div
              className={`${styles.modal__footer} ${styles.submitModal__footer}`}
            >
              <CustomButton
                customStyle={{ width: '100%', marginBottom: 20 }}
                onPress={() => dispatch(updateSelectedPage(pages.HOME))}
                value={singleConfirmationStrings.HOME}
              />
              <CustomButton
                customStyle={{ width: '100%', marginBottom: 20 }}
                onPress={handleResetNavigation}
                value={singleConfirmationStrings.START_NEW_CONFIRMATION}
              />
              {orderType !== 'ZM10' && (
                <CustomButton
                  customStyle={{ width: '100%', marginBottom: 20 }}
                  onPress={createFollowupOrder}
                  value={singleConfirmationStrings.CREATE_FOLLOW_UP_ORDER}
                />
              )}
              <CustomButton
                customStyle={{ width: '100%' }}
                onPress={handleLogOut}
                value={singleConfirmationStrings.LOGOUT}
              />
            </div>
          )}
        >
          <div className={`${styles.modal__content} row gx-0`}>
            <div className="col-12">
              <span>{singleConfirmationStrings.SUBMIT_SUCCESFULLY}</span>
            </div>
          </div>
        </CustomModal>
      )}
      {/* <FailedPersonnel
        list={submitFailMessage}
        allFail={assignedPersonnel.length === failedPersonnel.length}
        onClose={() => setSubmitFail(false)}
        onExit={() => dispatch(updateSelectedPage('home'))}
        onSkit={handleSkip}
        onSubmit={handleReSubmit}
        visible={submitFail}
      /> */}
      <GeneralError
        message={submitError.message}
        visible={submitError.show}
        onClose={() => setsubmitError({ ...submitError, show: false })}
      />
      <OrderAttachment
        orderId={orderId}
        attachments={mountAttachmentList(
          attachments,
          globalReducer.userData.mail,
          orderId,
        )}
        visible={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
      // onViewImage={url => handleOpenImage(url)}
      />
      {/* <ViewAttachment
        attachment={currentAttachment}
        visible={showAttachmentViewModal}
        onClose={() => setShowAttachmentViewModal(false)}
      /> */}
    </ContentWithBreadcrumb>
  );
}

// const TitledSection = ({ sectionClass, titleClass, title, children }) => {
//   return (
//     <div className='row gx-0 mx-3'>
//       <div className={sectionClass + ' col pl-4'}>
//         <div className='row gx-0'>
//           <h4 className={`col titleSection ${titleClass ? titleClass : ''}`}>
//             {title}
//           </h4>
//         </div>
//         {children}
//       </div>
//     </div>
//   );
// };

export function CustomRadio({
  customClass, onClick, icon, text,
}) {
  return (
    <span
      className={`custom-radio ${customClass && customClass}`}
      onClick={onClick}
      role="presentation"
    >
      <img src={icon} alt={text} style={{ marginRight: '10px' }} />
      <span className={styles.textStyle}>{text}</span>
    </span>
  );
}

CustomRadio.propTypes = {
  customClass: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
CustomRadio.defaultProps = {
  customClass: '',
  icon: '',
  onClick: () => { },
  text: '',
};
function DurationModal({ duration, onClose, onSubmit }) {
  const [localHour, setlocalHour] = useState({ value: '0', label: '0' });
  const [localMinute, setlocalMinute] = useState({ value: '0', label: '0' });
  const hours = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
  ];
  const minutes = [
    { label: '0', value: '0' },
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '20', value: '20' },
    { label: '25', value: '25' },
    { label: '30', value: '30' },
    { label: '35', value: '35' },
    { label: '40', value: '40' },
    { label: '45', value: '45' },
    { label: '50', value: '50' },
    { label: '55', value: '55' },
  ];

  useEffect(() => {
    let localDuration = duration.minutes / 60;
    localDuration = `${localDuration}`.split('.');
    const hour = localDuration[0];
    const minute = localDuration[1] ? duration.minutes % 60 : '0';
    if (duration.minutes !== 0) {
      setlocalHour({ value: hour });
      setlocalMinute({ value: minute });
    }
  }, [duration]);

  const handleSelectChange = (value, string) => {
    if (string === 'hour') {
      setlocalHour({ ...value, label: value.name });
    } else {
      setlocalMinute({ ...value, label: value.name });
    }
  };

  const handleSubmit = () => {
    onSubmit([localHour.value, localMinute.value]);
    onClose();
  };

  const getValidationText = () => {
    if (
      parseInt(localHour.value, 10) === 0
      && parseInt(localMinute.value, 10) < 16
    ) {
      return singleConfirmationStrings.TIME_LESS_THAN_15_MINUTES;
    }
    if (
      parseInt(localHour.value, 10) === 16
      && parseInt(localMinute.value, 10) !== 0
    ) {
      return singleConfirmationStrings.TIME_MORE_THAN_16_HOURS;
    }
    return '';
  };

  const getValidationState = () => {
    if (
      parseInt(localHour.value, 10) === 0
      && parseInt(localMinute.value, 10) < 16
    ) {
      return false;
    }
    if (
      parseInt(localHour.value, 10) === 16
      && parseInt(localMinute.value, 10) !== 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <CustomModal
      customHeight="350px"
      customWidth="400px"
      header={{ title: 'Time Worked', bgColor: '#0578BE' }}
      actions={{ cancel: onClose }}
      buttons={(
        <div className={`${styles.modal__footer}`}>
          <CustomButton
            customStyle={{ width: '48%' }}
            onPress={onClose}
            value={singleConfirmationStrings.CANCEL}
          />
          <CustomButton
            customStyle={{ width: '48%' }}
            onPress={handleSubmit}
            disabled={!getValidationState()}
            value={singleConfirmationStrings.OK}
          />
        </div>
      )}
    >
      <div className={`${styles.modal__content} row gx-0 no-gutters`}>
        <div className="">
          <div className="row gx-0 no-gutters d-flex justify-content-between align-items-center">
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
              <select
                style={{ width: 'inherit', marginRight: 10 }}
                className='duration-modal_select'
                value={localHour.value}
                onChange={(e) => handleSelectChange(e.target.value, 'hour')}>
                {hours.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <span>hr(s)</span>
            </div> */}
            <div style={{ zIndex: 880 }}>
              <CustomDropdown
                data={{
                  items: hours,
                  value: '',
                  name: singleConfirmationStrings.HRS,
                  selectedObject: { label: localHour.value },
                }}
                editable
                updateDropDownItem={(data) => handleSelectChange(data, 'hour')}
                zIndex={1000}
                childName="label"
                childValue="value"
              />
            </div>
            {/* <span>hr(s)</span> */}
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <select
                style={{ width: 'inherit', marginRight: 10 }}
                className='duration-modal_select'
                value={localMinute.value}
                onChange={(e) => handleSelectChange(e.target.value, 'minute')}>
                {minutes.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select> */}
            <div style={{ zIndex: 860 }}>
              <CustomDropdown
                data={{
                  items: minutes,
                  value: '',
                  name: singleConfirmationStrings.MINS,
                  selectedObject: { label: localMinute.value },
                }}
                editable
                zIndex={1000}
                updateDropDownItem={(data) => handleSelectChange(data, 'minute')}
                childName="label"
                childValue="value"
              />
            </div>
            {/* <span>min(s)</span> */}
            {/* </div> */}
          </div>
          <div className="row gx-0 no-gutters d-flex justify-content-end">
            <span className={`${styles.durationModal__warning}`}>
              {getValidationText()}
            </span>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

DurationModal.propTypes = {
  duration: PropTypes.shape({
    minutes: PropTypes.number,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

DurationModal.defaultProps = {
  duration: PropTypes.shape({
    minutes: 0,
  }),
  onClose: () => { },
  onSubmit: () => { },
};

function PersonnelItem({
  listValue,
  list,
  onChange,
  firstRow,
  onDelete,
  onSpread,
  index,
}) {
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [localDate, setlocalDate] = useState(undefined);
  const [localTime, setlocalTime] = useState(undefined);
  const [debouncedDate] = useDebounce(localDate, 1000);
  const [debouncedTime] = useDebounce(localTime, 1000);
  const [hourValue, setHourValue] = useState('');
  const [hourZone, setHourZone] = useState('AM');
  const [minValue, setMinValue] = useState('');

  const element = useRef(null);
  const dateValues = localDate?.split('-');
  useEffect(() => {
    if (listValue.endDate) {
      setlocalDate(format(new Date(listValue.endDate), 'yyyy-MM-dd'));
      const formattedTime = format(new Date(listValue.endDate), 'HH:mm');
      setlocalTime(formattedTime);
      const timeArray = formattedTime.split(':');
      const zone = parseInt(timeArray[0], 10) > 11 ? 'PM' : 'AM';
      setHourZone(zone);
      setMinValue(timeArray[1]);
      // setHourValue(timeArray[0])
      setHourValue(
        zone === 'AM'
          ? String(timeArray[0] === '00' ? '12' : timeArray[0]).padStart(2, '0')
          : String(
            parseInt(timeArray[0], 10) > 12
              ? parseInt(timeArray[0], 10) - 12
              : parseInt(timeArray[0], 10),
          ).padStart(2, '0'),
      );
    } else {
      setlocalDate('');
    }
    /* eslint-disable-next-line */
  }, [listValue]);
  useEffect(() => {
    if (checkNotNull(debouncedDate) && checkNotNull(debouncedTime)) {
      const newPersonnel = { ...listValue };
      const splitDate = debouncedDate.split('-');
      const splitTime = debouncedTime.split(':');
      if(splitTime[0] == 'NaN') {
        splitTime[0] = '12'
      }
      const newDate = new Date(
        splitDate[0],
        splitDate[1] - 1,
        splitDate[2],
        splitTime[0],
        splitTime[1],
      ).toISOString();
      newPersonnel.endDate = newDate;
      if (
        newPersonnel.actualDuration
        && newPersonnel.actualDuration.minutes
        && newPersonnel.actualDuration.minutes !== 0
      ) {
        newPersonnel.startDate = getStartDate(
          newPersonnel.endDate,
          newPersonnel.actualDuration.minutes,
        );
      }
      onChange(newPersonnel);
    }
    /* eslint-disable-next-line */
  }, [debouncedDate, debouncedTime]);

  const handleSelectChange = (value) => {
    const newPersonnel = { ...listValue };
    newPersonnel.value = value.value;
    newPersonnel.label = list.filter(
      (option) => option.value === value.value,
    )[0].label;
    onChange(newPersonnel);
  };

  const handleDurationChange = (duration) => {
    const newPersonnel = { ...listValue };
    newPersonnel.actualDuration = getHourDecimal(duration);
    if (newPersonnel.endDate) {
      newPersonnel.startDate = getStartDate(
        newPersonnel.endDate,
        newPersonnel.actualDuration.minutes,
      );
    }
    onChange(newPersonnel);
  };

  const onHourClick = (hour) => {
    setHourValue(hour);

    let convertedHour;

    if (hourZone === 'AM') {
      if (hour === '12') {
        convertedHour = '00';
      } else {
        convertedHour = hour;
      }
    } else if (hour === '12') {
      convertedHour = '12';
    } else {
      convertedHour = (parseInt(hour, 10) + 12).toString();
    }

    setlocalTime(`${convertedHour}:${minValue}`);
  };

  const onMinClick = (min) => {
    setMinValue(min);

    let convertedHour;

    if (hourZone === 'AM') {
      if (hourValue === '12') {
        convertedHour = '00';
      } else {
        convertedHour = hourValue;
      }
    } else if (hourValue === '12') {
      convertedHour = '12';
    } else {
      convertedHour = (parseInt(hourValue, 10) + 12).toString();
    }

    setlocalTime(`${convertedHour}:${min}`);
  };

  const onZoneClick = (zone) => {
    setHourZone(zone);

    let convertedHour;

    if (zone === 'AM') {
      if (hourValue === '12') {
        convertedHour = '00';
      } else {
        convertedHour = hourValue;
      }
    } else if (hourValue === '12') {
      convertedHour = '12';
    } else {
      convertedHour = (parseInt(hourValue, 10) + 12).toString();
    }

    setlocalTime(`${convertedHour}:${minValue}`);
  };

  return (
    <div className={`${styles.personnelItem} row`} ref={element}>
      {firstRow ? (
        <span
          className={`${styles.personnelRow_icon} `}
          onClick={onSpread}
          role="presentation"
        >
          <DoubleArrowDown />
        </span>
      ) : (
          <span className={`${styles.personnelRow_icon} `} />
        )}
      <LabeledField
        fieldClass="personnel-field col"
        // customStyle={{ maxWidth: '20%', flex: '0 0 20%'}}
        label={singleConfirmationStrings.CLOCK_NO}
      >
        {/* <SearchableDropdown
          value={listValue}
          items={list}
          onChange={(value) => handleSelectChange(value)}
          abovePostion={true}
        /> */}
        <div style={{ marginTop: '-10px', zIndex: 1000 - index }}>
          <CustomDropdown
            data={{
              items: list,
              name: '',
              value: '',
              selectedObject: listValue,
            }}
            editable
            required
            withSmartTyping
            zIndex={1000 - index}
            updateDropDownItem={(data) => {
              handleSelectChange(data);
            }}
            childName="label"
            childValue="value"
          />
        </div>
      </LabeledField>
      <LabeledField
        fieldClass="start-field col"
        // customStyle={{ maxWidth: '20%', flex: '0 0 20%' }}
        label={singleConfirmationStrings.START_DATE_AND_TIME}
      >
        {listValue.startDate && (
          <span
            className="d-flex align-items-center "
            style={{ color: '#192D38', fontFamily: 'OpenSans-Regular' }}
          >
            {format(new Date(listValue.startDate), 'MM/dd/yyyy HH:mm')}
          </span>
        )}
        {/* <div style={{ marginTop: '-5px' }}>
        <DatePicker
            type='date'
            value={
              listValue.endDate &&
              format(new Date(listValue.endDate), 'yyyy-MM-dd')
            }
            onChange={(e) => setlocalDate(e)}/>
        </div> */}
      </LabeledField>
      <LabeledField
        fieldClass="end-time-field col"
        // customStyle={{ maxWidth: '17%', flex: '0 0 17%' }}
        label={singleConfirmationStrings.END_DATE}
      >
        {/* <input
            className='field'
            type='date'
            value={
              listValue.endDate &&
              format(new Date(listValue.endDate), 'yyyy-MM-dd')
            }
            onChange={(e) => setlocalDate(e.target.value)}
          />
          <Calendar
            customStyle={{
              position: 'absolute',
              width: '18',
              height: '40',
              right: '20',
              bottom: '5',
              background: '#ecf2f6',
              pointerEvents: 'none',
            }}
          /> */}
        <div style={{ marginTop: '-5px', zIndex: 1000 - index }}>
          <DatePicker
            // value={
            //   listValue.endDate &&
            //   format(new Date(listValue.endDate), 'yyyy-MM-dd')
            // }
            value={
              localDate
                ? new Date(dateValues[0], dateValues[1] - 1, dateValues[2])
                : ''
            }
            onChange={(e) => (e != null
              ? setlocalDate(format(e, 'yyyy-MM-dd'))
              : setlocalDate(''))}
          />
        </div>
      </LabeledField>
      <LabeledField
        fieldClass="end-date-field col"
        // customStyle={{ maxWidth: '23%', flex: '0 0 23%' }}
        label={singleConfirmationStrings.END_TIME}
      >
        <div
          className="d-flex "
          style={{ marginTop: '-10px', zIndex: 1000 - index }}
        >
          {/* <input
            className='field'
            type='time'
            value={
              listValue.endDate && format(new Date(listValue.endDate), 'HH:mm')
            }
            onChange={(e) => { console.log(e.target.value); setlocalTime(e.target.value) }}
          />
          <Clock
            customStyle={{
              position: 'absolute',
              width: '18',
              height: '50',
              right: '25',
              bottom: '5',
              background: '#ecf2f6',
              pointerEvents: 'none',
            }}
          /> */}

          <div style={{ width: '30%', marginRight: 5, zIndex: 1000 - index }}>
            {/* <Dropdown
              value={hourValue}
              onItemClick={(item) => onHourClick(item.name)}
              style={{ inputBgColor: '#ECF2F6', paddingTop: 4, paddingBottom: 4, borderRadius: 5 }}
              pickerClass='confPickerClass'
              items={Array.from({ length: 12 }, (_, i) => i + 1).map(number => { return { name: String(number).padStart(2, '0') } })} />             */}
            <CustomDropdown
              data={{
                items: Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (number) => ({
                    label: String(number).padStart(2, '0'),
                    value: String(number).padStart(2, '0'),
                  }),
                ),
                name: '',
                value: '',
                selectedObject: { label: hourValue },
              }}
              editable
              zIndex={1000 - index}
              updateDropDownItem={(item) => onHourClick(item.name)}
              childName="label"
              childValue="value"
            />
          </div>
          <div style={{ marginRight: 5 }}>
            <p className="mt-2">:</p>
          </div>
          <div style={{ width: '30%', marginRight: 5, zIndex: 1000 - index }}>
            {/* <Dropdown
              value={minValue}
              onItemClick={(item) => onMinClick(item.name)}
              style={{ inputBgColor: '#ECF2F6', paddingTop: 4, paddingBottom: 4, borderRadius: 5 }}
              pickerClass='confPickerClass'
              items={Array.from({ length: 60 }, (_, i) => i).map(number => { return { name: String(number).padStart(2, '0') } })} /> */}
            <CustomDropdown
              data={{
                items: Array.from({ length: 60 }, (_, i) => i + 1).map(
                  (number) => ({
                    label: String(number).padStart(2, '0'),
                    value: String(number).padStart(2, '0'),
                  }),
                ),
                name: '',
                value: '',
                selectedObject: { label: minValue },
              }}
              editable
              zIndex={1000 - index}
              updateDropDownItem={(item) => onMinClick(item.name)}
              childName="label"
              childValue="value"
            />
          </div>
          <div style={{ width: '30%', zIndex: 1000 - index }}>
            {/* <Dropdown
              value={hourZone}
              onItemClick={(item) => onZoneClick(item.name)}
              style={{ inputBgColor: '#ECF2F6', paddingTop: 4, paddingBottom: 4, borderRadius: 5 }}
              pickerClass='confPickerClass'
              withSmartTyping={false}
              items={[{ name: 'AM' }, { name: 'PM' }]} /> */}
            <CustomDropdown
              data={{
                items: [
                  { label: 'AM', value: 'AM' },
                  { label: 'PM', value: 'PM' },
                ],
                name: '',
                value: hourZone,
                selectedObject: { label: hourZone },
              }}
              editable
              updateDropDownItem={(item) => onZoneClick(item.name)}
              zIndex={1000 - index}
              childName="label"
              childValue="value"
            />
          </div>
        </div>
      </LabeledField>
      <LabeledField
        fieldClass="time col"
        customStyle={{ maxWidth: '11%', flex: '0 0 11%' }}
        label={singleConfirmationStrings.TIME_WORKED}
      >
        <span
          className={`${styles.clockField} ${styles.dropdownView}`}
          style={{ marginTop: '-5px' }}
          onClick={() => setShowTimeModal(true)}
          role="presentation"
        >
          {(
            <span>{`${listValue?.actualDuration?.decimal ? listValue?.actualDuration?.decimal : 0} hr(s)`}</span>
          )}
        </span>
      </LabeledField>
      <div className="col">
        <span
          className={`${styles.personnelRow_icon}`}
          onClick={onDelete}
          role="presentation"
        >
          <img src={Trash} height={25} width={25} alt="trash" />
        </span>
      </div>
      {showTimeModal && (
        <DurationModal
          duration={listValue && listValue.actualDuration}
          onClose={() => setShowTimeModal(false)}
          onSubmit={(duration) => handleDurationChange(duration)}
        />
      )}
    </div>
  );
}

PersonnelItem.propTypes = {
  firstRow: PropTypes.bool,
  index: PropTypes.number,
  list: PropTypes.array,
  listValue: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: PropTypes.number,
    }),
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    personnel: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSpread: PropTypes.func,
};
PersonnelItem.defaultProps = {
  firstRow: false,
  index: 0,
  list: PropTypes.shape({
    filter: () => { },
  }),
  listValue: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: 0,
    }),
    endDate: '',
    startDate: '',
    personnel: '',
  }),
  onChange: () => { },
  onDelete: () => { },
  onSpread: () => { },
};

function ConfirmedPersonnelItem({ listValue }) {
  return (
    <div className={`${styles.personnelItem} row pr-4`}>
      <LabeledField fieldClass="personnel-field col-3" label="Personnel No.">
        <span className="d-flex align-items-center pt-2">
          {listValue.value}
        </span>
      </LabeledField>
      <LabeledField fieldClass="start-field col-3" label="Start Date & Time">
        <span className="d-flex align-items-center pt-2">
          {listValue.startDate.localized}
        </span>
      </LabeledField>
      <LabeledField fieldClass="end-field col-3" label="End Date & Time">
        <span className="d-flex align-items-center pt-2">
          {listValue.endDate}
        </span>
      </LabeledField>
      <LabeledField fieldClass="time col-2" label="Time Worked">
        <span>{`${listValue.actualDuration.decimal} hr(s)`}</span>
      </LabeledField>
      <span className={`${styles.personnelRow_icon}`}>
        <Check />
      </span>
    </div>
  );
}

ConfirmedPersonnelItem.propTypes = {
  listValue: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: PropTypes.number,
    }),
    endDate: PropTypes.string,
    startDate: PropTypes.shape({
      localized: PropTypes.string,
    }),
    value: PropTypes.string,
  }),
};
ConfirmedPersonnelItem.defaultProps = {
  listValue: PropTypes.shape({
    actualDuration: PropTypes.shape({
      decimal: null,
    }),
    endDate: '',
    startDate: PropTypes.shape({
      localized: '',
    }),
    value: '',
  }),
};
