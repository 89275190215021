import PropTypes from 'prop-types';
import React from 'react';
import './tool-tip.styles.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default function ToolTip({
  children, toolTipText,
}) {
  const popover = (
    <Popover id="popover-basic" arrowProps={{ style: { backgroundColor: '#FFF5CC' } }}>
      <Popover.Body style={{
        backgroundColor: '#FFF5CC',
        color: '#53626A',
        fontFamily: 'OpenSans-SemiBold',
        fontSize: 14,
        borderRadius: 3,
      }}
      >
        {toolTipText}
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger placement="bottom" trigger={['hover', 'focus']} overlay={popover} arrowProps={{ style: { backgroundColor: '#FFF5CC' } }}>
      {children}
    </OverlayTrigger>
  );
}

ToolTip.propTypes = {
  children: PropTypes.element,
  toolTipText: PropTypes.string,
};

ToolTip.defaultProps = {
  children: '',
  toolTipText: '',
};
