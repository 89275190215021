import { connect } from 'react-redux';

import SparesManagement from './spares-management.component';
import {
  showLoader, hideLoader, addBreadCrumb, resetBreadCrumbs, updateSelectedPage, dataFromSpares,
} from '../../../redux/actions/global.actions';
import { updateDropDownItem, updateValue } from '../work-order/work-order.actions';

const mapStateToProps = (state) => ({
  global: state,
  // createWorkOrdersState: state.createWorkOrderReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
  addBreadCrumb: (value) => dispatch(addBreadCrumb(value)),
  resetBreadCrumbs: () => dispatch(resetBreadCrumbs()),
  updateSelectedPage: (value) => dispatch(updateSelectedPage(value)),
  updateDropDownItem: (value) => dispatch(updateDropDownItem(value)),
  updateValue: (value) => dispatch(updateValue(value)),
  dataFromSpares: (value) => dispatch(dataFromSpares(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SparesManagement);
