import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// import {analytics} from '../../../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
// Styles
import styles from './work-confirm.module.scss';
// Strings, Helpers, Actions
// Styles
// import './work-confirm.styles.css';
// Strings, Helpers, Actions
import { loaderMessages, workConfirmStrings } from '../../../constants/strings';
import { SEARCH } from './work-confirm.strings';
import { getWorkOrderResults } from './work-confirm.helper';
import {
  showLoader,
  hideLoader,
  updateSelectedPage,
} from '../../../redux/actions/global.actions';
import {
  updatePersonnelList,
  updateWorkConfirmValue,
} from './work-confirm.actions';
// Components
import CustomIconButton from '../../controls/custom-icon-button';
import CustomModal from '../../controls/custom-modal';
import CustomButton from '../../controls/custom-button';
// Images
import IconWarning from '../../../assets/images/warning.png';
import RoundedArrowLeft from '../../../assets/svgs/icon_rounded-arrow-left';
import Drawer from '../../../assets/svgs/icon_drawer';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { handleSingleConfirmation } from '../single-confirmation/single-confirmation.helper';
import Operations from '../../controls/operations';
// import { workConfirmStrings } from '../../../constants/strings';

export default function WorkConfirmation({ addBreadCrumb, deleteBreadCrumb }) {
  const globalReducer = useSelector((state) => state.globalReducer);
  const workConfirmationReducer = useSelector(
    (state) => state.workConfirmationReducer,
  );
  const { plantId } = globalReducer;
  const {
    activityType, mnWrkCtrId, operations, orderId, personnelList,
  } = workConfirmationReducer;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [pageState, setPageState] = useState('search');
  // const [breadcrumb, setBreadcrumb] = useState('');
  const [searchNumber, setSearchNumber] = useState('');
  const [errorMessage, setSearchMessage] = useState(null);
  const [localOperation, setLocalOperation] = useState(undefined);
  const [showPersonalModal, setShowPersonalModal] = useState(false);

  useEffect(() => {
    addBreadCrumb({
      name: workConfirmStrings.SEARCH,
      action: null,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (operations === undefined) {
      setPageState('search');
    } else if (operations?.length > 1) {
      setPageState('operations');
    } else if (operations?.length === 1) {
      handleSingleConfirmation(
        dispatch,
        { ...workConfirmationReducer, ...workConfirmationReducer.operations[0] },
        false,
      );
    }
    // eslint-disable-next-line
  }, [operations]);

  useEffect(() => {
    // let breadcrumb = 'Search';
    if (pageState === 'operation' || pageState === 'operations') {
      // breadcrumb = `${breadcrumb} / WO#${orderId}`;
      deleteBreadCrumb(1);
      addBreadCrumb({
        name: `WO#${orderId}`,
        action: null,
      });
    }
    if (pageState === 'operation') {
      // breadcrumb = `${breadcrumb} / Operation ${localOperation.description}`;
      deleteBreadCrumb(2);
      addBreadCrumb({
        name: `Operation ${localOperation.description}`,
        action: null,
      });
    } else if (pageState === 'operations') {
      // breadcrumb = `${breadcrumb} / Select your operation`;
      deleteBreadCrumb(2);
      addBreadCrumb({
        name: workConfirmStrings.SELECT_YOUR_OPERATION,
        action: null,
      });
    }
    // setBreadcrumb(breadcrumb);
    // eslint-disable-next-line
  }, [pageState, activityType, orderId]);

  useEffect(() => {
    if (pageState === 'search-error' && searchNumber.length > 0) {
      setPageState('search');
    }
    // eslint-disable-next-line
  }, [searchNumber]);

  useEffect(() => {
    const initializePersonnel = async () => {
      const localPersonnelOptions = personnelList;
      if (!Object.prototype.hasOwnProperty.call(globalReducer.userPersonalData, 'name')) {
        setShowPersonalModal(true);
      }

      const orderedList = [
        ...localPersonnelOptions.filter(
          ({ workCenter }) => workCenter === localOperation.workCenter,
        ),
        ...localPersonnelOptions.filter(
          ({ workCenter }) => workCenter !== localOperation.workCenter,
        ),
      ];
      dispatch(updatePersonnelList(orderedList));
    };

    if (localOperation) {
      initializePersonnel();
      handleSingleConfirmation(
        dispatch,
        { ...workConfirmationReducer, ...localOperation },
        false,
      );
    }

    if (localOperation) initializePersonnel();
    // eslint-disable-next-line
  }, [localOperation]);

  const handleSearch = async (woNumber = null) => {
    try {
      dispatch(showLoader(loaderMessages.SEARCH_WO));
      const searchWOValue = typeof woNumber === 'string' ? woNumber : searchNumber;
      const data = await queryClient.fetchQuery({
        queryKey: searchWOValue,
        queryFn: async () => {
          const response = await getWorkOrderResults(plantId, searchWOValue);
          return response;
        },
        retry: 2,
        staleTime: 1000 * 60 * 30,
      });

      dispatch(updateWorkConfirmValue(data));
      // if (config.ENVIRONMENT === 'PROD') {
      //   await analytics.logEvent('search_order_confirmation', {
      //     search_term: searchWOValue,
      //     searched_by: userData.displayName,
      //   });
      // }
    } catch (error) {
      setSearchMessage(SEARCH.errorSearchNumber(searchNumber));
      setSearchNumber('');
      setPageState('search-error');
    } finally {
      dispatch(hideLoader());
    }
  };

  const onOperationClick = (operation) => {
    setLocalOperation(operation);
    setPageState('operation');
  };

  return (
    <ContentWithBreadcrumb title={workConfirmStrings.CONFIRMATION}>
      <div className={` ${styles.search__wrapper} ml-4`}>
        <input
          className={`${pageState === 'search-error' ? styles.error : ''
          } ${styles.search__input}`}
          placeholder={SEARCH.placeholder}
          type="text"
          value={searchNumber}
          onChange={(e) => setSearchNumber(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
        />
        <CustomIconButton
          dataId="search-button"
          disabled={searchNumber.length < 8}
          onPress={handleSearch}
        >
          <RoundedArrowLeft fill={searchNumber.length < 8 ? '#8C969B': ''} />
        </CustomIconButton>
      </div>
      <div className={`${styles.wcContainer} container p-0 ml-4 mr-4`}>
        {(pageState === 'search' || pageState === 'search-error') && (
          <div className={`${styles.wosearchContainer} col`}>
            <Drawer fill={pageState === 'search-error' ? '#FFE2DC' : ''} />
            <p
              data-testid="search-message"
              className={`${styles.wosearchText} ${pageState === 'search-error' ? styles.wosearchTextError : ''
              }`}
            >
              {errorMessage || SEARCH.defaultMessage}
            </p>
          </div>
        )}
        {pageState === 'operations' && (
          <Operations
            mnWrkCtrId={mnWrkCtrId}
            operations={operations}
            onOperationClick={onOperationClick}
          />
        )}
        {showPersonalModal && (
          <CustomModal
            customWidth="350px"
            actions={{ cancel: () => setShowPersonalModal(false) }}
            header={{ title: IconWarning, bgColor: '#E5A812' }}
            buttons={(
              <div className="modal__footer">
                <CustomButton
                  customStyle={{ width: '48%' }}
                  onPress={() => dispatch(updateSelectedPage('home'))}
                  value={workConfirmStrings.BACK}
                />
                <CustomButton
                  customStyle={{ width: '48%' }}
                  onPress={() => setShowPersonalModal(false)}
                  value={workConfirmStrings.CONTINUE}
                />
              </div>
            )}
          >
            <div className="modal__content row">
              <div className="col-12">
                <span>
                  {workConfirmStrings.CLOCK_NOT_FOUND}
                  <br />
                  {' '}
                  {workConfirmStrings.CHECK_WITH_PLANNER}
                  <br />
                  {' '}
                  {workConfirmStrings.CONFIRM_WORK_FOR_YOURSELF}
                </span>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </ContentWithBreadcrumb>
  );
}

WorkConfirmation.propTypes = {
  addBreadCrumb: PropTypes.func,
  deleteBreadCrumb: PropTypes.func,
};

WorkConfirmation.defaultProps = {
  addBreadCrumb: () => { },
  deleteBreadCrumb: () => { },
};
