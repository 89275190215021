import PropTypes from 'prop-types';
import React from 'react';
// import './form-dropdown.styles.css';
import OutsideClickHandler from 'react-outside-click-handler';
import imgDownArrow from '../../../assets/images/form-dropdown-arrow.png';
import styles from './form-dropdown.module.scss';

export default function FormDropdown({
  items, onItemClick, placeholder, value, style,
}) {
  const [dropdownPicker, setDropdownPicker] = React.useState(false);
  const toggleDropdown = () => setDropdownPicker(!dropdownPicker);
  return (
    <OutsideClickHandler onOutsideClick={() => setDropdownPicker(false)}>
      <div className={`${styles.dropdownContainer} pointer`} onClick={toggleDropdown} style={{ ...style }} role="presentation">
        {
          value
            ? (
              <p className={`${styles.formDropdownValue} m-0 pr-3`}>{value}</p>
            )
            : (
              <p className={`${styles.formDropdownItalicValue} m-0 pr-3`}>{placeholder}</p>
            )
        }
        <img src={imgDownArrow} alt="dropdown-arrow" height={10} width={15} />
      </div>
      {
        dropdownPicker
          && (
            <div className={`${styles.dataDropdownContent}`} style={{ ...style }}>
              {items.map((item) => {
                const onClick = () => { onItemClick(item); setDropdownPicker(false); };
                return (
                  <div onClick={onClick} className={`${styles.dataDropdownItem}`} role="presentation">
                    {item.name}
                  </div>
                );
              })}
            </div>
          )
      }
    </OutsideClickHandler>
  );
}

FormDropdown.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};
FormDropdown.defaultProps = {
  items: PropTypes.shape({
    map: () => {},
  }),
  onItemClick: () => {},
  placeholder: '',
  style: {},
  value: '',
};
