import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './spares-management.module.scss';

function RenderLoader({loaderMessage}) {
    return (
        <div className={`d-flex flex-column align-items-center justify-content-center ${styles.modalLoader}`}>
            <Loader
                type="Oval"
                color="#0578BE"
                height={40}
                width={40}
                visible
            />
            <p
                className={`${styles.loaderMessage} d-flex justify-content-center align-items-center`}
            >
                {loaderMessage}
            </p>
            <p className={`${styles.loaderLastMesssage}`}>This may take a minute. Thank you for your patience!</p>
        </div>
    );
}

export default RenderLoader;