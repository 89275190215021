import PropTypes from 'prop-types';
import React from 'react';

import styles from './work-order-tile.module.scss';

import imgRightArrow from '../../../assets/images/right-arrow.png';
import { assetViewModalStrings } from '../../../constants/strings';

export default function WorkOrderTile({ item, onTileClick }) {
  return (
    <div className={`${styles.workOrderTileContainer} border`} onClick={onTileClick} role="presentation">
      <div style={{ width: '95%', display: 'flex', flexDirection: 'column' }}>
        <p className={`${styles.descriptionText} `}>
          <span className={`${styles.materialText}`}>
            {item.material.slice(-8)}
          </span>
          <span>
            {item.POLongText}
          </span>
        </p>
        <div className="mb-1">
          <span className={`${styles.bomListHeading}`}>
            {assetViewModalStrings.MATERIAL_MEMO}
          </span>
          <span className={`${styles.bomList}`}>
            {item.materialMEMO}
          </span>
        </div>
        <div className="">
          <span className={`${styles.bomListHeading}`}>
            {assetViewModalStrings.BIN_LOCATION}
          </span>
          <span className={`${styles.bomList}`}>
            {item.stageBin}
          </span>
          <span className={`${styles.bomListHeading}`}>
            {assetViewModalStrings.UNRESTRICTED_QTY}
          </span>
          <span className={`${styles.bomList}`}>
            {item.unrestrictedQuantity}
          </span>
          <span className={`${styles.bomListHeading}`}>
            {assetViewModalStrings.UNIT_PRICE}
          </span>
          <span className={`${styles.bomList}`}>
            {`$ ${parseFloat(item.unityPrice).toFixed(2).toString()}`}
          </span>
        </div>
        {/* <p style={styles.infoText} numberOfLines={1} ellipsizeMode="tail"><span style={styles.subHeadingText}> </span></p>
        <p style={styles.infoText} numberOfLines={1} ellipsizeMode="tail"><span style={styles.subHeadingText}>: </span></p>
        <p style={styles.infoText} numberOfLines={1} ellipsizeMode="tail"><span style={styles.subHeadingText}>: </span></p> */}
      </div>
      <div style={styles.iconView}>
        <img src={imgRightArrow} alt="right-arrow" style={styles.imgRightIcon} />
      </div>
    </div>
  );
}

WorkOrderTile.propTypes = {
  item: PropTypes.shape({
    POLongText: PropTypes.string,
    material: PropTypes.string,
    materialMEMO: PropTypes.string,
    stageBin: PropTypes.string,
    unityPrice: PropTypes.number,
    unrestrictedQuantity: PropTypes.number,
  }),
  onTileClick: PropTypes.func,
};

WorkOrderTile.defaultProps = {
  item: {},
  onTileClick: () => { },
};
