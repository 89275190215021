import React from 'react';
import styles from './spares-management.module.scss';
import DatePicker from '../../controls/date-picker';
import CustomTextInput from '../../controls/custom-textinput/custom-textinput.component';
import { CustomRadio } from '../single-confirmation/single-confirmation.component';
import redWarning from '../../../assets/images/red-warning.png';
import radioTick from '../../../assets/images/radio-tick-black.png';
import info from '../../../assets/images/info.svg';
import IconRadioOn from '../../../assets/images/icon_radio-on.png';
import IconRadioOff from '../../../assets/images/icon_radio-off.png';
import { getVendorName } from './spares-management.helper';
import { format } from 'date-fns';


function RenderExternalRepairs({ handleChange, objectData, setExternalRepairs, setScrapSpares, editable }) {
    return (
        <div className="row px-2" style={{ flexWrap: 'wrap' }}>
            {
                <div className="col-lg-4 pb-2">
                    <p className={`${styles.labelTextStyle}`} style={{ marginBottom: '5px' }}>
                        Document Date
                    </p>
                    {editable ? (
                        <DatePicker
                            value={objectData.document_date ? objectData.document_date : ''}
                            onChange={(data) => {
                                handleChange('document_date', data);
                            }}
                        />) : (
                        <div className={`${styles.nameText}`}>
                            <span
                                className={`${styles.nameText}`}
                                style={{ color: '#53626A' }}
                            >
                                {objectData?.document_date && format(new Date(objectData?.document_date), "dd/MM/yyy")}
                            </span>
                        </div>
                    )
                    }
                </div>
            }
            <div className="col-lg-4 pb-2">
                <CustomTextInput
                    label='PO #'
                    value={objectData?.po_text}
                    onValueChange={(data) => {
                        handleChange('po_text', data.value);
                    }}
                    rows={1}
                    editable={editable}
                />
            </div>
            <div className="col-lg-4 pb-2">
                <CustomTextInput
                    label='Movement Title'
                    value={objectData?.movement_title}
                    onValueChange={(data) => {
                        handleChange('movement_title', data.value);
                    }}
                    rows={1}
                    editable={editable}
                    maxLength={20}
                />
                {editable && (
                    <div>
                        <img src={info} alt="info" width="15px" height="15px" />
                        <span className={`ml-1 ${styles.charactersRemaining}`}>{20 - (objectData?.movement_title?.length || 0)} Characters Remaining</span>
                    </div>
                )}
            </div>
            <div className="col-lg-4 pb-2">
                <CustomTextInput
                    label='Vendor'
                    value={editable ? objectData?.vendor?.value : `${objectData?.vendor?.value} - ${objectData?.vendor.name}`}
                    onValueChange={async (data) => {
                        handleChange('vendor', {value : data.value, name: '', loading: false});
                        if (data.value.length == 10) {
                            handleChange('vendor', {value : data.value, name: '', loading: true});
                            let response = await getVendorName(data.value);
                            handleChange('vendor', {value : data.value, name: response, loading: false})
                        }
                    }}
                    rows={1}
                    editable={editable}
                    isLoader={objectData?.vendor?.loading}
                />
                {editable && (
                    <div>
                        {!objectData.vendor?.name ? (
                            <div>
                                <img src={info} alt="info" width="15px" height="15px" />
                                <span className={`ml-1 ${styles.charactersRemaining}`}>Enter Vendor Id</span>
                            </div>
                        ) : <span className={`ml-1 ${objectData.vendor?.name == 'Vendor Does not exist' ? styles.vendorFailedText : styles.vendorText}`}>{objectData.vendor?.name == 'Vendor Does not exist' && <img src={redWarning} alt="info" width="15px" height="15px" style={{ marginRight: 5 }} />}{objectData.vendor?.name}</span>
                        }
                    </div>
                )}
            </div>
            <div className="col-lg-4 pb-2">
                <p className={`${styles.labelTextStyle}`} style={{ marginBottom: '5px' }}>
                    Storage Location
                </p>
                <div className='d-flex flex-row'>
                    <div>
                        {editable ? <CustomRadio
                            onClick={
                                () => {
                                    if (objectData.type == 'External Repairs') {
                                        let obj= {
                                            ...objectData,
                                            stockMr98: true,
                                            stockMr99: false,
                                            movement_type: '511',
                                        }
                                        setExternalRepairs(obj)
                                    };
                                    if (objectData.type == 'Scrap Spares') {
                                        let obj = {
                                            ...objectData,
                                            stockMr98: true,
                                            stockMr99: false,
                                            movement_type: 'Z20',
                                        };
                                        setScrapSpares(obj);
                                    }

                                }
                            }
                            icon={
                                objectData.stockMr98 ? IconRadioOn : IconRadioOff
                            }
                            text='Stock: MR98'
                        /> : (
                            objectData.stockMr98 ? (
                                <div>
                                    <img src={radioTick} alt='radio tick icon' width='21px' height='21px' />
                                    <span className={`${styles.stockText} ml-2`}>Stock: MR98</span>
                                </div>
                            ) : ''
                        )
                        }
                    </div>
                    <div className='pl-2'>
                        {editable ? <CustomRadio
                            onClick={
                                () => {
                                    if (objectData.type == 'External Repairs') {
                                        let obj = {
                                            ...objectData,
                                            stockMr98: false,
                                            stockMr99: true,
                                            movement_type: '511',
                                        };
                                        setExternalRepairs(obj);
                                    };
                                    if (objectData.type == 'Scrap Spares') {
                                        let obj = {
                                            ...objectData,
                                            stockMr98: false,
                                            stockMr99: true,
                                            movement_type: 'Z20',
                                        };
                                        setScrapSpares(obj);
                                    }

                                }
                            }
                            icon={
                                objectData.stockMr99 ? IconRadioOn : IconRadioOff
                            }
                            text='Non-Stock: MR99'
                        /> : (
                            objectData.stockMr99 ? (
                                <div>
                                    <img src={radioTick} alt='radio tick icon' width='21px' height='21px' />
                                    <span className={`${styles.stockText} ml-2`}>Non-Stock: MR99</span>
                                </div>
                            ) : ''
                        )
                        }
                    </div>
                </div>
            </div>
            <div className='row px-2'>
                <div className="col-lg-2">
                    <CustomTextInput
                        label='Movement Type'
                        value={objectData?.movement_type}
                        onValueChange={(data) => {
                            handleChange('movement_type', data.value);
                        }}
                        rows={1}
                        disable={true}
                        editable={editable}
                    />
                </div>
                <div className="col-lg-2 pb-2">
                    <CustomTextInput
                        label='Receipent'
                        value={objectData.recipient}
                        editable={editable}
                        onValueChange={(data) => {
                            handleChange('recipient', data.value);
                        }}
                        disable={true}
                        rows={1}
                    />
                </div>
                <div className="col-lg-2 pb-2">
                    <CustomTextInput
                        label='Material'
                        value={objectData?.sap_matl_id}
                        editable={editable}
                        disable={true}
                        onValueChange={(data) => {
                            handleChange('sap_matl_id', data.value);
                        }}
                        rows={1}
                    />
                </div>
                <div className="col-lg-2 pb-2">
                    <CustomTextInput
                        label='Plant'
                        value={objectData?.plant_id}
                        onValueChange={(data) => {
                            handleChange('plant_id', data.value);
                        }}
                        rows={1}
                        disable={true}
                        editable={editable}
                    />
                </div>
                <div className="col-lg-2 pb-2">
                    <CustomTextInput
                        label='Quantity'
                        value={objectData?.qty_required}
                        onValueChange={(data) => {
                            handleChange('qty_required', data.value);
                        }}
                        // disable={true}
                        rows={1}
                        editable={editable}
                    />
                </div>
                <div className="col-lg-2 pb-2">
                    <CustomTextInput
                        label='UoM'
                        value={objectData?.uom_id}
                        onValueChange={(data) => {
                            handleChange('uom_id', data.value);
                        }}
                        disable={true}
                        rows={1}
                        editable={editable}
                    />
                </div>
            </div>
        </div>
    );
}

export default RenderExternalRepairs;