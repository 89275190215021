import { connect } from 'react-redux';

import SparesManagementExternalRepair from './spares-management-external-repairs.component';
import {
  showLoader,
  hideLoader,
  addBreadCrumb,
  resetBreadCrumbs,
} from '../../../redux/actions/global.actions';
import updateTransactions from './spares-management-external-repairs.actions';

const mapStateToProps = (state) => ({
  global: state.globalReducer,
  sparesMgtState: state.sparesMgtReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (value) => dispatch(showLoader(value)),
  hideLoader: () => dispatch(hideLoader()),
  updateTransactions: (data) => dispatch(updateTransactions(data)),
  resetBreadCrumbs: (data) => dispatch(resetBreadCrumbs(data)),
  addBreadCrumb: (data) => dispatch(addBreadCrumb(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SparesManagementExternalRepair);
