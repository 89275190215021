import { globalActionTypes } from './action-types';

export const showLoader = (loaderMessage) => ({
  type: globalActionTypes.SHOW_LOADER,
  payload: loaderMessage,
});

export const resetBreadCrumbs = (value) => ({
  type: globalActionTypes.RESET_BREAD_CRUMB,
  payload: value,
});

export const updateLastUpdatedTime = (value) => ({
  type: globalActionTypes.UPDATE_LAST_UPDATED_TIME,
  payload: value,
});

export const addBreadCrumb = (value) => ({
  type: globalActionTypes.ADD_BREAD_CRUMB,
  payload: value,
});

export const updateBreadCrumb = (value) => ({
  type: globalActionTypes.UPDATE_BREAD_CRUMB,
  payload: value,
});

export const updateGlobalSearchOrder = (value) => ({
  type: globalActionTypes.UPDATE_GLOBAL_SEARCH_ORDER,
  payload: value,
});

export const deleteBreadCrumb = (value) => ({
  type: globalActionTypes.DELETE_BREAD_CRUMB,
  payload: value,
});

export const updateFacilPlannerData = (value) => ({
  type: globalActionTypes.UPDATE_FACIL_PLANNER_DATA,
  payload: value,
});

export const updatePersonnalOption = (value) => ({
  type: globalActionTypes.UPDATE_PERSONNAL_DATA,
  payload: value,
});

export const updateUserData = (data) => ({
  type: globalActionTypes.UPDATE_USER_DATA,
  payload: data,
});
export const updateSelectedPage = (value) => ({
  type: globalActionTypes.UPDATE_SELECTED_PAGE,
  payload: value,
});

export const updateParentFollowupOrder = (value) => ({
  type: globalActionTypes.SET_PARENT_FOLLOW_UP_ORDER,
  payload: value,
});

export const updatePlantID = (data) => ({
  type: globalActionTypes.UPDATE_PLANT_ID,
  payload: data,
});

export const updateProfilePic = (data) => ({
  type: globalActionTypes.UPDATE_PROFILE_PIC,
  payload: data,
});

export const hideLoader = () => ({
  type: globalActionTypes.HIDE_LOADER,
});

export const setActiveTab = (data) => ({
  type: globalActionTypes.UPDATE_ACTIVE_TAB,
  payload: data,
});

export const dataFromSpares = (value) => ({
  type: globalActionTypes.DATA_FROM_SPARES,
  payload: value,
});

export const currentUser = (value) => ({
  type: globalActionTypes.CURRENT_USER_DATA,
  payload: value,
});

