import { getURL } from '../../../Helpers/common-helpers';
import { GET_SPARES_TRANSACTIONS } from '../../../constants/endpoints';
import { HttpGet } from '../../../services/api-services';

const getTransactions = async () => {
  try {
    const results = await HttpGet(getURL(GET_SPARES_TRANSACTIONS), {
      plantId: 1330,
    });
    return results.data.message.data;
  } catch (e) {
    throw e.message.data.error[0].description;
  }
};
export default getTransactions;
