import PropTypes from 'prop-types';
import React from 'react';
// import './form-input.styles.css';
import styles from './form-input.module.scss';

export default function FormInput({
  placeholder, value, style, onChange, inputWidth,
}) {
  return (
    <div className={`${styles.formInput} pointer`} style={{ ...style }}>
      <input className={`${styles.formInputValue}`} value={value} placeholder={placeholder} onChange={onChange} style={inputWidth && { width: inputWidth }} />
    </div>
  );
}

FormInput.propTypes = {
  inputWidth: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.shape()),
  value: PropTypes.string,
};

FormInput.defaultProps = {
  inputWidth:null,
  onChange: () => { },
  placeholder: '',
  style: { },
  value: '',
};
