import { createWorkOrderStrings, adminSetupStrings, sparesMgtStrings } from './strings';

export const createWorkOrderModal = {
  firstButton: {
    value: 'Home',
    type: 'Outline',
  },
  // secondButton: {
  //   value: 'Confirmation',
  //   type: 'Outline',
  // },
  secondButton: {
    value: 'Create Additional Follow Up order',
    type: 'Outline',
  },
};
export const opSuccessModalData = {
  firstButton: {
    value: 'Back to Work Order',
    type: 'Outline',
  },
  secondButton: {
    value: 'Go To Operation',
  },
  instructionsText1: 'Operation created successfully!',
};

export const opFailureModalData = {
  buttonText: 'Ok',
  instructionsText1: 'Failed to create operation.',
};

export const funcLocFailApiModal = {
  firstButton: {
    value: 'Retry',
    type: 'Outline',
  },
  secondButton: {
    value: 'Home',
    type: 'Outline',
  },
  instructionsText1: 'There was an issue in fetching FLOC data.',
};
export const confExecutionFailure = {
  firstButton: {
    value: 'OK',
    type: 'Outline',
  },
  secondButton: {
    value: 'Go To Confirmation',
    type: 'Outline',
  },
};

export const deleteWorkCenterAssignment = {
  firstButton: {
    value: 'Cancel',
  },
  secondButton: {
    value: 'Delete',
  },
  instructionsText1: 'Are you sure you want to delete this line?',
};

export const invalidWCModal = {
  buttonText: 'OK',
  instructionsText1: createWorkOrderStrings.M_M_C_INVALID_INS_1,
  instructionsText2: createWorkOrderStrings.M_M_C_INVALID_INS_2,
};

export const invalidInsSchModal = {
  buttonText: 'OK',
  instructionsText1: 'Something Went Wrong',
  instructionsText2: createWorkOrderStrings.M_M_C_INVALID_INS_2,
};
export const insSchModal = {
  firstButton: {
    value: 'Home',
    type: 'Outline',
  },
  secondButton: {
    value: 'Back to Inspection',
    type: 'Outline',
  },
  thirdButton: {
    value: 'Inspection List',
  },
};
export const newPageClearDataModal = {
  firstButton: {
    value: 'Yes',
    type: 'Outline',
  },
  secondButton: {
    value: 'No',
    type: 'Outline',
  },
  instructionsText1: 'Entered Data gets Deleted',
  instructionsText2: 'Are u sure u want to clear all Data',
};
export const deleteSelectedModal = {
  firstButton: {
    value: 'Cancel',
    type: 'Outline',
  },
  secondButton: {
    value: 'Delete',
    type: 'Outline',
  },
  instructionsText1: 'Would you like to delete selected spares? This action cannot be undone.',
};

export const activityTypes = {
  104: 'Equipment Down',
  105: 'Equipment Reduced Capacity',
  130: 'Production Down due to Equip',
  131: 'Production Reduced Capacity',
  110: 'Isolation Oil(Transformer Oil)',
  112: 'Lubrication Oil',
  162: 'Measurable Inspection',
  118: 'Motor Isolation',
  159: 'Non Destructive Testing',
  119: 'Offline Motor Testing',
  120: 'Online Motor Testing',
  124: 'Oscillator Monitoring',
  145: 'Thermographic',
  146: 'Ultrasound Thickness',
  147: 'Ultrasound Airborne',
  148: 'Vibration',
  108: 'Inspection with Preparation',
  111: 'Lubrication',
  132: 'Programmed Repair or Replacement',
  163: 'Test Functional',
  109: 'Inspector Check List',
  141: 'Shift Rounds',
  143: 'Sponsor Rounds',
  117: 'Mobile Equipment Inspection',
  133: 'REC',
  142: 'Shut Down Inspection',
  144: 'Start Up Inspection',
  101: 'Common',
  134: 'Regulatory Standards',
  106: 'Impromptu from Maint - ID"d outside of Maint process by Maint',
  107: 'Impromptu from Ops - ID"d outside of Maint process by Ops',
  100: 'Breakdown Maint follow up',
  113: 'Maint Inspe follow up',
  153: 'Maint Paper Checklist follow up',
  121: 'Follow up from Ops Paper Insp for Maint action',
  122: 'Follow up from Ops Paper Insp for Non - Maint action',
  128: 'Predictive follow up',
  129: 'Preventive follow up',
  149: 'Improvement follow up',
  150: 'Refurbishment follow up',
  160: 'Predictive SMDO',
  135: 'Re - Inspection',
  151: 'Energy Improvement',
  102: 'Engineering Improvement',
  103: 'Environmental Improvement',
  114: 'Maintenance Improvement',
  115: 'Management of Process Risk Improvement',
  123: 'Operational Improvement',
  140: 'Safety Improvement',
  152: 'Consumables - low cost / build material req"ts',
  136: 'Repair by External Resources',
  137: 'Repair by Internal Resources(Gerdau)',
  138: 'Rework by External Resources',
  139: 'Rework by Internal Resources(Gerdau)',
  161: 'Accident CDM - Non Maint Accident',
  125: 'Other Improvement(Non - Maint)',
  158: 'Other Ops Miscellaneous Activity(Non - Maint)',
  155: 'Other Ops Planned Corrective(Non - Maint)',
  156: 'Other Ops Predictive(Non - Maint)',
  157: 'Other Ops Preventive(Non - Maint)',
  126: 'Other Ops Periodic(Non - Maint)',
  127: 'Other Unplanned(Non - Maint)',
  154: 'Write - off Stores Obsolete Mat"l >365',
};

export const plantDetails = {
  1300: 'Manitoba',
  1301: 'Cambridge',
  1302: 'Whitby',
  1321: 'Charlotte',
  1323: 'Cartersville',
  1327: 'Midlothian',
  1330: 'Petersburg',
  1332: 'St.Paul',
  1333: 'Jackson',
  1334: 'Wilton',
  6304: 'St.Paul',
  2860: 'Midlothan GMSP',
};
export const hydrostatsUrls = {
  DEV: 'https://hydrostats-web.dev.gerdaugln.tech/',
  QA: 'https://hydrostats-web.qa.gerdaugln.tech/',
  PROD: 'https://hydrostats-web.prd.gerdaugln.tech/',
};

export const eInspectionUrls = {
  dev: (mailId) => `https://einspections.gerdaugln.tech/JobAid_TEST/auto-login/${mailId}`,
  qa: (mailId) => `https://einspections.gerdaugln.tech/JobAid_TEST/auto-login/${mailId}`,
  prod: (mailId) => `https://einspections.gerdaugln.tech/JobAid/auto-login/${mailId}`,
};

export const criticalInspectionUrls = {
  dev: (mailId) => `https://critical-inspection-web.qa.gerdaugln.tech/inspections/#username=${mailId}`,
  qa: (mailId) => `https://critical-inspection-web.qa.gerdaugln.tech/inspections/#username=${mailId}`,
  prod: (mailId) => `https://critical-inspection-web.prd.gerdaugln.tech/inspections/#username=${mailId}`,
};

export const priorityCWItems = [
  {
    name: '1 - 24 HRS',
    value: '1',
  },
  {
    name: '2 - 3 DAYS',
    value: '2',
  },
  {
    name: '3 - 1 WEEK',
    value: '3',
  },
  {
    name: '4 - 2 WEEKS',
    value: '4',
  },
  {
    name: '5 - 1 MONTH',
    value: '5',
  },
  {
    name: '6 - 2 MONTHS',
    value: '6',
  },
];

export const specialityWOItems = [
  {
    name: '01CV - CIVIL',
    value: '01CV',
    selected: false,
  },
  {
    name: '01EL - ELECTRICAL',
    value: '01EL',
    selected: false,
  },
  {
    name: '01FA - FABRICATION',
    value: '01FA',
    selected: false,
  },
  {
    name: '01LB - LUBRICATION',
    value: '01LB',
    selected: false,
  },
  {
    name: '01MA - MACHINING',
    value: '01MA',
    selected: false,
  },
  {
    name: '01MC - MECHANICAL',
    value: '01MC',
    selected: false,
  },
];
export const impactWOItems = [
  {
    name: '10I1 - ENVIRONMENTAL',
    value: '10I1',
    selected: false,
  },
  {
    name: '10I2 - SAFETY',
    value: '10I2',
    selected: false,
  },
  {
    name: '10I3 - QUALITY',
    value: '10I3',
    selected: false,
  },
  {
    name: '10I4 - PRODUCTION',
    value: '10I4',
    selected: false,
  },
  {
    name: '10I5 - ENERGY',
    value: '10I5',
    selected: false,
  },
];

export const workOrderSysCondtion = [
  {
    name: '5 - RUNTIME',
    value: '5',
  },
  {
    name: '6 - DOWNDAY',
    value: '6',
  },
  {
    name: '7 - OUTAGE',
    value: '7',
  },
  {
    name: '8 - INTERRUPTION',
    value: '8',
  },
];

export const adminSetupHeaders = [
  {
    name: adminSetupStrings.PLANT,
    flexValue: 1,
  },
  {
    name: adminSetupStrings.WC_ID,
    flexValue: 1,
  },
  {
    name: adminSetupStrings.WC_DESCRIPTION,
    flexValue: 2,
  },
  {
    name: adminSetupStrings.PERSONELL,
    flexValue: 2,
  },
  {
    name: adminSetupStrings.EINSP_DB_URL,
    flexValue: 3,
  },
  {
    name: '',
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: '',
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: '',
    flexValue: 1,
    customWidth: '5%',
  },

];

export const sparesMgtHeaders = [
  {
    name: sparesMgtStrings.PLANT_STO_LOC,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.POSTING_DATE,
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: sparesMgtStrings.MOVEMENT_TYPE,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.QTY,
    customWidth: '3%',
  },
  {
    name: sparesMgtStrings.DOC_DATE,
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: sparesMgtStrings.ENTRY_DATE,
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: sparesMgtStrings.USERNAME,
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: sparesMgtStrings.VENDOR,
    flexValue: 1,
    customWidth: '7%',
  },
  {
    name: sparesMgtStrings.GOODS_RECIPIENT,
    customWidth: '7%',
    flexValue: 1,
  },
  {
    name: sparesMgtStrings.MATERIAL_DOC,
    flexValue: 1,
    customWidth: '6%',
  },
  {
    name: sparesMgtStrings.DOCUMENT_HEADER_TEXT,
    flexValue: 1,
    customWidth: '8%',
  },
];

export const sparesMgtOptionalHeaders = [
  {
    name: sparesMgtStrings.REFERENCE_UNLOAD_POINT,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.ORDER_OPERATION_ACTIVITY,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.PURCHASE_ORDER,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.COUNTER,
    flexValue: 1,
    customWidth: '5%',
  },
  {
    name: sparesMgtStrings.SMART_ITEM,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.MOV_REASON_MOV_INDICATOR,
    flexValue: 1,
    customWidth: '10%',
  },
  {
    name: sparesMgtStrings.CUSTOMER_CONSUMPTION,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.RECEIPT_INDICATOR,
    flexValue: 1,
    customWidth: '8%',
  },
  {
    name: sparesMgtStrings.ITEM_AUTO_CREATED,
    flexValue: 1,
    customWidth: '8%',
  },
];
export const plantIdDetails = [
  {
    name: '1300 - Manitoba',
    value: '1300',
  },
  {
    name: '1301 - Cambridge',
    value: '1301',
  },
  {
    name: '1302 - Whitby',
    value: '1302',
  },
  {
    name: '1321 - Charlotte',
    value: '1321',
  },
  {
    name: '1323 - Cartersville',
    value: '1323',
  },
  {
    name: '1327 - Midlothian',
    value: '1327',
  },
  {
    name: '1330 - Petersburg',
    value: '1330',
  },
  {
    name: '1332 - St.Paul',
    value: '1332',
  },
  {
    name: '1333 - Jackson',
    value: '1333',
  },
  {
    name: '1334 - Wilton',
    value: '1334',
  },
  {
    name: '6304 - St.Paul',
    value: '6304',
  }];

export const confDetailsHeaders = ['Type', 'Personnel', 'Start Date & Time', 'End Date & Time', 'Time Worked', 'Confirmation Text'];
export const sparesManagementModal = {
  buttonText: 'OK',
};

export const plantIdMultiSelectData = [
      {
          "name": "1300",
          "value": "1300",
          "selected": false
      },
      {
          "name": "1301",
          "value": "1301",
          "selected": false
      },
      {
          "name": "1302",
          "value": "1302",
          "selected": false
      },
      {
          "name": "1321",
          "value": "1321",
          "selected": false
      },
      {
          "name": "1323",
          "value": "1323",
          "selected": false
      },
      {
          "name": "1327",
          "value": "1327",
          "selected": false
      },
      {
          "name": "1330",
          "value": "1330",
          "selected": false
      },
      {
          "name": "1332",
          "value": "1332",
          "selected": false
      },
      {
          "name": "1334",
          "value": "1334",
          "selected": false
      },
      {
          "name": "1333",
          "value": "1333",
          "selected": false
      },
      {
          "name": "6309",
          "value": "6309",
          "selected": false
      },
      {
        "name": "2860",
        "value": "2860",
        "selected": false
    },
]

export const vendorAgreementsColumnDefs = [
  {
    field: 'plant',
    headerName: 'Plant',
    flex: 1,
  },
  {
    field: 'vendor',
    headerName: 'Vendor',
    flex: 1,
    tooltipField: 'vendor',
  },
  {
    field: 'agreementPrice',
    headerName: 'Agreement Price',
    flex: 1,
  },
];

export const sparesEmail = {
  '1330': [
    'Ronald.Mohn@gerdau.com',
    'ana.walker@gerdau.com',
    'april.weathersby@gerdau.com',
    'Darrell.Gill@gerdau.com',
    'riley.lescault@gerdau.com'
  ],
  '1301':[
    'Don.Costanzo@gerdau.com',
    'FrankA.Addesso@gerdau.com',
    'mitchell.greer@gerdau.com'
  ],
  '1321':[
    'charlottestoreroom@gerdau.com'
  ],
  '1333':[
    'Phil.howell@gerdau.com',
    'Greg.godwin@gerdau.com'
  ],
  '1323':[
    'cvlstores@gerdau.com',
    'Marlon.Harris@gerdau.com'
  ],
  '1302':[
    'WhitbyReceiving@gerdau.com'
  ],
  '1327':[
    'Paul.Graves@Gerdau.com',
    'Shane.Chapman@Gerdau.com'
  ],
  '1334':[
    'Brian.michel@gerdau.com',
    'Rachel.miller@gerdau.com',
    'Marci.rominger@gerdau.com'
  ],
  '1330':[
    'cory.flett@gerdau.com',
    'dean.sitka@gerdau.com',
    'michael.hunnie@gerdau.com',
    'thomas.mcmurchy@gerdau.com',
    'murray.skrepich@gerdau.com',
    'randy.marriott@gerdau.com'
  ]
}

export const qaTestEmail = [
  {
  emailAddress: {
    address: 'sainag.chunduru@gerdau.com',
  }
  },
  {
    emailAddress: {
      address: 'brenda.wallace@gerdau.com',
    }
  },
  {
    emailAddress: {
      address: 'ken.hicks@gerdau.com',
    }
  },
]

