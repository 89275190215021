import { SPARES, AREA_GROUPS, SPARES_MGT_WITH_MATERIAL_ID, SPARES_MATERIAL_ID, VENDOR_DETAILS, SPARES_INFO } from '../../../constants/endpoints';
import { HttpGet } from '../../../services/api-services';
import { getURL } from '../../../Helpers/common-helpers';
import { newPageStrings } from '../../../constants/strings';
import exportFromJSON from 'export-from-json';
import { checkNotNull } from '../../../Helpers/common-helpers';

export const getAreaGroups = async () => {
    const apiURL = `${getURL(AREA_GROUPS)}`;
    const response = await HttpGet(apiURL);
    if (response.data.message.data.error) {
        throw response.data.message.data.error.message;
    }
    return response.data.message.data;
};

export const getSpares = async () => {
    const apiURL = `${getURL(SPARES)}`;
    const response = await HttpGet(apiURL);
    if (response.data.message.data.error) {
        throw response.data.message.data.error.message;
    }
    return response.data.message.data;
};

export const getMaterials = async (materialId) => {
    const apiURL = getURL(SPARES_MGT_WITH_MATERIAL_ID);
    const params = { materialId: materialId };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
        throw response.data.message.data.error.message;
    }
    return response.data.message.data;
};

export const getSapMaterials = async (sapMaterialId) => {
    const apiURL = `${getURL(SPARES_MATERIAL_ID)}`;
    const params = { materialId: sapMaterialId };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
        throw response.data.message.data.error[0].description;
    }
    return response.data.message.data;
};

export const exportSelectedRows = (selectedRows, filteredData) => {
    const fileName = newPageStrings.SPARES_MANAGEMENT_EXPORT_XLSX;
    const exportType = newPageStrings.XLS;
    if (selectedRows?.length) {
        return exportFromJSON({ data: selectedRows, fileName, exportType });
    }
    return exportFromJSON({ data: filteredData, fileName, exportType });
};

export const getValueByKey = (data, keyName, filterKey, idKey, value) => {
    if (!data || !data[keyName]) return null;
    const item = data[keyName].find(item => item[filterKey] === value);
    return item ? item[idKey].toString() : null;
};

export const checkAdvancedDetails = (advDetails) => {
    let flag = true;
    if (Object.keys(advDetails).length !== 0) {
        Object.values(advDetails).map((item) => {
            if (checkNotNull(item)) {
                flag = false;
            }
            return null;
        });
        return flag;
    }
    return flag;
};

export const getVendorName = async (vendorNumber) => {
    const apiURL = `${getURL(VENDOR_DETAILS)}`;
    const params = { vendorNo: vendorNumber };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data[0]?.vendorName) {
        return response.data.message.data[0]?.vendorName;
    }
    return "Vendor Does not exist";
};

export const checkExternalRepairData = (externalRepairData) => {
    if (externalRepairData?.document_date &&
        (externalRepairData.stockMr98 || externalRepairData.stockMr99) &&
        externalRepairData.movement_type && externalRepairData.movement_title
        && externalRepairData.po_text && externalRepairData.qty_required && externalRepairData.sap_matl_id
        && externalRepairData.plant_id && (externalRepairData.vendor?.name != 'Vendor Does not exist' && checkNotNull(externalRepairData.vendor?.name))
    ) {
        return false;
    }
    else {
        return true;
    }
};

export const checkScrapSpares = (scrapSparesData) => {
    if (scrapSparesData?.document_date &&
        (scrapSparesData.stockMr98 || scrapSparesData.stockMr99)
        && scrapSparesData.movement_type && scrapSparesData.movement_title
        && scrapSparesData.po_text && scrapSparesData.qty_required && scrapSparesData.sap_matl_id
        && scrapSparesData.plant_id && (scrapSparesData.vendor?.name != 'Vendor Does not exist' && checkNotNull(scrapSparesData.vendor?.name))
    ) {
        return false;
    }
    else {
        return true;
    }
};

export const checkModaldata = (subHeaderData) => {
    let canBeSubmit = false;
    // eslint-disable-next-line
    subHeaderData?.forEach((item) => {
        if (
            checkNotNull(item[newPageStrings.SITE])
            && checkNotNull(item[newPageStrings.AREA_GROUP])
            && checkNotNull(item[newPageStrings.COMPONENT_DESCRIPTION])
        ) {
            canBeSubmit = true;
        } else {
            canBeSubmit = false;
        }
    });
    return canBeSubmit;
};

export const getRequestObjectForExternalRepairs = (externalRepairData) => {
    let reqObj_511 = {
        postingDate: (new Date(externalRepairData.document_date)).toISOString().slice(0, 10),
        documentDate: (new Date(externalRepairData.document_date)).toISOString().slice(0, 10),
        headerText: " ",
        referneceDocNumber: externalRepairData.po_text,
        goodsMovementItems: [
            {
                vendor: externalRepairData.vendor.value,
                goodsRecipient: externalRepairData.recipient,
                unloadingPoint: " ",
                quantity: externalRepairData.qty_required.toString(),
                materialNo: externalRepairData.sap_matl_id.toString(),
                storageLocation: externalRepairData.stockMr98 ? 'MR98' : 'MR99',
                unitOfMeasure: externalRepairData?.uom_id || " ",
                movementType: externalRepairData.movement_type,
                plantId: externalRepairData.plant_id.toString()
            }
        ]
    }
    return reqObj_511;
}

export const getRequestObjectForScrapSpares = (scrapSpares) => {
    let reqObj_511 = {
        postingDate: (new Date(scrapSpares.document_date)).toISOString().slice(0, 10),
        documentDate: (new Date(scrapSpares.document_date)).toISOString().slice(0, 10),
        headerText: " ",
        referneceDocNumber: scrapSpares.po_text,
        goodsMovementItems: [
            {
                vendor: scrapSpares.vendor.value,
                goodsRecipient: scrapSpares.recipient,
                unloadingPoint: " ",
                quantity: scrapSpares.qty_required.toString(),
                materialNo: scrapSpares.sap_matl_id.toString(),
                storageLocation: scrapSpares.stockMr98 ? 'MR98' : 'MR99',
                unitOfMeasure: scrapSpares?.uom_id || " ",
                movementType: scrapSpares.movement_type,
                plantId: scrapSpares.plant_id.toString()
            }
        ]
    }
    return reqObj_511;
}

export const getReqObjForEditViewEntries = (value, repairStatusData, areaGroupData) => {
    const reqObj = {
        repair_status: value,
        equip_component_description: repairStatusData?.equip_component_description,
        additional_desc_info: repairStatusData?.additional_desc_info,
        critical_spare: repairStatusData?.critical_spare,
        is_asset: repairStatusData?.is_asset,
        repairable: repairStatusData?.repairable,
        sap_matl_id: repairStatusData?.sap_matl_id,
        qty_on_hand: repairStatusData?.qty_on_hand,
        qty_required: repairStatusData?.qty_required,
        notes: repairStatusData?.notes,
        model_nbr: repairStatusData?.model_nbr,
        install_time: repairStatusData?.install_time,
        qty_in_repair: repairStatusData?.qty_in_repair,
        horsepower: repairStatusData?.horsepower,
        serial_nbr: repairStatusData?.serial_nbr,
        output_rpm: repairStatusData?.output_rpm,
        mfg_part_nbr: repairStatusData?.mfg_part_nbr,
        mfg_drawing_ref: repairStatusData?.mfg_drawing_ref,
        estimated_cost: repairStatusData?.estimated_cost,
        input_rpm: repairStatusData?.input_rpm,
        servicer_factor: repairStatusData?.servicer_factor,
        updates: repairStatusData?.updates,
        amperage: repairStatusData?.amperage,
        sap_equip_id: repairStatusData?.sap_equip_id,
        lead_time: repairStatusData?.lead_time,
        mfg_oem: repairStatusData?.mfg_oem,
        sap_floc_id: repairStatusData?.sap_floc_id,
        voltage: repairStatusData?.voltage,
        ratio: repairStatusData?.ratio,
        created_by: repairStatusData.created_by,
        modified_by: repairStatusData.modified_by,
        plant_id: typeof repairStatusData?.plant_id === 'number' ? repairStatusData?.plant_id : getPlantValue(repairStatusData?.plant_id),
        area_group_id: getValueByKey(areaGroupData, 'areaGroups', 'area_group', 'area_group_id', repairStatusData?.area_group),
        uom_id: repairStatusData?.unit_of_measure && getValueByKey(areaGroupData, 'uom', 'unit_of_measure', 'uom_id', repairStatusData?.unit_of_measure),
        area_sub_group_1_id: (repairStatusData?.area_sub_group_1 && getValueByKey(areaGroupData, 'areaSubGroups1', 'area_sub_group_1', 'area_sub_group_1_id', repairStatusData?.area_sub_group_1)) || '1',
        area_sub_group_2_id: (repairStatusData?.area_sub_group_2 && getValueByKey(areaGroupData, 'areaSubGroups2', 'area_sub_group_2', 'area_sub_group_2_id', repairStatusData?.area_sub_group_2)) || '1',
        phase_id: (repairStatusData?.phase_value && getValueByKey(areaGroupData, 'phase_values', 'phase_value', 'phase_id', repairStatusData?.phase_value)) || '1',
        ac_dc_id: (repairStatusData?.ac_dc_value && getValueByKey(areaGroupData, 'ac_dc_values', 'ac_dc_value', 'ac_dc_id', repairStatusData?.ac_dc_value)) || '1',
        construction_material_id: (repairStatusData?.construction_material && getValueByKey(areaGroupData, 'construction_materials', 'construction_material', 'construction_material_id',repairStatusData?.construction_material)) || '1',
        frame_id: (repairStatusData?.frame_value && getValueByKey(areaGroupData, 'frame_values', 'frame_value', 'frame_id',repairStatusData?.frame_value)) || '1',
        storage_location_id: (repairStatusData?.storage_location && getValueByKey(areaGroupData, 'storage_locations', 'storage_location', 'storage_location_id',repairStatusData?.storage_location)) || '1',
        location_used_id: (repairStatusData?.location_used && getValueByKey(areaGroupData, 'location_used_values', 'location_used', 'location_used_id', repairStatusData?.location_used)) || '1',
        class_id: (repairStatusData?.spares_class && getValueByKey(areaGroupData, 'spares_class', 'spares_class', 'class_id', repairStatusData?.spares_class)) || '1',
        stores_item_id: (repairStatusData?.stores_item && getValueByKey(areaGroupData, 'storeItems', 'stores_item', 'stores_item_id', repairStatusData?.stores_item)) || '1',
        Attachments: repairStatusData?.Attachments || [],
    };
    return reqObj;
}

export const getEditData = async (sparesId) => {
    const apiURL = getURL(SPARES_INFO);
    const params = { sparesId }
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
        throw response.data.message.data.error.message;
    }
    return response.data.message.data;
};