import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
// import './e-inspection.styles.css';
import IframeResizer from 'iframe-resizer-react';
import config from '../../../utilities/config';
import ContentWithBreadcrumb from '../../templates/content-with-breadcrumb';
import { pages } from '../../../constants/strings';
import { eInspectionUrls } from '../../../constants/enums';
import styles from './e-inspection.module.scss';
import { loginRequest } from '../../../Helpers/auth-config';

export default function EInspection({ addBreadCrumb, global }) {
  const iframeRef = useRef(null);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  React.useEffect(() => {
    addBreadCrumb({
      name: pages.EINSPECTION_BC,
      action: null,
    });
    /* eslint-disable-next-line */
  }, []);
  const mailId = global.userData.mail.substring(
    0,
    global.userData.mail.indexOf('@'),
  );
  let url = eInspectionUrls.dev(mailId);
  if (config.ENVIRONMENT === 'QA') {
    url = eInspectionUrls.qa(mailId);
  } else {
    url = eInspectionUrls.prod(mailId);
  }

  window.addEventListener('message', async (event) => {
    console.log('Message received from the child: ', JSON.stringify(event.data)); // Message received from parent
    let response;
    if (account) {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
    }
    iframeRef.current.sendMessage(response.accessToken);
  });

  // let url = `https://einspections.gerdaugln.tech/JobAid/external-login/jobaid/MID/ken.hicks`

  const onJobAidMessage = (data) => {
    iframeRef.current.sendMessage('Hello back from the parent page');
  };

  return (
    <ContentWithBreadcrumb title={pages.EINSPECTION}>
      <div
        className={`${styles.eIContainer} d-flex justify-content-center align-items-center`}
      >
        <IframeResizer
          forwardRef={iframeRef}
          heightCalculationMethod="taggedElement"
          src={url}
          minHeight={window.innerHeight - 124}
          onMessage={onJobAidMessage}
          style={{ width: '1px', minWidth: '100%' }}
        />
      </div>
    </ContentWithBreadcrumb>
  );
}

EInspection.propTypes = {
  addBreadCrumb: PropTypes.func,
  global: PropTypes.shape({
    userData: PropTypes.shape({
      mail: PropTypes.string,
    }),
  }),
};

EInspection.defaultProps = {
  addBreadCrumb: () => { },
  global: {},
};
