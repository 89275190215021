import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { updateSelectedPage } from '../../../redux/actions/global.actions';
import { sortArrayByKey } from '../../pages/week-schedule/week-schedule.helper';
import { updateScheduleOrder } from '../../pages/week-schedule/week-schedule.actions';
import { pages, scheduleListStrings } from '../../../constants/strings';
import ListOperations from '../list-operations';
import SingleOperation from '../list-operations/single-operation.component';
import ScheduleContent from './schedule-content.component';
import CustomButton from '../custom-button';
import EmptyList from '../empty-list';
import SCHEDULE from '../../pages/week-schedule/week-schedule.strings';
// eslint-disable-next-line
// import CustomDropdown from '../custom-dropdown/custom-dropdown.component';
import blueClose from '../../../assets/images/blue-close.png';
import removeItem from '../../../assets/images/remove-item.png';
import filterListIcon from '../../../assets/images/filter.png';
import CustomTextInput from '../custom-textinput/custom-textinput.component';
import CustomMultiSelect from '../custom-multi-select';
import CustomDateRangePicker from '../date-range-picker/date-range-picker.component';
import { checkNotNull } from '../../../Helpers/common-helpers';
import styles from './schedule-list.module.scss';

function OpView({
  index, revisionDescCheck = '', revision, operations, facilPlannerData = [], isFacilSchedule = false,
}) {
  const dispatch = useDispatch();
  const handleWork = (work) => {
    dispatch(updateScheduleOrder(work));
    dispatch(updateSelectedPage(pages.WEEK_SCHEDULE));
  };
  const state = useSelector((store) => store.globalReducer);

  if (state.activeTab !== scheduleListStrings.FOLLOW_UP_BACKLOG) {
    return (
      <ListOperations
        collabsable
        startClose={index !== 0}
        key={revision}
        title={{
          text: `${scheduleListStrings.REVISION} ${revision}`,
          description: ` - ${revisionDescCheck}`,
          color: index !== 0 ? '#83929B' : '#003366',
        }}
      >
        {isFacilSchedule && facilPlannerData.length > 0
          ? facilPlannerData.map((wrkCenterObj) => {
            if (
              operations.filter(
                (item) => item.mnWrkCtrId === wrkCenterObj.workCenter,
              ).length > 0
            ) {
              return (
                <ListOperations
                  collabsable
                  startClose={index !== 0}
                  key={revision}
                  textColor="#53626A"
                  title={{
                    text: `${scheduleListStrings.WORK_CENTER} ${wrkCenterObj.workCenter}`,
                    description: ` - ${wrkCenterObj.workCenter_description}`,
                    color: '#F1F5F8',
                  }}
                >
                  {sortArrayByKey(
                    operations.filter(
                      (item) => item.mnWrkCtrId === wrkCenterObj.workCenter,
                    ),
                    ['earliestStartdate', 'priority', 'description'],
                  ).map((work, filteredOperationIndex) => (
                    (
                      <SingleOperation
                        // key={work.orderId + work.description + index}
                        style={{
                          backgroundColor:
                          filteredOperationIndex % 2 === 0 ? '#FFF' : '#F2F8FC',
                        }}
                        onClick={() => handleWork(work)}
                      >
                        <ScheduleContent
                          data={{
                            title: work.description,
                            order: `${scheduleListStrings.ORDER} ${work.orderId} - ${work.shortDescription}`,
                            earliestStart: work.earliestStartdate,
                            operation: `${scheduleListStrings.OPERATION} ${work.activity} - ${work.description}`,
                            systemStatus: work.systemStatus,
                            createdBy: work.createdBy,
                            userStatus: work.userStatus,
                            priority: work.priority,
                            workcenter: work.mnWrkCtrId,
                          }}
                        />
                      </SingleOperation>
                    )
                  ))}
                </ListOperations>
              );
            }
            return '';
          })
          : sortArrayByKey(operations, [
            'earliestStartdate',
            'priority',
            'description',
          ]).map((work, operationsIndex) => (
            (
              <SingleOperation
                key={work.orderId + work.description + operationsIndex}
                style={{
                  backgroundColor: operationsIndex % 2 === 0 ? '#FFF' : '#F2F8FC',
                }}
                onClick={() => handleWork(work)}
              >
                <ScheduleContent
                  data={{
                    title: work.description,
                    order: `${scheduleListStrings.ORDER} ${work.orderId} - ${work.shortDescription}`,
                    earliestStart: work.earliestStartdate,
                    operation: `${scheduleListStrings.OPERATION} ${work.activity} - ${work.description}`,
                    systemStatus: work.systemStatus,
                    createdBy: work.createdBy,
                    userStatus: work.userStatus,
                    priority: work.priority,
                    workcenter: work.mnWrkCtrId,
                  }}
                />
              </SingleOperation>
            )
          ))}
      </ListOperations>
    );
  }
  return (
    <ListOperations
      collabsable
      startClose={index !== 0}
      key={revision}
      title={{
        text: `${revision}`,
        description: ' ',
        color: index !== 0 ? '#83929B' : '#003366',
      }}
    >
      {sortArrayByKey(operations, [
        'earliestStartdate',
        'priority',
        'description',
      ]).map((work, arrayIndex) => (
        (
          <SingleOperation
            key={work.orderId + work.description + arrayIndex}
            style={{ backgroundColor: arrayIndex % 2 === 0 ? '#FFF' : '#F2F8FC' }}
            onClick={() => handleWork(work)}
          >
            <ScheduleContent
              data={{
                title: work.description,
                order: `${scheduleListStrings.ORDER} ${work.orderId} - ${work.shortDescription}`,
                earliestStart: work.earliestStartdate,
                operation: `${scheduleListStrings.OPERATION} ${work.activity} - ${work.description}`,
                systemStatus: work.systemStatus,
                createdBy: work.createdBy,
                userStatus: work.userStatus,
                priority: work.priority,
                workcenter: work.mnWrkCtrId,
              }}
            />
          </SingleOperation>
        )
      ))}
    </ListOperations>
  );
}
OpView.propTypes = {
  index: PropTypes.number,
  revisionDescCheck: PropTypes.string,
  revision: PropTypes.string,
  operations: PropTypes.arrayOf(PropTypes.shape({})),
  facilPlannerData: PropTypes.bool,
  isFacilSchedule: PropTypes.bool,
};
OpView.defaultProps = {
  index: null,
  revisionDescCheck: '',
  revision: '',
  operations: [],
  facilPlannerData: false,
  isFacilSchedule: false,
};

export default function ScheduleList({
  revisions = [],
  facilPlannerData = false,
  isFacilSchedule = false,
}) {
  const y = [];
  revisions.forEach((item) => {
    const createdBy = item[0];
    y.push({ name: createdBy, value: createdBy, selected: false });
  });
  const state = useSelector((store) => store.globalReducer);
  const withRevisions = revisions.filter((rev) => rev[0] !== '');
  const [openModal, setOpenModal] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [date, setDate] = useState([null, null]);
  const [filteredData, setFilteredData] = useState(null);
  const [textValue, setTextValue] = useState('');
  const [multiSelectValue, setMultiSelectValue] = useState('');
  const [count, setCount] = useState(0);
  // const facilPlannerDataOp = facilPlannerData;

  const clear = () => {
    setCount(0);
    setDate([null, null]);
    setMultiSelectValue('');
    setTextValue('');
    setDropdownData(
      dropdownData.map((item) => (
        {
          ...item,
          selected: false,
        }
      )),
    );
  };

  useEffect(() => {
    clear();
    setFilteredData(_.cloneDeep(revisions));
    const multiselectDropdownData = [];
    revisions.forEach((item) => {
      const createdBy = item[0];
      multiselectDropdownData.push({ name: createdBy, value: createdBy, selected: false });
    });
    setDropdownData(multiselectDropdownData);
    // eslint-disable-next-line
  }, [revisions]);

  const remove = (index) => {
    dropdownData[index].selected = false;
    const data = dropdownData;
    setDropdownData(dropdownData);
    setMultiSelectValue(data.map((item) => (item.selected ? item.name : '')));
    let x = 0;
    dropdownData.map((item) => {
      if (item.selected) {
        x += 1;
        setCount(x);
      } else {
        setCount(x);
      }
      return item;
    });
  };

  const filterOperations = (data) => {
    let filteredOperationsData = _.cloneDeep(data);
    if (dropdownData.filter((item) => item.selected).length > 0) {
      filteredOperationsData = filteredOperationsData.filter(
        (item) => dropdownData.filter((item1) => item1.selected && item1.name === item[0]).length > 0,
      );
    }
    filteredOperationsData.forEach((groupedOperations, index) => {
      if (checkNotNull(date[0]) && checkNotNull(date[1])) {
        filteredOperationsData[index][1] = [
          ...groupedOperations[1].filter((item) => {
            const createdOn = new Date(
              parseInt(item.createOn.substr(0, 4), 10),
              parseInt(item.createOn.substr(4, 2), 10) - 1,
              item.createOn.substr(6, 2),
            );
            return date[0] < createdOn && date[1] > createdOn;
          }),
        ];
      }
      if (textValue !== '') {
        filteredOperationsData[index][1] = [
          ...groupedOperations[1].filter((item) => item.shortDescription.includes(textValue)),
        ];
      }
    });
    filteredOperationsData = filteredOperationsData.filter((item) => item[1].length > 0);
    setFilteredData(filteredOperationsData.length > 0 ? filteredOperationsData : null);
  };

  const onValueChange = (val) => {
    setTextValue(val.value);
  };

  const updateMultiSelect = (val) => {
    setMultiSelectValue(val?.value);
    setDropdownData(val.items);
    let x = 0;
    val.items.map((item) => {
      if (item.selected) {
        x += 1;
        setCount(x);
      } else {
        setCount(x);
      }
      return item;
    });
  };
  const filterItems = () => {
    setOpenModal(false);
    filterOperations(revisions);
  };

  return (
    <>
      {state.activeTab === scheduleListStrings.FOLLOW_UP_BACKLOG ? (
        <div className="my-2" style={{ height: '30px' }}>
          <div className="mx-2">
            {!openModal ? (
              <CustomButton
                customStyle={{}}
                outline
                onPress={() => {
                  setOpenModal(true);
                }}
                value={scheduleListStrings.FILTER_LIST}
              />
            ) : (
              <div className={`${styles.filterListDiv}`}>
                <div className={`${styles.filterListHeader}`}>
                  <div className={`${styles.headerTitl}`}>
                    <img
                      src={filterListIcon}
                      alt="filter"
                      style={{ marginRight: '10px', marginBottom: '2px' }}
                    />
                    {scheduleListStrings.FILTER_LIST}
                  </div>
                  <div role="presentation" onClick={() => setOpenModal(false)} onKeyDown={(e) => { if (e.keyCode === 13) setOpenModal(false); }}>
                    <img
                      src={blueClose}
                      alt="close"
                      className={`${styles.close}`}
                    />
                  </div>
                </div>
                <div className={`${styles.filterListBody}`}>
                  <div style={{ marginTop: '5px', height: '60px' }}>
                    <div className="d-flex justify-content-between">
                      <div className={`${styles.headerSide}`}>
                        <div className={`${styles.headerText} mt-1`}>
                          {scheduleListStrings.CREATED_BY}
                        </div>
                        <div className={`${styles.notification}`}>{count}</div>
                      </div>
                      <div
                        className={`${count > 0 ? styles.coloredClearAll : styles.clearAll
                        }`}
                        role="presentation"
                        onClick={clear}
                        onKeyDown={(e) => { if (e.keyCode === 13) clear(); }}
                      >
                        {scheduleListStrings.CLEAR_ALL}
                      </div>
                    </div>
                    <CustomMultiSelect
                      data={{
                        name: '',
                        items: dropdownData,
                        value: multiSelectValue,
                        selectedObject: '',
                      }}
                      editable
                      isEditOrder={false}
                      updateMultiSelect={updateMultiSelect}
                      style={{ zIndex: 800 }}
                    />
                  </div>
                  <div className="d-flex flex-row gap-3 flex-wrap">
                    {dropdownData.map((item, index) => {
                      if (item.selected) {
                        return (
                          <div className="mt-3">
                            <span className={`${styles.selectedValue}`}>
                              <span role="presentation" onClick={() => remove(index)} onKeyDown={(e) => { if (e.keyCode === 13) remove(index); }}>
                                <img
                                  src={removeItem}
                                  alt="close"
                                  className="closeItem"
                                />
                              </span>
                              <span className={`${styles.selectedItem}`}>{item.name}</span>
                            </span>
                          </div>
                        );
                      }
                      return '';
                    })}
                  </div>
                  <div>
                    <div className={`${styles.headerText} mt-2 mb-1`}>
                      {scheduleListStrings.CREATED_ON}
                    </div>
                    <div>
                      <CustomDateRangePicker
                        onOkClick={(e) => setDate(e)}
                        value={date}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={`${styles.headerText} mt-1`}>
                      {scheduleListStrings.DESCRIPTION}
                    </div>
                    <div className="mt-1">
                      <CustomTextInput
                        value={textValue}
                        editable
                        onValueChange={onValueChange}
                        rows={1}
                        placeholder=" "
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.filterListFooter} mt-4`}>
                  <CustomButton
                    customStyle={{ width: '100%' }}
                    onPress={filterItems}
                    value={scheduleListStrings.FILTER}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {state.activeTab === scheduleListStrings.FOLLOW_UP_BACKLOG ? (
        <>
          {filteredData?.map((item, index) => {
            const data = item[1];
            const { createdBy } = data[0];
            const operations = [];
            data.forEach((order) => {
              order.operations.forEach((operation) => {
                operations.push({
                  ...order,
                  ...operation,
                });
              });
            });
            return (
              <OpView
                key={index}
                index={index}
                revision={createdBy}
                operations={operations}
                facilPlannerData={facilPlannerData}
                isFacilSchedule={isFacilSchedule}
              />
            );
          }) || <EmptyList message={SCHEDULE.emptyScreen.filterText} />}
        </>
      ) : (
        <>
          {withRevisions.map((item, index) => {
            const data = item[1];
            const { revision, revisionDesc } = data[0];
            const revisionDescCheck = revisionDesc ?? '';
            const operations = [];
            data.forEach((order) => {
              order.operations.forEach((operation) => {
                operations.push({
                  ...order,
                  ...operation,
                });
              });
            });
            return (
              <OpView
                key={index}
                index={index}
                revision={revision}
                revisionDescCheck={revisionDescCheck}
                operations={operations}
                facilPlannerData={facilPlannerData}
                isFacilSchedule={isFacilSchedule}
              />
            );
          })}
          {revisions
            .filter((rev) => rev[0] === '')
            .map((item, index) => {
              const data = item[1];
              const { revision, revisionDesc } = data[0];
              const revisionDescCheck = revisionDesc ?? '';
              const operations = [];
              data.forEach((order) => {
                order.operations.forEach((operation) => {
                  operations.push({
                    ...order,
                    ...operation,
                  });
                });
              });
              return (
                <OpView
                  key={index}
                  index={index}
                  revision={revision}
                  revisionDescCheck={revisionDescCheck}
                  operations={operations}
                  facilPlannerData={facilPlannerData}
                  isFacilSchedule={isFacilSchedule}
                />
              );
            })}
        </>
      )}
    </>
  );
}

ScheduleList.propTypes = {
  facilPlannerData: PropTypes.bool,
  isFacilSchedule: PropTypes.bool,
  revisions: PropTypes.array,
};

ScheduleList.defaultProps = {
  revisions: [],
  facilPlannerData: false,
  isFacilSchedule: false,
};
