import PropTypes from 'prop-types';
import React from 'react';
import styles from './labeled-field.module.scss';

function LabeledField({
  fieldClass, customStyle, label, children,
}) {
  return (
    <div
      style={customStyle && customStyle}
      className={`${styles.labeledField} ${fieldClass && fieldClass}`}
    >
      {label && <span className={`${styles.labeledField__field}`}>{label}</span>}
      {children && children}
    </div>
  );
}

LabeledField.propTypes = {
  children: PropTypes.element,
  customStyle: PropTypes.shape({}),
  fieldClass: PropTypes.string,
  label: PropTypes.string,
};

LabeledField.defaultProps = {
  children: null,
  customStyle: {},
  fieldClass: '',
  label: '',
};

export default LabeledField;
