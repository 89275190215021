import { appColors } from '../../../constants/colors';
import { appFontFamilies, appFontSizes } from '../../../constants/styles';

const styles = {
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.0001)',
    display: 'flex',
  },
  textMessage: {
    color: appColors.TEX,
    fontSize: 20,
    marginTop: 20,
    textAlign: 'center',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColors.WHITE_BG,
    width: '100%',
    flexDirection: 'column',
    paddingTop: 30,
    height: 300,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  resultsView: {
    backgroundColor: appColors.TEXT_DARK_BLUE,
    paddingLeft: 10,
    padding: 10,
  },
  resultText: {
    fontFamily: appFontFamilies.BARLOW_BOLD,
    fontSize: 20,
    color: appColors.TEXT_WHITE,
    margin: 0,
  },
  searchView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 25,
    marginRight: 25,
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
  },
  goButton: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  imgIcon: {
    resizeMode: 'contain',
    width: 19,
    height: 19,
    marginLeft: 5,
    marginRight: 5,
  },
  searchText: {
    fontFamily: appFontFamilies.BARLOW_BOLD,
    fontSize: 24,
    color: appColors.TEXT_DARK_BLUE,
    marginLeft: 10,
    paddingTop: 10,
  },
  titleView: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: appColors.BUTTON_BACKGROUND,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  contentView: {
    backgroundColor: appColors.WHITE_BG,
    borderBottomRadius: 5,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    width: '100%',
    maxHeight: '100%',
    paddingBottom: 10,
  },
  closeButton: {
    flex: 1,
    alignItems: 'flex-end',
    marginRight: 20,
    cursor: 'pointer',
  },
  imgClose: {
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
  modalView: {
    minHeight: 300,
    minWidth: 800,
    maxHeight: '60%',
    maxWidth: 1000,
    margin: 'auto',
    borderRadius: 5,
  },
  buttonView: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: appColors.BUTTON_BACKGROUND,
    // marginHorizontal: 10,
    marginTop: 10,
    borderRadius: 2,
  },
  noButton: {
    marginLeft: 0,
  },
  yesButton: {
    marginRight: 0,
  },
  titleText: {
    fontFamily: appFontFamilies.BARLOW_BOLD,
    fontSize: 18,
    color: appColors.TEXT_WHITE,
    width: '100%',
    textAlign: 'center',
    paddingVertical: 5,
    marginBottom: 0,
  },
  instructionsText: {
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    fontSize: 16,
    color: appColors.TEXT_BLACK,
    marginBottom: 5,
    lineHeight: 24,
    textAlign: 'center',
  },
  buttonText: {
    color: appColors.TEXT_WHITE,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    fontSize: appFontSizes.SIDE_HEADING,
  },
  boldWO: {
    fontFamily: appFontFamilies.OPENSANS_BOLD,
  },
  textInputContainer: {
    marginLeft: 17,
    backgroundColor: appColors.SEARCH_BAR_BG,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    paddingHorizontal: 11,
    marginRight: 10,
    width: '100%',
  },
  searchContainer: {
    backgroundColor: appColors.WHITE_BG,
    paddingBottom: 10,
  },
  textInput: {
    fontSize: 16,
    height: 35,
    padding: 5,
    color: appColors.TEXT_BLACK,
    backgroundColor: appColors.SEARCH_BAR_BG,
    borderWidth: 0,
    width: '100%',
  },
  toolboxIcon: {
    resizeMode: 'contain',
    marginBottom: 10,
    height: 200,
    width: 200,
  },
  failureView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 30,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  failureText: {
    color: appColors.TEXT_ERROR_MODERATE,
    fontFamily: appFontFamilies.OPENSANS_REGULAR,
    fontSize: appFontSizes.SIDE_HEADING,
    marginVertical: 5,
  },
  description: {
    color: appColors.TEXT_BLACK,
    fontFamily: appFontFamilies.OPENSANS_ITALIC,
    fontSize: appFontSizes.SIDE_HEADING,
    paddingBottom: 10,
    alignSelf: 'center',
  },
};
export default styles;
