import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import cx from 'classnames';
import styles from './asset-view-list.module.scss';
// import './asset-view-list.styles.css';
import imgFailureBucket from '../../../assets/images/empty-work-order-after-search.png';
import imgEmptyBucket from '../../../assets/images/empty-bucket.png';
import { appColors } from '../../../constants/colors';
import PageLoader from '../page-loader/page-loader.component';
import imgUnselect from '../../../assets/images/unselect-icon@2x.png';
import imgSelect from '../../../assets/images/select-icon@2x.png';
import externalRepairIcon from '../../../assets/images/external-spares.svg';
import CustomTextInput from '../custom-textinput';
import { assetViewModalStrings } from '../../../constants/strings';
import { checkNotNull } from '../../../Helpers/common-helpers';
import { sortArrayByKey } from '../../pages/week-schedule/week-schedule.helper';
import removeItem from '../../../assets/images/remove-item.png';
import AGGridTable from '../../pages/spares-management/ag-grid-table-component';
import { queryKeyStrings } from '../../../constants/strings';
import { FUNC_LOC_OPEN_ORDERS } from '../../../constants/endpoints';
import { showLoader } from '../../../redux/actions/global.actions';
import { hideLoader } from '../../../redux/actions/global.actions';
import CustomModal from '../custom-modal';
import blueClose from '../../../assets/images/Close.svg';
import { useQueryClient } from 'react-query';
import { getURL } from '../../../Helpers/common-helpers';
import { HttpGet } from '../../../services/api-services';
import { useDispatch } from 'react-redux';

function BOMResultsList({
  results = [],
  editable,
  setBomResults,
  flocDetails,
  bomFilterArray,
  setArray,
}) {
  const filteredBomFilterArray = bomFilterArray.filter((item) => item.trim() !== '');
  const [filteredBomArray, setFilteredBomArray] = React.useState(filteredBomFilterArray);
  React.useEffect(() => {
    setFilteredBomArray(filteredBomFilterArray);
  }, [bomFilterArray]);

  let filteredData = results;
  if (filteredBomArray.length > 0) {
    filteredData = results.filter((item) => {
      const description = item.bomComponentDescription.toUpperCase();
      return filteredBomArray.some((keyword) => description.includes(keyword));
    });
  }
  const removeElement = (index) => {
    const newArray = [...filteredBomArray];
    newArray.splice(index, 1);
    setArray(newArray);
    setFilteredBomArray(newArray);
  };
  const updateBomResults = (id) => {
    const assetResults = [...results];
    const index = assetResults.findIndex((item) => item.bomComponent === id);
    assetResults[index].selected = !assetResults[index].selected;
    setBomResults(assetResults);
  };
  return (
    <div className="row gx-0" style={{ overflow: 'scroll', maxHeight: 590 }}>
      <div className="col-12" style={{ marginBottom: 50 }}>
        <div className="d-flex flex-row gap-3 flex-wrap mt-2 ml-2">
          {filteredBomArray.map((item, index) => (
            <span className={`${styles.selectedValue} d-flex flex-wrap`}>
              <span
                role="presentation"
                onClick={() => removeElement(index)}
                onKeyDown={(elementIndex, e) => {
                  if (e.keyCode === 13) removeElement(elementIndex);
                }}
              >
                <img src={removeItem} alt="close" className="closeItem" />
              </span>
              <span className={`${styles.selectedItem}`}>{item}</span>
            </span>
          ))}
        </div>
        {filteredData.map((result, index) => (
          <ListItemContent
            data={result}
            editable={editable}
            flocDetails={flocDetails}
            updateMaterialResults={updateBomResults}
            style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F2F8FC' }}
            key={
              result.title
              || 0 + result.partNumber
              || 0 + result.availability
              || 0
            }
          />
        ))}
      </div>
    </div>
  );
}

function ListItemContent({
  data,
  style,
  updateMaterialResults,
  editable,
  flocDetails,
}) {
  const {
    bomComponent,
    bomComponentDescription,
    POText,
    stageBin,
    MEMOText,
    unrestrictedQuantity,
    unitPrice,
    functionalLocation,
  } = data;
  const [openViewOrdersModal, setOpenViewOrdersModal] = useState(false);
  const [openViewOrdersData, setOpenViewOrdersData] = useState(false);
  const queryClient = useQueryClient();
  const dashEmptyValue = ((value) => (
    value || '-'
  ));
  const dispatch = useDispatch();

  const onMaterialClick = () => {
    updateMaterialResults(data.bomComponent);
  };
  const viewOrderColDefs = [
    {
      field: 'orderId',
      headerName: 'Order#',
      flex: 1,
      tooltipField: 'orderId'
    },
    {
      field: 'funLocDescription',
      headerName: 'Order Description',
      flex: 1,
      tooltipField: 'funLocDescription'
    },
    {
      field: 'orderType',
      headerName: 'Order Type',
      flex: 1,
      tooltipField: 'orderType'
    },
    {
      field: 'orderSysStatus',
      headerName: 'Sys Status',
      flex: 1,
      tooltipField: 'orderSysStatus'
    },
    {
      headerName: 'User Status',
      field: 'userStatus',
      flex: 1,
      tooltipField: 'userStatus'
    },
  ];
  const getViewOrdersData = async (funcLocation) => {
    const apiURL = `${getURL(FUNC_LOC_OPEN_ORDERS)}`;
    const params = { funLoc: funcLocation };
    const response = await HttpGet(apiURL, params);
    if (response.data.message.data.error) {
      throw response.data.message.data.error[0].description;
    }
    return response.data.message.data;
  };
  const onOpenViewOrdersData = async (functionalLocation) => {
    try {
      dispatch(showLoader('Fetching View Orders Data'));
      const data = await queryClient.fetchQuery({
        queryKey: `${queryKeyStrings.VIEW_ORDERS_DATA}/${functionalLocation}`,
        queryFn: async () => {
          const response = await getViewOrdersData(functionalLocation);
          return response;
        },
        select: (viewOrderData) => viewOrderData,
        retry: false,
        staleTime: 1000,
      });
      setOpenViewOrdersData(data);
      setOpenViewOrdersModal(true);
      dispatch(hideLoader());
    } catch (e) {
      console.log(e, 'e')
      setOpenViewOrdersModal(true);
      setOpenViewOrdersData(e?.message?.data?.error[0]?.description || 'No Records Found');
      dispatch(hideLoader());
      console.log(new Error(e));
    }
  }
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onMaterialClick();
    }
  };
  const materialContent = (
    <div style={{ ...style }} className="px-3 py-2">
      <p className={`${styles.descriptionText}`}>
        <span className={`${styles.materialText}`}>
          {dashEmptyValue(bomComponent)}
        </span>
        {` ${dashEmptyValue(bomComponentDescription)}`}
        <span onClick = {() => onOpenViewOrdersData(functionalLocation)}>
          <span className={`pl-2 ${styles.linkText} pr-1`}>View Open Orders </span>
          <img src={externalRepairIcon} alt="info" width="15px" height="15px" style={{ marginBottom: 3 }} />
        </span>
      </p>
      {
        flocDetails && (
        <div className="mb-1">
          <span className={flocDetails?.value === functionalLocation ? styles.bomListHeadingGreen : styles.bomListHeading}>
            {assetViewModalStrings.FLOC}
          </span>
          <span className={`${styles.bomList}`}>
            {dashEmptyValue(functionalLocation)}
          </span>
          <span className={`${styles.bomListHeading}`}>
            {assetViewModalStrings.DESCRIPTION}
          </span>
          <span className={`${styles.bomList}`}>
            {dashEmptyValue(POText)}
          </span>
        </div>
        )
      }
      <div className="mb-1">
        <span className={`${styles.bomListHeading}`}>
          {assetViewModalStrings.PO_LONG_TEXT}
        </span>
        <span className={`${styles.bomList}`}>
          {dashEmptyValue(POText)}
        </span>
      </div>
      <div className="mb-1">
        <span className={`${styles.bomListHeading}`}>
          {assetViewModalStrings.MATERIAL_MEMO}
        </span>
        <span className={`${styles.bomList}`}>
          {dashEmptyValue(MEMOText)}
        </span>
      </div>
      <div className="">
        <span className={`${styles.bomListHeading}`}>
          {assetViewModalStrings.BIN_LOCATION}
        </span>
        <span className={`${styles.bomList}`}>
          {dashEmptyValue(stageBin)}
        </span>
        <span className={`${styles.bomListHeading}`}>
          {assetViewModalStrings.UNRESTRICTED_QTY}
        </span>
        <span className={`${styles.bomList}`}>
          {dashEmptyValue(unrestrictedQuantity)}
        </span>
        <span className={`${styles.bomListHeading}`}>
          {assetViewModalStrings.UNIT_PRICE}
        </span>
        <span className={`${styles.bomList}`}>
          {(!checkNotNull(unitPrice) ? '$ 0' : '$ ') + parseFloat(unitPrice).toFixed(2).toString()}
        </span>
      </div>
    </div>
  );

  return (
    <>
      {
        editable
          ? (
            <div
              onClick={onMaterialClick}
              role="presentation"
              onKeyDown={(e) => onKeyDown(e)}
              style={{
                ...style, flexDirection: 'row', display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              {materialContent}
              <div style={{ marginRight: 20 }}>
                <img style={{ height: 50, width: 50 }} src={data.selected ? imgSelect : imgUnselect} alt="select-icon" />
              </div>
            </div>
          )
          : (
            <div style={{
              ...style,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            >
              {materialContent}
            </div>
          )
      }
      {
        openViewOrdersModal && (
          <CustomModal
            customWidth="60%"
            actions={{
              cancel: () => {
                setOpenViewOrdersModal(false);
                setOpenViewOrdersData(null);
              },
            }}
            header={{
              title: 'Open Orders',
              color: '#003366',
              bgColor: '#e2e2e2',
              exit: true,
              scroll: true,
            }}
            icon={blueClose}
          >
            { typeof openViewOrdersData === 'object' ? <div
              className="ag-theme-alpine"
              style={{
                height: '150px',
                margin: '30px',
                // width: '95%',
              }}
            >
              <AGGridTable
                rowData={openViewOrdersData}
                columnDefs={viewOrderColDefs}
              />
            </div> : <p className='d-flex justify-content-center align-items-center' style={{height: '175px'}}>No Records Found</p>
            }
          </CustomModal>
        )
      };
    </>
  );
}

function DOCResultsList({
  results = [],
}) {
  return (
    <div className="row gx-0">
      <div className="col-12" style={{ marginBottom: 100 }}>
        {results.map((result, index) => (
          <div
            className="border-bottom"
            style={{
              flexDirection: 'column',
              padding: 10,
              paddingLeft: 20,
              backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F2F8FC',
            }}
          >
            <div>
              <a href={result.documentUrl} rel="noopener noreferrer" target="_blank" className="docListHeading m-0">{result.documentDescription}</a>
            </div>
            <div>
              <p className="docListId m-0">{result.documentNumber.slice(-8)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

DOCResultsList.propTypes = {
  results: PropTypes.instanceOf(Array),
};
DOCResultsList.defaultProps = {
  results: [],
};
function renderView({
  assetFailureMessage, docFailureMessage, bomLoading, editable, flocDetails, activePage, results, setBomResults, docValue, bomFilterValue, setArray,
}) {
  // const [bomFilterValue, setBOMFilterValue] = React.useState('');
  // const [docFilterValue, setDOCFilterValue] = React.useState('');

  if (bomLoading) {
    return (
      <PageLoader message={assetViewModalStrings.FINDING_ASSET_BOM} style={{ height: 350 }} />
    );
  }
  if ((activePage === assetViewModalStrings.ASSET_VIEW && assetFailureMessage !== '') || (activePage !== assetViewModalStrings.ASSET_VIEW && docFailureMessage !== '')) {
    return (
      <div className="justify-content-center d-flex " style={{ flexDirection: 'column', alignItems: 'center', marginTop: 45 }}>
        <div>
          <img src={imgFailureBucket} alt="empty-bucket" />
        </div>
        <div>
          <p style={{
            fontFamily: 'OpenSans-Regular',
            color: appColors.TEXT_ERROR,
            fontSize: 16,
            marginTop: 10,
          }}
          >
            {activePage === assetViewModalStrings.ASSET_VIEW ? assetFailureMessage : docFailureMessage}
          </p>
        </div>
      </div>
    );
  }
  if (results.length === 0) {
    return (
      <div className="justify-content-center d-flex " style={{ flexDirection: 'column', alignItems: 'center', marginTop: 45 }}>
        <div>
          <img src={imgEmptyBucket} alt="empty-bucket" />
        </div>
        <div>
          <p style={{
            fontFamily: 'OpenSans-Regular',
            color: appColors.TEXT_DARK_GRAYISH_BLUE,
            fontSize: 16,
            marginTop: 10,
          }}
          >
            {assetViewModalStrings.SELECT_ASSET}
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      {
        activePage === assetViewModalStrings.ASSET_VIEW
          ? (
            <BOMResultsList results={sortArrayByKey(results, ['functionalLocation'])} editable={editable} setBomResults={setBomResults} flocDetails={flocDetails} bomFilterArray={bomFilterValue} setBomFilterArray setArray={setArray} />
          )
          : (
            <DOCResultsList results={results.filter((item) => JSON.stringify(item).toUpperCase().includes(docValue.toUpperCase()))} />
          )
      }
    </>
  );
}

function ListContent({
  activePage,
  results = [],
  assetFailureMessage,
  docFailureMessage,
  bomLoading,
  editable,
  setBomResults,
  flocDetails,
}) {
  const [bomFilterValue, setBOMFilterValue] = React.useState([]);
  const [docFilterValue, setDOCFilterValue] = React.useState([]);
  const [bomValue, setBOMValue] = React.useState('');
  const [docValue, setDOCValue] = React.useState('');
  React.useEffect(() => {
    setBOMFilterValue([]);
  }, [flocDetails]);
  const setArray = (data) => {
    setBOMFilterValue(data);
  };
  const onValueChange = (data) => {
    if (activePage === assetViewModalStrings.ASSET_VIEW) {
      setBOMValue(data.value.toUpperCase());
    } else {
      setDOCValue(data.value.toUpperCase());
    }
  };
  const onKeyDown = (data) => {
    if (activePage === assetViewModalStrings.ASSET_VIEW) {
      setBOMFilterValue(bomFilterValue.concat(data.value));
      setBOMValue('');
    } else {
      setDOCFilterValue(docFilterValue.push(data.value));
    }
  };

  return (
    <>
      {
        (results?.length > 0)
        && (
          <div className="mr-2 ml-2 mt-2">
            <CustomTextInput
              value={activePage === assetViewModalStrings.ASSET_VIEW ? bomValue : docValue}
              editable
              onValueChange={onValueChange}
              rows={1}
              placeholder={activePage === assetViewModalStrings.ASSET_VIEW ? assetViewModalStrings.FILTER_ASSET_TEXT : assetViewModalStrings.FILTER_DOC_TEXT}
              onKeyDown={(e) => { if (e.keyCode === 13) { onKeyDown(e.target); } }}
            />
          </div>
        )
      }
      {renderView({
        assetFailureMessage, docFailureMessage, bomLoading, editable, flocDetails, activePage, results, setBomResults, docValue, bomFilterValue, setArray,
      })}
    </>
  );
}

ListContent.propTypes = {
  activePage: PropTypes.string,
  assetFailureMessage: PropTypes.string,
  bomLoading: PropTypes.bool,
  docFailureMessage: PropTypes.string,
  editable: PropTypes.bool,
  flocDetails: PropTypes.shape({
    value: PropTypes.string,
  }),
  results: PropTypes.instanceOf(Array),
  setBomResults: PropTypes.func,
};

ListContent.defaultProps = {
  activePage: '',
  assetFailureMessage: '',
  bomLoading: false,
  flocDetails: PropTypes.shape({
    value: '',
  }),
  docFailureMessage: '',
  editable: false,
  results: [],
  setBomResults: () => {},
};

export default function AssetViewList({
  bomLoading,
  assetFailureMessage,
  docFailureMessage,
  bomResults = [],
  editable,
  setBomResults,
  docResults = [],
  flocDetails = {},
}) {
  const [activePage, setActivePage] = React.useState(assetViewModalStrings.ASSET_VIEW);

  const assetViewClick = () => { setActivePage(assetViewModalStrings.ASSET_VIEW); };
  const documentsClick = () => { setActivePage(assetViewModalStrings.DOCUMENTS); };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div className={`border ${activePage === assetViewModalStrings.ASSET_VIEW ? styles.activePage : styles.inActivePage}`} role="presentation" onClick={assetViewClick}>
          <p className={`${styles.tabHeadingText}`}>{assetViewModalStrings.ASSET_VIEW}</p>
        </div>
        <div className={`border ${activePage === assetViewModalStrings.DOCUMENTS ? styles.activePage : styles.inActivePage}`} role="presentation" onClick={documentsClick}>
          <p className={`${styles.tabHeadingText}`}>{assetViewModalStrings.DOCUMENTS}</p>
        </div>
      </div>
      <ListContent activePage={activePage} bomLoading={bomLoading} assetFailureMessage={assetFailureMessage} docFailureMessage={docFailureMessage} results={activePage === assetViewModalStrings.ASSET_VIEW ? bomResults : docResults} setBomResults={setBomResults} editable={editable} flocDetails={flocDetails} />
    </div>
  );
}

BOMResultsList.propTypes = {
  bomFilterArray: PropTypes.array,
  editable: PropTypes.bool,
  flocDetails: PropTypes.shape({
    value: PropTypes.string,
  }),
  results: PropTypes.arrayOf(PropTypes.shape({})),
  setArray: PropTypes.func,
  setBomResults: PropTypes.func,
};

BOMResultsList.defaultProps = {
  editable: false,
  results: [],
  flocDetails: PropTypes.shape({
    value: '',
  }),
  setBomResults: () => { },
  bomFilterArray: PropTypes.shape({
    filter: () => { },
  }),
  setArray: () => { },
};
ListItemContent.propTypes = {
  MEMOText: PropTypes.string,
  POText: PropTypes.string,
  bomComponent: PropTypes.string,
  bomComponentDescription: PropTypes.string,
  data: PropTypes.shape({
    MEMOText: PropTypes.string,
    POText: PropTypes.string,
    bomComponent: PropTypes.string,
    bomComponentDescription: PropTypes.string,
    functionalLocation: PropTypes.instanceOf(Array),
    selected: PropTypes.bool,
    stageBin: PropTypes.string,
    unitPrice: PropTypes.number,
    unrestrictedQuantity: PropTypes.string,
  }),
  editable: PropTypes.bool,
  flocDetails: PropTypes.shape({
    value: PropTypes.string,
  }),
  selected: PropTypes.bool,
  stageBin: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.shape()),
  unrestrictedQuantity: PropTypes.string,
  updateMaterialResults: PropTypes.func,
};
ListItemContent.defaultProps = {
  MEMOText: '',
  POText: '',
  bomComponent: '',
  bomComponentDescription: '',
  data: PropTypes.shape({
    MEMOText: '',
    POText: '',
    bomComponent: '',
    bomComponentDescription: '',
    functionalLocation: [],
    selected: false,
    stageBin: '',
    unitPrice: null,
    unrestrictedQuantity: '',
  }),
  editable: false,
  flocDetails: PropTypes.shape({
    value: '',
  }),
  selected: false,
  stageBin: '',
  style: { },
  unrestrictedQuantity: '',
  updateMaterialResults: () => { },
};

AssetViewList.propTypes = {
  assetFailureMessage: PropTypes.string,
  bomLoading: PropTypes.bool,
  bomResults: PropTypes.instanceOf(Array),
  docFailureMessage: PropTypes.string,
  docResults: PropTypes.instanceOf(Array),
  editable: PropTypes.bool,
  // failureMessage: PropTypes.string,
  flocDetails: PropTypes.shape({
    name: PropTypes.string,
  }),
  // searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  setBomResults: PropTypes.func,
};

AssetViewList.defaultProps = {
  bomLoading: false,
  bomResults: [],
  docResults: [],
  editable: false,
  // failureMessage: '',
  flocDetails: {},
  setBomResults: () => { },
  assetFailureMessage: '',
  // searchResults: [],
  docFailureMessage: '',
};
