export const apiConstants = {
  CLIENT_ID: 'client_id',
  CLIENT_SECRET: 'client_secret',
};

export const navBarStrings = {
  WO_PLACEHOLDER: 'Search Work Order #',
  FACILITATOR: 'facilitator',
  PLANNER: 'planner',
  NO_NOTIFICATIONS_FOUND: 'No notifications found.',
  SELECT_USER: 'Select User',
  SELECT_PLANT_ID: 'Select Plant Id',
  IMPERSONATE_DETAILS: 'Impersonate Details',
  IMPERSONATE_USER_DETAILS: 'Impersonate User Details',
  BROADCAST_MESSAGE: 'Broadcast Message',
  TITLE: 'Title',
  SEND_MESSAGE: 'Send Message',
  CANCEL: 'Cancel',
  NO_NOTIFICATIONS_FOUND: 'No Notifications Found',
  BROADCAST_NOTIFICATION: 'Broadcast Notification',
  NOTIFICATIONS: 'Notifications',
  SEND_NOTIFICATIONS: 'Send Notifications',
  EXTERNAL_RESOURCES: 'External Resources',
  INITIALISING_DATA: 'Initializing Data',
  SENDING_BROADCAST_MESSAGE: 'Sending Broadcast Message',
  SELECT_OPERATION: 'Select Operation',
};

export const orderStrings = {
  ORDER: 'ORDER',
  EARLIEST_START: 'EARLIEST START',
  OPERATION: 'OPERATION',
  SYS_STATUS: 'SYSTEM STATUS',
  USER_STATUS: 'USER STATUS',
  WORK_CENTER: 'Work Center',
  PRIORITY: 'PRIORITY',
  MY_WC: 'My Work Center',
  LONG_TEXT: 'LONG TEXT',
};

export const productCatalogStrings = {
  HEADER_TEXT: 'Product Catalog',
  TITLE: 'Search Product Catalog',
  SEARCH_MATERIAL: 'Searching Materials',
  SEARCH: 'Search',
  SEARCH_INSTRUCTION: '(Search Term should be a minimum of 5 characters)',
  SEARCH_PLACEHOLDER: 'Search for Material',
  FAILURE_TEXT_2: 'Please check your search criteria and try again!',
  FAILURE_TEXT_1: 'did not return any results.',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  RESULTS: 'Results',
  SEARCH_FOR_MATERIAL: 'Search for Material',
};

export const reservationStrings = {
  HEADER_TEXT: 'Reservation',
  WORK_ORDER: 'Work Order ',
  COST_CENTER: 'Cost Center',
  NO_WORK_ORDER: 'No Work Order Selected',
  NO_COST_CENTER: 'No Cost Center Selected',
  SHORT_TEXT: 'Short Text',
  SELECT_OPERATION: 'Select Operation',
  USER: 'User',
  RESERVE_MATERIALS: 'Reserve Materials',
  MATERIALS: 'Materials',
  ATTENTION: 'Attention',
  WO_ATTENTION: 'A Work Order # is required to continue.',
  VAL_CLS_MIS: 'MATERIAL REQUIRES A WORK ORDER FOR RESERVATION',
  CHANGE_RES_TYPE: 'Change Reservation Type?',
  CHANGE_RES_TYPE_INS_1: 'Are you sure you want to change your reservation type to',
  CHANGE_RES_TYPE_INS_2: 'Your header data will be cleared but your reserved materials list will remain.',
  SAVE_RESERVATION: 'Save Reservation',
  WBS: 'WBS',
};

export const adminSetupStrings = {
  PLANT: 'Plant',
  WC_ID: 'WC ID',
  WC_DESCRIPTION: 'WC Name',
  PERSONELL: 'Personnel (Facilitator/Planner)',
  PLANNER: 'Planner',
  EINSP_DB_URL: 'eInsp DB URL (Optional)',
  RFAC: 'RFAC',
  KEY_FLD: 'Key Fld',
  SAVING_DATA: 'Saving Data...',
  DELETING_ASSIGNMENT: 'Deleting Assignment',
  NO_SELECTION: 'No Selection',
  FILTER_BY_PLANT: 'Filter by Plant',
  QUICK_FILTER: 'Quick FIlter',
  FILTER_BY_PARAMETERS: 'Filter by Work Center ID, Facilitator, or Planner',
  NEW_ENTRY: 'New Entry',
  EDIT: 'Edit',
  COPY_LINE: 'Copy line',
  DELETE: 'Delete',
  SAVE: 'Save',
  REVERT_ALL_CHANGES: 'Revert all changes',
};

export const sparesMgtStrings = {
  PLANT_STO_LOC: 'Plant / Storage Location',
  POSTING_DATE: 'Posting Date',
  MOVEMENT_TYPE: 'Movement Type',
  MATERIAL_ID: 'Material Id',
  MATERIAL_DES: 'Material Description',
  QTY: 'Qty',
  DOC_DATE: 'Doc Date',
  ENTRY_DATE: 'Entry Date',
  USERNAME: 'Username',
  VENDOR: 'Vendor',
  GOODS_RECIPIENT: 'Goods Recipient',
  MATERIAL_DOC: 'Material Doc',
  DOCUMENT_HEADER_TEXT: 'Document Header Text',
  REFERENCE_UNLOAD_POINT: 'Reference / Unloading Point',
  ORDER_OPERATION_ACTIVITY: 'Order / Operation / Activity',
  PURCHASE_ORDER: 'Purchase Order',
  SMART_ITEM: 'Smart No / Item',
  COUNTER: 'Counter',
  MOV_REASON_MOV_INDICATOR: 'Movement Reason / Movement Indicator',
  CUSTOMER_CONSUMPTION: 'Customer Consumption',
  RECEIPT_INDICATOR: 'Receipt Indicator / Goods Receipt',
  ITEM_AUTO_CREATED: 'Item Auto Created',
};

export const apiHeaders = {
  CLIENT_ID: '9883754b590e462d92b45c51733d9f00',
  CLIENT_SECRET: '37c75857466F41b9B84A741c195B4635',
};

export const assetViewStrings = {
  HEADER_TEXT: 'Asset View',
  SELECT_ASSET: 'Asset',
  EQUIP_LABEL: 'Equipment (Optional)',
  SEARCH: 'Search',
  SCAN_FLOC_BARCODE: 'Scan FLOC Barcode',
  FLOC_LEVEL: 'Functional Location - Level',
  LEVEL: 'level',
  NO_RECORDS_FOUND: 'No records found',
  NO_BOM_FOUND: 'No BOM found for selected Asset',
  SOMETHING_WENT_WRONG: 'Something went wrong while fetching BOM from Asset',
  BARCODE_TEXT: 'Tap to scan asset barcode or select manually from the options listed below.',
  FILTER_BOM_LIST: 'Filter BOM List',
  ASSET_VIEW: 'Asset View',
  DOCUMENTS: 'Documents',
  FILTER_DOC_TEXT: 'Filter Documents List',
  CREATE_ORDER: 'Create Work Order With Selected Asset & Materials',
  US_TAMPA_OFFICE: 'US Tampa Office',
  FUNCTIONAL_LOCATION: 'Functional Location',
  EQUIPMENTS: 'Equipments',

};

export const assetViewModalStrings = {
  HEADER_TEXT: 'Asset View',
  FLOC_EQUIP: 'FLOC/Equipment',
  SELECT_ASSET: 'Select Asset above to Begin',
  PART_HASH: 'PART #',
  LOCATION: 'LOCATION',
  AVAILABILITY: 'AVAILABILITY',
  // PO_LONG_TEXT: 'PO Long Text',
  // MATERIAL_MEMO: 'Material Memo',
  BUTTON_TEXT: 'Add Selected Materials to Work Order',
  ASSET_VIEW: 'Asset View',
  DOCUMENTS: 'Documents',
  FILTER_ASSET_TEXT: 'Filter BOM List',
  FILTER_DOC_TEXT: 'Filter Documents List',
  DESCRIPTION: 'DESCRIPTION',
  PO_LONG_TEXT: 'PO LONG TEXT',
  MATERIAL_MEMO: 'MATERIAL MEMO',
  BIN_LOCATION: 'BIN LOCATION',
  UNRESTRICTED_QTY: 'UNRESTRICTED QTY',
  UNIT_PRICE: 'UNIT PRICE',
  FLOC: 'FLOC',
  FINDING_ASSET_BOM: 'Finding Asset BOM',
  NO_RECORDS_FOUND: 'No records found:',
  NO_BOM_FOUND: 'No BOM found for selected Asset',
  SOMETHING_WENT_WRONG: 'Something went wrong while fetching BOM from Asset',

};

export const homeStrings = {
  instructions:
    'In order to use this application you must grant access to all the requested permissions on the following screen.',
  errorMessage:
    'You are not authorized to access the Maintenance Portal. If you believe you have received this message in error, contact your supervisor to obtain access.',
};

export const createWorkOrderStrings = {
  HEADER_TEXT: 'Create New Work Order',
  SAVE_BUTTON_TEXT: 'Create Work Order',
  ASSET_WO_TYPE: 'workOrderFromAsset',
  FOLLOW_UP_TYPE: 'followup',
  EDIT_TYPE: 'editorder',
  ORDER_TYPE: 'Order Type',
  SHORT_DESC: '(First 40 Characters Display As Short Text)',
  ACTIVITY_TYPE: 'Activity Type',
  REQ_KITTING: 'Requires Kitting',
  OPTIONAL_TEXT: 'Optional - Basic Information',
  REQ_INFO: 'Required Information',
  CREATE_CONF: 'Create Confirmation',
  SAVE_CHANGES: 'Save Changes',
  CANCEL: 'Cancel',
  OPTIONAL_OPERATION: 'Optional - Operational Information',
  IMM_EXEC_TEXT: 'Create w/ Immediate Execution',
  USER: 'User',
  MAIN_WORK_CENTER: 'Main Work Center',
  FUNC_LOC_LEV_1: 'Functional Location - Level 1',
  FUNC_LOC_LEV_2: 'Functional Location - Level 2',
  FUNC_LOC_LEV_3: 'Functional Location - Level 3',
  FUNC_LOC_LEV_4: 'Functional Location - Level 4',
  FUNC_LOC_LEV_5: 'Functional Location - Level 5',
  FUNC_LOC_LEV_6: 'Functional Location - Level 6',
  PRIORITY: 'Priority',
  SYS_CONDITION: 'System Condition',
  SHORT_TEXT: 'Order Description',
  SELECT_ORDER_TYPE: 'Select Order Type',
  SELECT_FLOC_2: 'Select FLOC Level 2',
  SELECT_MATERIALS: 'NEXT: Select Materials For Goods Movement',
  MATERIAL_PO_LONG: 'Material PO Long',
  OPTIONAL: '- Optional',
  M_M_C_INVALID_INS_1: 'Main Work Center not valid.',
  M_M_C_INVALID_INS_2: 'Please correct your entry and try again.',
  BASIC_START_DATE: 'Basic Start Date',
  REVISION: 'Revision',
  IMPACT: 'Impact',
  MATERIAL_DETAIL: 'Material Detail',
  SPECIALITY: 'Speciality',
  REQUIRED_RESOURCES: 'Required Resources',
  KITTING: 'Kitting',
  OP_WK_CNTR: 'Operation Work Center',
  NO_OF_RQD_RES: 'Number of Required Resources',
  OP_DURATION: 'Duration (Hours)',
  OP_DESC: 'Operation Description',
  MATERIAL: 'Material #',
  MATERIAL_MEMO: 'Material Memo',
  PO_TEXT: 'PO_TEXT',
  QTY_RQD: 'Quantity Required',
  BIN_LOCATION: 'Bin Location',
  VALUE_CALSS: 'Valuation Class',
  UNRES_QTY: 'Unrestricted Qty',
  UNIT_PRICE: 'Unit Price',
  CREATE_ANOTHER_ORDER: 'Create another order',
  UNLOAD_POINT: 'Unloading Point',
  REQ_DATE: 'Req. Date',
  RECIPIENT: 'Recipient',
  IMMEDIATE_EXECUTION: 'Immediate Execution',
  EQUIPMENT: 'Equipment',
  CREATE_FU_ORDER: 'Create Additional Follow Up order',
  FLOC_VALUE: 'Functional Location',
  ADDITIONAL_WBS_TEXT: 'No additional WBS levels',
  ADDITIONAL_FLOC_TEXT: 'No additional FLOC levels',
  RES_ID: 'Res Id',
  UNIT_OF_MEASURE: 'UOM',
  ITEM_NUMBER: 'Item Number',
  RESERVATION_ITEM: 'Reservation Item',
};

export const mainPortalStrings = {
  HEADER_TEXT: 'Maintenance Portal',
  INSTRUCTIONS_1: 'Welcome to the Gerdau Maintenance Portal!',
  INSTRUCTIONS_2: 'Tap the  ',
  INSTRUCTIONS_3: '  icon below to begin.',
  MAIN_FEATURES: 'Main Features',
  EXTERNAL_RESOURCES: 'External Resources',
  WO_NOT_EXIST: 'Work Order not found. Please Try again!',
};

export const calendarStrings = {
  DAY: 'Day',
  SWING: 'Swing',
  NIGHT: 'Night',
  DAY_SHIFT: 'day_shift',
  SWING_SHIFT: 'swing_shift',
  NIGHT_SHIFT: 'night_shift',
};

export const uiStrings = {
  NUMBER_PAD: 'number-pad',
  FADE: 'fade',
  DATE: 'date',
  TAIL: 'tail',
  DARK_CONTENT: 'dark-content',
  LARGE: 'large',
  STRING: 'string',
  SQUARE: 'square',
  CIRCLE: 'circle',
  CHARECTERS: 'characters',
  DEFAULT: 'default',
  CLIP: 'clip',
  SEARCH: 'search',
  KEYBOARD_SHOW: 'keyboardDidShow',
  KEYBOARD_HIDE: 'keyboardDidHide',
  PHOTO: 'photo',
  FOCUS: 'focus',
  PADDING: 'padding',
  HEIGHT: 'height',
  NEVER: 'never',
  NUMERIC: 'number-pad',
  ON_DRAG: 'on-drag',
  ROUND: 'round',
  NONE: 'none',
};

export const lastConfStrings = {
  NO_CONF_FOUND: 'No confirmation found for the time frame (last 3 days)',
  SEARCH_PLACEHOLDER: 'Search Plant Personnel',
};

export const queryStrings = {
  LAST_CONFIRMATIONS: 'lastConfirmations',
  FLOC: 'functionalLoc-v4',
  SPARES_MANAGEMENT: 'sparesMgt-v1',
};

export const insSchStrings = {
  HEADER_TEXT: 'Inspection Schedule',
  BASIC_SCHEDULE: 'BasicSchedule',
  DAILY_SCHEDULE: 'DailySchedule',
  BASIC_SCHEDULE_HDR: 'Basic Schedule',
  DAILY_SCHEDULE_HDR: 'Daily Schedule',
  NO_INSPECTION_FOUND: 'No Inspection schedules found.',
  CREATE_INSPECTION: 'Please create a inspection in JobAid to manage the schedule.',
  FORM_NAME: 'Form Name',
  AREA: 'Area',
  WORK_CENTER: 'Work Center',
  INSPECTION_TYPE: 'Inspection Type',
  NEW_SCH_LINE: 'New Schedule Line',
  UNDO: 'Undo Unsaved Changes',
  APPLY_CHANGES: 'Apply Changes to Daily Schedule',
  SAVE_SCH: 'Save Schedule',
  SAVE_SUCCESS: 'Inspection schedule has been successfully updated!',
  DATE_FORMAT: 'MM/dd/yyyy',
  API_DATE_FORMAT: 'yyyy-MM-dd',
  UI_DATE_FORMAT: 'MMM, dd yyyy',
  MAX_YEAR: '9999',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  MAX_DATE: '12/31/9999',
  API_MAINTENANCE: 'Application is under maintenance. We will come back soon.',
  UI_MAINTENANCE: 'Inspection Schedule is under Maintenance. We will come back soon.',
};

export const generalStrings = {
  NEW_ATTACHMENT: 'New Attachment',
  WHATS_NEW: 'What"s New',
  NAME: 'name',
  LOGIN: 'Login',
  WHATS_NEW_Q: 'What"s New ?',
  DEPLOY_DATE: 'April 01, 2022',
  OK: 'OK',
  ANDROID: 'android',
  LOGOUT: 'Log Out',
  NO_SELECTION: 'No Selection',
  MORE: '...More',
  MAIN_FEATURES: 'Main Features',
  EXTERNAL_RESOURCES: 'External Resources',
  GERDAU_PEOPLE_PORTAL: 'Gerdau People Portal',
  PHOTO: 'photo',
  IOS: 'ios',
  OBJECT: 'object',
  STRING: 'string',
  PROD: 'PROD',
  TRUE: 'true',
  US10_TAMPA: 'US10 Tampa Office',
  BEARER: 'Bearer',
  BASE_64: 'base64',
  AWS_BUCKET_NAME: 'gln-maintenance-portal',
  BRENDA: 'brenda.wallace',
  KEN_HICKS: 'ken.hicks',
  AUTHORIZATION: 'Authorization',
  HYDROSTATS: 'Hydrostats',
  ATTACHMENTS: 'Attachments',
  E_INSP_KEY: 'einspections',
};

export const loaderMessages = {
  EMPTY: '',
  SEARCH_WO: 'Searching Work Orders',
  WORK_CONFIRMATION: 'Submitting Confirmation',
  WEEK_SCHEDULE: 'Loading Order',
  SCHEDULE: 'Loading Schedule',
  LAST_CONFIRMATIONS: 'Loading Confirmations',
  POST_GM: 'Submitting Goods Movement',
  PREPARING_FORMS: 'Preparing Forms',
  FETCHING_ORDER: 'Fetching Order Details...',
  CREATE_WO: 'Creating Work Order',
  UPDATING_ORDER: 'Updating Work Order',
  GET_PROFILE: 'Getting Profile Data',
  GET_MATERIAL: 'Getting Material Data',
  LOGIN: 'Logging In',
  ADD_NEW_OP: 'Adding New Operation',
  RELOAD_ORDER: 'Reloading Order',
  LOGOUT: 'Signing Off',
  FETCHING_FORM: 'Fetching Forms',
  FETCHING_BASIC_SCHEDULE: 'Fetching Basic Schedule',
  POST_SCHEDULE: 'Saving Changes',
  FETCHING_DAILY_SCHEDULE: 'Fetching Daily Schedule',
  APPLYING_BASIC_SCHEDULE: 'APPLYING_BASIC_SCHEDULE',
  RELOADING_CHANGES: 'Reloading Changes',
  FINDING_ORDER: 'Finding Work Order',
  FINDING_CC: 'Finding Cost Center',
  REFRESHING_MATERIALS: 'Refreshing Materials',
  SENDING_FEEDBACK: 'Sending Feedback',
  INITIALISING_DATA: 'Initializing Data',
  LOADING_TRANSACTIONS: 'Loading Transactions',
  LOADING_WC_ASSIGNMENTS: 'Loading Wc Assignments'
};

export const failedPersonnelStrings = {
  TRY_AGAIN: 'Try Again',
  BACK_2_CONF: 'Back To Confirmation',
  EXIT_WITHOUT_SAVE: 'Exit Without Saving',
  SKIP_USERS: 'Skip These Users',
  PROCESSED_BY: 'is currently being processed by',
  FOLLOWING_CONF_FAILED: '. The following confirmations have failed:',
  WORK_ORDER: 'Work Order ',
};

export const profileStrings = {
  LOGOUT: 'Logout',
  HEADER_TEXT: 'Profile',
  FEEDBACK_TEXT: 'Provide Feedback',
  FEEDBACK: 'Feedback',
  INSTRUCTION: 'Help us make this a better tool for you!',
  IMAGE_TYPE: 'image/png',
  ATTACHMENT_INSTRUCTION: 'Total size of attached files is too big.',
  ATTACHMENT_SIZE: 'Maximum allowed file size is 3.0 MB',
  MS_TYPE: '#microsoft.graph.fileAttachment',
  SUBMIT_FEEDBACK: 'Submit Feedback',
  DATA_TYPE: '@odata.type',
  FILE: 'file://',
  CLOSE: 'Close',
  FEEDBACK_ACK: 'Thank you! Your feedback helps us to make these tools better for you!',
};

export const pages = {
  HOME: 'Home',
  WORK_ORDER: 'Create Work Order',
  CONFIRM: 'Confirmation',
  GERDAU_PEOPLE_PORTAL: 'Gerdau People Portal',
  PRODUCT_CATALOG: 'Product Catalog',
  KRONOS: 'Kronos',
  INTELEX: 'Intelex',
  FEEDBACK: 'Feedback',
  NEW_WO: 'New Work Order',
  SPARES_MANAGEMENT_EXTERNAL_REPAIRS: 'Spares Management - External Repairs',
  SINGLE_CONFIRMATION: 'Single Confirmation',
  EINSPECTION: 'JobAid E-Inspection',
  EINSPECTION_BC: 'JobAid',
  WEEK_SCHEDULE: 'Schedule',
  FOLLOWUP_ORDER: 'Follow Up Work Order',
  INSPECTION_SCHEDULE: 'Inspection Schedule',
  HYDROSTATS: 'Hydrostats',
  CHANGE_WORK_ORDER: 'Work Order Search',
  EDIT_ORDER: 'Edit Order',
  ASSET_VIEW: 'Asset View',
  E_STORES: 'E-Stores',
  CRITICAL_INSPECTION: 'Critical Inspection Review',
  WORK_ORDER_FROM_ASSET: 'Create Work Order from Asset',
  MYSEMEQ: 'MySemeq',
  QUAD_APEX: 'Quad Apex',
  EXTRA_SPACE: ' ',
  IMPERSONATE: 'Impersonate',
  SPARES_MANAGEMENT: 'Spares Management',
  WORK_ORDER_FROM_SPARES: 'Work Order From Spares',
  EXTERNAL_RESOURCES: 'External Resources',
};

export const weekDays = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const changeOrderStrings = {
  WORK_ORDER: 'Work Order #',
  ADD_NEW_OP: 'Add New Operation',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SHORT_TEXT: 'Short Text Preview:',
  SELECT_OPERATION: 'Select Operation',
  ADDING_NEW_OPERATION: 'Adding New Operation',
  RELOADING_ORDER: 'Reloading Order',
  ATTACHMENTS: 'Attachments',
  CREATE_CONFIRMATION: 'Create Confirmation',
  MODIFY: 'Modify',
  CREATE_FOLLOW_UP: 'Create Follow-Up',
  ASSET_VIEW: 'Asset View',
  FIRST_40_CHARACTERS: '(First 40 Characters Display As Short Text)',
};
export const addAttachmentStrings = {
  MB: 'MB',
  EMPTY_STRING: ' ',
};
export const buttonModalStrings = {
  WORK_ORDER: 'Work Order ',
  CREATED: ' Created',
  UPDATED: ' Updated!',

};
export const confirmationContentStrings = {
  OPERATION: 'OPERATION',
  START_DATE_AND_TIME: 'START DATE & TIME',
  END_DATE_AND_TIME: 'END DATE & TIME',
  LONG_TEXT: 'LONG TEXT',
};
export const customDropdownStrings = {
  ASTERIK: '*',
  CLEAR_SELECTION: 'Clear Selection...',
};
export const customNavBarStrings = {
  SELECT_OPERATION: 'Select Operation',
  INITIALISING_DATA: 'Initializing Data',
};
export const dateRangePickerStrings = {
  START_DATE_TO_END_DATE: 'Start Date → End Date',
  CHARACTER_ARROW: '→',
};
export const deleteLineModalStrings = {
  DELETE_LINE: 'Delete Line?',
  ARE_U_SURE_DELETE_LINE: 'Are you sure want to delete this line?',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  ACTION_UNDONE: 'This Action cannot be undone.',
};
export const multiDropdownStrings = {
  ASTERIK: '*',
  NO_SELECTION: 'no selection',
  FUNCTIONAL_LOCATION: 'Functional Location',
  FE: 'FE',
};
export const operationsStrings = {
  OTHER_WORK_CENTERS: 'Other Work Centers',
};
export const orderDetailsStrings = {
  HEADER_DETAILS: 'Header Details',
  ENTERED_BY: 'Entered By',
  SHOW_MORE_HEADER_DETAILS: '+ Show More Header Details',
  SHOW_LESS_HEADER_DETAILS: '+ Show Less Header Details',

};
export const reserveMaterialTileStrings = {
  MATERIAL_DETAIL: 'Material Detail',
  MATERIAL_PO_LONG: 'Material Po Long',
  MORE: ' ...More ',
  OPTIONAL: ' - Optional',
  CHARACTERS: 'characters',

};
export const resourceAssignmentStrings = {
  CLOCK_NO: 'Clock No.',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  DURATION: 'Duration',
  TIME_LESS_THAN_15_MINUTES: 'Time worked cannot be less than 15 minutes.',
  TIME_MORE_THAN_16_HOURS: 'Time worked cannot be greater than 16 hours.',
  HRS: 'hr(s)',
  MINS: 'min(s)',
};
export const scheduleContentStrings = {
  EARLIEST_START: 'EARLIEST START',
  SYSTEM_STATUS: 'SYSTEM STATUS',
  CREATED_BY: 'CREATED BY',
  WORK_CENTER: 'WORK CENTER',
};
export const scheduleListStrings = {
  FOLLOW_UP_BACKLOG: 'Follow-Up Backlog',
  REVISION: 'Revision',
  WORK_CENTER: 'Work Center',
  ORDER: 'Order',
  OPERATION: 'Operation',
  FILTER_LIST: 'Filter List',
  CREATED_BY: 'Created By',
  CLEAR_ALL: 'Clear All',
  CREATED_ON: 'Created On',
  DESCRIPTION: 'Description ',
  FILTER: 'Filter ',

};

export const weekScheduleStrings = {
  ASSIGNED_TO_WC: 'assignedToMyWc',
  PERSON: 'person',
  MY_SCHEDULE: 'My Schedule',
  LOADING: 'loading',
  FOLLOW_UP_BACKLOG: 'Follow-Up Backlog',
  CREATEDBYME: 'createdByMe',
  ASSIGNED_TO_ME: 'Assigned To Me',
  ASSIGNED_TO_MY_WORK_CENTERS: 'Assigned To My WorkCenters',
  MY_WORK_CENTER: 'My Work Center',
  CREATED_BY_ME: 'Created By Me',
  WORK_ORDER: 'Work Order ',
  SCHEDULE: 'Schedule',
  ATTACHMENTS: 'Attachments',

};
export const generalErrorStrings = {
  OK: 'OK',
};
export const workOrderStrings = {
  CREATE_WORK_ORDER: 'Create Work Order',
  CREATE_FOLLOW_UP_WO: 'Create Follow Up Work Order',
  VIEW_WO: 'View Work Order',
  WORK_ORDER_FROM_ASSET: 'workOrderFromAsset',
  FOLLOW_UP: 'followup',
  EDIT_ORDER: 'editorder',
  FETCHING_ORDER_DETAILS: 'Fetching Order Details...',
  ASTERIK: '*',
  INDICATES_REQUIRED: 'INDICATES REQUIRED FIELD',
  RESERVE_MATERIALS: 'Reserve Materials',
  ASSET_VIEW: 'Asset View',
  WORK_ORDER: 'Work Order ',
  OPERATION: 'Operation',
  ATTACHMENTS: 'Attachments',
  CREATE_CONFIRMATION: 'Create Confirmation',
  CREATE_FOLLOW_UP: 'Create Follow-Up',
  EDIT_WORK_ORDER: 'Edit Work Order',
  MODIFY: 'Modify',
  IMM_EXEC_TEXT: 'Create w/ Immediate Execution',
  REQUIRES_KITTING: ' Requires Kitting',
  FUNCTIONAL_LOCATION: 'Functional Location',
  EQUIPMENT: 'Equipment',
  SHORT_TEXT_PREVIEW: 'Short Text Preview:',
  ADD_MATERIAL: 'Add Material',
  EXIT_WITHOUT_CREATING_WO: 'Exit Without Creating Work Order',
  PRODUCT_CATALOG: 'Product Catalog',
  EXIT_WITHOUT_CHANGES: 'Exit Without Changes',
  SAVE_CHANGES: 'Save Changes',
  RES_ID: 'RES ID',
};
export const workConfirmStrings = {
  SEARCH: 'Search',
  SELECT_YOUR_OPERATION: 'Select your operation',
  CONFIMATION: 'Confirmation',
  BACK: 'Back',
  CONTINUE: 'Continue',
  CLOCK_NOT_FOUND: 'Your clock number could not be found.',
  CHECK_WITH_PLANNER: 'Please check with your planner and try again.',
  CONFIRM_WORK_FOR_YOURSELF: 'In the meantime you will only be able to confirm work for yourself on orders that you are already assigned to.',

};
export const singleConfirmationStrings = {
  FAR: 'FAR, ',
  PAR: 'PAR, ',
  NO_ACTION_REQUIRED: 'Ok - No Action Required, ',
  FINAL_CONFIRMATION: 'Final Confirmation (CNF)',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  WORK_ORDER: 'Work Order ',
  OPERATION: 'OPERATION',
  WORK_CONFIRMATION_DETAILS: 'Work Confirmation Details',
  PARTIAL_CONFIRMATION: 'Partial Confirmation (PCNF)',
  PERSONNEL_DETAILS: 'Personnel Details',
  HOME: 'Home',
  START_NEW_CONFIRMATION: 'Start New Confirmation',
  CREATE_FOLLOW_UP_ORDER: 'Create Follow Up Order',
  LOGOUT: 'Logout',
  SUBMIT_SUCCESFULLY: 'Work Confirmation submitted successfully',
  TIME_LESS_THAN_15_MINUTES: 'Time worked cannot be less than 15 minutes.',
  TIME_MORE_THAN_16_HOURS: 'Time worked cannot be greater than 16 hours.',
  CANCEL: 'Cancel',
  OK: 'Ok',
  HRS: 'hrs',
  MINS: 'mins',
  CLOCK_NO: 'Clock No.',
  START_DATE_AND_TIME: 'Start Date & Time',
  END_DATE: 'End Date',
  END_TIME: 'End Time',
  TIME_WORKED: 'Time Worked',
};
export const eStoresStrings = {
  RESERVATION_SAVED: 'Reservation is succesfully saved',
  OK: 'Ok',
  YES: 'Yes',
  NO: 'No',
  PRODUCT_CATALOG: 'Product Catalog',
  SELECT_VALID_OPERATION: ' SELECT A VALID OPERATION (OPTIONAL)',
  SELECTED: 'SELECTED',
  NOT_SELECTED: 'NOT SELECTED',
  VALID: 'VALID',
  OPERATION_NOT_SELECTED: 'OPERATION NOT SELECTED',
  SELECT_A_VALID: 'SELECT A VALID',
  SAVING_RESERVATION: 'Saving Reservation',
  E_STORES: 'E Stores',
  SEARCH_CC: 'Search CC',
  SEARCH_ORDER: 'Search Order',
  WBS_NOT_SELECTED: 'WBS NOT SELECTED',
  SELECTED_WBS_ELEMENT: 'SELECTED WBS ELEMENT',
};
export const userFeedbackStrings = {
  ATTACHMENTS: 'Attachments ',
  MB: 'MB',
};
export const queryKeyStrings = {
  ORDERSCREATEDBYMEV1: 'ordersCreatedByMe-v3',
  BACKLOGSCHEDULE: 'backlogSchedule-v3',
  PERSONALSCHEDULEV1: 'personalSchedule-v3',
  WCSCHEDULEV2: 'wcSchedule-v4',
  FACILSCHEDULEV1: 'facilSchedule-v3',
  LASTCONFIRMATIONSV1: 'lastConfirmations-v3',
  PERSONNALDATAV1: 'personnalData-v3',
  FACILDATAV1: 'facilData-v3',
  SEARCH_ORDER: 'Search Order',
  FLOC: 'functionalLoc-v6',
  SPARES_MANAGEMENT_EXT_RPR: 'sparesMgtExtRpr-v2',
  SEARCH_CC: 'Search CC',
  WBS_DATA: 'WBS DATA',
  AREA_GROUPS: 'AREA GROUPS',
  SPARES: 'SPARES',
  SPARES_INFO: 'sparesInfo',
  VIEW_ORDERS_DATA: 'VIEW ORDERS DATA'
};
export const siteStrings = [
  {
    name: 'Cambridge',
    value: '1301',
  },
  {
    name: 'Whitby',
    value: '1302',
  },
  {
    name: 'Charlotte',
    value: '1321',
  },
  {
    name: 'Cartersville',
    value: '1323',
  },
  {
    name: 'Midlothian',
    value: '1327',
  },
  {
    name: 'Petersburg',
    value: '1330',
  },
  {
    name: 'St.Paul',
    value: '1332',
  },
  {
    name: 'Jackson',
    value: '1333',
  },
  {
    name: 'Wilton',
    value: '1334',
  },
  {
    name: 'St.Paul',
    value: '6304',
  }];

export const newPageStrings = {
  SITE: 'Site',
  AREA_GROUP: 'Area Group',
  SAP_MAT_ID: 'SAP Matl ID',
  COMPONENT_DESCRIPTION: 'Equip | Component Description',
  ADDITIONAL_DESCRIPTION: 'Additional Desc Info',
  CRITICAL_SPARE: 'Critical Spare',
  STORES_ITEM: 'Stores Item',
  QTY_REQUIRED: 'QTY Required',
  QTY_ON_HAND: 'Qty on Hand',
  UOM: 'QOH UOM',
  REPAIRABLE: 'Repairable',
  REPAIR_STATUS: 'Repair Status',
  IS_ASSET: 'Is Asset',
  SPARES_MANAGEMENT: 'Spares Management',
  FETCH_AREA_GROUP: 'Fetching Area Group Data',
  QUICK_FILTER: 'Quick Filter',
  FILTER_BY: 'Filter By...',
  ADD_SPARES: 'Add Spares',
  BULK_IMPORT: 'Bulk Import',
  DOWNLOAD_TEMPLATE: 'Download Template',
  CLEAR_ALL_SECTIONS: 'Clear All Sections',
  SUBMIT: 'Submit',
  SPARES_DETAILS: 'Spares Details',
  SAP_MATERIAL_ID: 'SAP Material Id',
  QUANTITY_REQUIRED: 'Qty Required',
  QUANTITY_ON_HAND: 'Qty on Hand',
  UNIT_OF_MEASURE: 'UOM',
  EQUIPMENT_DESCRIPTION: 'Equipment Description',
  ADDTL_DESCRIPTION: 'Additional Description',
  ADD_ANOTHER_SPARE: 'Add Another Spare',
  FILE: 'File',
  DRAG_FILE: 'Drag your file here or click to upload',
  EXPORT: 'Export',
  DELETE_SELECTED: 'Delete Selected',
  SPARES_SUBMITTED_SUCCESFULLY: 'Spares Data Submitted SucCessfully',
  SUBMITTING_SPARES: 'Submitting SPARES',
  SELECTED_ROWS: 'Spares_management_selected_rows',
  XLS: 'xls',
  SPARES_MANAGEMENT_EXPORT_XLSX: 'spares_management_export',
  SITE_TITLE: 'site_title',
  PLANT_ID: 'plant_id',
  AREAGROUP: 'area_group',
  AREAGROUP_ID: 'area_group_id',
  UNIT_OF_MEASURE_SMALL: 'unit_of_measure',
  UOM_ID: 'uom_id',
  SPARES_MANAGEMENT_TEMPLATE: 'spares_management_template.xls',
  SAVE: 'Save',
  ATTACHMENTS: 'Attachments',
  FETCHING_SPARES_DATA: 'Fetching Spares Data',
  EDITING_REPAIR_STATUS: 'Editing Repair Status',
  EDITING_SPARES_DATA: 'Editing Spares Data',
  DELETING_DATA: 'Deleting Data',
  CLEAR_FILTERS: 'Clear Filters',
  CONFIRM_DELETE: 'Confirm Delete?',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  DELETE_SELECTED_SPARES_MESSAGE: 'Would you like to delete selected spares? This action cannot be undone.',
  EDIT_SPARES: 'Edit Spares',
  DESCRIPTION_OPTIONAL: 'Description(Optional)',
  SPARES_INFO: 'Spares Info',
  EDIT: 'Edit',
  MATERIAL_INFO: 'Material Info',
  EDIT_REPAIR_NOTES: 'Edit Repair Notes',
  FETCHING_FLOC_DATA: 'Fetching Floc Data',
  FETCHING_MATERIAL_DATA: 'Fetching Material Data',
  LAUNCH_GME_LINK: 'https://fiori2.gerdau.net/sap/bc/ui5_ui5/sap/zgme/index.html',
  AREA_SUB_GROUP_1: 'Area Sub Group 1',
  AREA_SUB_GROUP_2: 'Area Sub Group 2',
  SPARES_CLASS: 'Spares Class',
  AC_DC_VALUE: 'AC DC Value',
  CONSTRUCTION_MATERIAL: 'Construction Material',
  FRAME_VALUE: 'Frame Value',
  PHASE_VALUE: 'Phase Value',
  STORAGE_LOCATION: 'Storage Location',
  LOCATION_USED_VALUES: 'Location Used Values',
  NOTES: 'Notes',
  MODEL_NBR: 'Model Nbr',
  INSTALL_TIME: 'Install Time',
  QTY_IN_REPAIR: 'QTY In Repair',
  HORSE_POWER: 'Horse Power',
  SERIAL_NBR: 'Serial Nbr',
  OUTPUT_RPM: 'Output Rpm',
  MFG_PART_NBR: 'Mfg Part Nbr',
  MFG_DRAWING_REF: 'Mfg Drawing Ref',
  ESTIMATED_COST: 'Estimated Cost',
  INPUT_RPM: 'Input Rpm',
  SERVICER_FACTOR: 'Servicer Factor',
  UPDATES: 'Updates',
  AMPERAGE: 'Amperage',
  SAP_EQUIP_ID: 'Sap Equip Id',
  LEAD_TIME: 'Lead Time',
  SAP_FLOC_ID: 'Sap Floc Id',
  MFG_OEM: 'Mfg Oem',
  VOLTAGE: 'Voltage',
  RATIO: 'Ratio',
  VIEW_ENTRIES: 'View Entries',
  CREATE_REFURB_ORDER: 'Create Refurb Order',
  MATERIAL_INFO: 'Material Info',
  DELETE_SPARES: 'Delete Spares',
  FETCHING_DATA_OF_SPARES_ID: 'Fetching  Data of Spares Id ',
  SEND_TO_EXTERNAL_REPAIR: 'Send to External Repair',
  SCRAP_SPARES: 'Scrap Spares',
};
export const materialInfoStrings = {
  MATERIAL_INFO: 'Material Info',
  MATERIAL_DATA: 'Material Data',
  OPEN_PURCHASE_ORDER: 'Open Purchase Orders',
  OPEN_PURCHASE_REQUISTION: 'Open Purchase Requistion',
  VENDOR_AGREEMENTS: 'Vendor Agreements',
};

export const notificationStrings = {
  CONFIGURE_NOTIFICATIONS_FOR_IN_APP_ACCESS: 'Configure Notifications for In-App Access',
  RELOAD_THE_PAGE: 'Reload the Page',
  SELECT_ALLOW_NOTIFICATIONS: 'Select Allow Notifications',
  NAVIGATE_TO_SITE_SETTINGS: 'Navigate to Site Settings',
  CLICK_ON_INFO_ICON: 'Click on the info icon located in the address bar.',
  STEPS_TO_ENABLE_NOTIFICATIONS: 'Steps to Enable Notifications',
}
export const advancedDetails = ['notes', 'model_nbr', 'install_time', 'qty_in_repair', 'horsepower', 'serial_nbr', 'output_rpm', 'created_by', 'mfg_part_nbr', 'mfg_drawing_ref', 'estimated_cost', 'input_rpm', 'servicer_factor', 'updates', 'amperage', 'sap_equip_id', 'lead_time', 'mfg_oem', 'voltage', 'modified_by', 'ratio'];

export const workOrderNavigationObject = {
  OrderType: {
    "key": "Order Type",
    "value": "ZM09",
    "name": "ZM09 - REFURBISHMENT ORDER"
  },
  systemCondition: {
    "key": "System Condition",
    "value": "5",
    "name": "5 - RUNTIME",
  },
  priority: {
    "key": "Priority",
    "value": "4",
    "name": "4 - 2 WEEKS"
  },
  activityType: {
    "key": "Activity Type",
    "value": "136",
    "name": "136 - REPAIR BY EXTERNAL RESOURCES"
  },
};
