/* eslint-disable */
import { Client } from '@microsoft/microsoft-graph-client';
import gerdauImage from '../assets/images/gerdau-image.png';
import { qaTestEmail, sparesEmail } from "../constants/enums";
import apiConfig from '../utilities/config';


class GraphManager {
  static getUserAsync = async (token) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      return graphClient.api('/me').get();
    } catch (err) {
      console.log('err', err);
      throw err;
    }
    // GET /me
  };

  static getPhotoAsync = async (token) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      return graphClient.api('/me/photo/$value').get();
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  static sendFeedbackMail = async (token, content, version) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      const sendMail = {
        message: {
          subject: `Feedback for Maintenance Portal - ${version}`,
          body: {
            contentType: 'Text',
            content: `${content.text}\n\nBrowser Details: ${content.browserDetails}\n\n`,
          },
          toRecipients: [
            {
              emailAddress: {
                address: 'ce502e84.gerdaucld.onmicrosoft.com@amer.teams.ms',
              },
            },
          ],
          attachments: [...content.attachments],
        },
        saveToSentItems: 'false',
      };

      await graphClient.api('/me/sendMail')
        .version('beta')
        .post(sendMail);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  static sendScrapSparesMail = async (token, content, version) => {
    class GraphAuthProvider {
      getAccessToken = async () => token;
    }
    const clientOptions = {
      authProvider: new GraphAuthProvider(),
    };
    const graphClient = Client.initWithMiddleware(clientOptions);
    try {
      const sendMail = {
        message: {
          subject: `Scrap Spares`,
          body: {
            contentType: 'HTML',
            content: `
            <!DOCTYPE html>
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Document</title>
                  <style>
                      /* Add some basic styling for better presentation */
                      body {
                          margin: 0;
                          padding: 0;
                      }
                      p {
                        margin: 0;
                        padding: 0;
                      }
                      .uomData {
                        padding: 0px 5px;
                        color: #83929B;
                        /* color:#BFCCD9; */
                        /* font-family: 'Open-sans Regular'; */
                        text-align: center;
                        background-color: #F1F5F8;
                        border: 1px solid #BFCCD9;
                        border-radius: 3.5px;
                        font-weight: 400;
                        font-size: 12px;
                      }
                      strong {
                        font-size: 14px;
                      }
                  </style>
              </head>
              <body>
                  <div id="hyd-root" style='display: block; width: 100%; font-size: 1rem; font-family: ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'>
                    
                    <!-- HEADER -->
                    <header style="display: block; border-bottom: 3px solid #E5A812; background-color: #FFE2DC;">
                      <table style="width: 100%; background-color: #F2F8FC; padding: 0 24px;">
                        <tr>
                          <td align="center">
                            <table style="width: 100%; margin: 6px 0; border:0;">
                              <tr>
                                <td style="text-align: left;" align="left">
                                  <img src=${gerdauImage} alt="GERDAU" height="50px" />
                                </td>
                                <td style="text-align: right; vertical-align: center; padding-right: 12px;" align="right">
                                  <strong style="display: block; color: #40668C; font-weight: 700; font-size: 12px;">${!content.action_required ? 'ACTION REQUIRED' : 'NO ACTION REQUIRED'}</strong>
                                  <strong style="text-transform: uppercase; color: #003366; font-weight: 700;">SPARES SCRAPPED</strong>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </header>

                    <!-- MESSAGE -->
                    <table style="width: 100%; border-bottom: 1px solid #BFCCD9;">
                      <tr>
                        <td align="center">
                          <table style="width: 100%; padding: 0px 24px;">
                            <tr>
                              <td style="text-align: left; margin-top: 15px;">
                                <h4 style="font-size: 1rem; margin-bottom: 10px; color: #0578BE; text-transform: uppercase;">Hello!</h4>
                                <p style="font-size: 1rem; margin-bottom: 10px;"><strong>Spares Material Scrapped</strong></p>
                                <p style="font-size: 1rem; margin-bottom: 10px;">${!content.action_required ? 'Please initiate a replacement order for the following material. Thank you!' : 'The following spares material has been scrapped but no action is required. Thank you!'}</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>

                    <!-- MAIN -->
                    <table style="width: 100%; background-color: #FAFCFD; padding: 24px;border-spacing: inherit;">
                      <tr >
                        <td style="padding: 0;">
                          <table style="width: 100%; background-color: white;border-bottom: 1px solid #BFCCD9; border-left: 4px solid #0578BE;">
                            <tr >
                              <td style="width: 33.3%; height: 110px;box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Material</p>
                                <strong>${content.sap_matl_id}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Plant</p>
                                <strong>${content.plant_id}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Quantity</p>
                                <strong>2 <span class="uomData">${content.uom_id || ' '}</span></strong>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr >
                        <td style="padding: 0;">

                          <table style="width: 100%; background-color: white;border-bottom: 1px solid #BFCCD9; border-left: 4px solid #BFCCD9;">
                            <tr >
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Date Scrapped</p>
                                <strong>${(new Date(content.document_date)).toISOString().slice(0, 10)}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">PO#</p>
                                <strong>${content.po_text}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Movement Title</p>
                                <strong>${content.movement_title}</strong>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr >
                        <td style="padding: 0;">
                          <table style="width: 100%; background-color: white;border-bottom: 1px solid #BFCCD9; border-left: 4px solid #BFCCD9;
              ;">
                            <tr >
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Vendor <span class="uomData">${content.vendor.value}</span></p>
                                <strong>${content.vendor.name}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Storage Location</p>
                                <strong>${content.stockMr98 ? 'Stock MR98' : 'Non Stock MR99'}</strong>
                              </td>
                              <td style="width: 33.3%; height: 110px; box-sizing: border-box; padding: 1rem; vertical-align: top;">
                                <p style="color: #53626A; margin-bottom: 8px">Scrapped By</p>
                                <strong>${content.recipient}</strong>
                              </td>
                            </tr>
                          </table> 
                        </td>
                      </tr>
                    </table>
                    <table >
                      <tbody>
                      </tbody>
                    </table>

                    <!-- FOOTER -->
                    <footer style="display: block; border-top: 2px solid #FFCC00;">
                      <table style="width: 100%">
                        <tr>
                          <td align="center">
                            <table style="max-width: 1000px; margin: 15px 25px; border:0;">
                              <tr>
                                <td>
                                  <p style="text-align: left; color: #8C969B; font-size: 0.8rem;">This message may include restricted, legally privileged, and/or confidential information. If you received this message by mistake please delete it immediately and inform us about it. This message will be considered as originated from Gerdau or its subsidiaries only when formally confirmed by its officers authorized for that.</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </footer>

                  </div>
              </body>
              </html>
          `,
          },
          toRecipients: apiConfig.ENVIRONMENT == 'QA' ? qaTestEmail : sparesEmail[content.plant_id].map(email => {
            return {
              emailAddress: {
                address: email,
              },
            }
          }),
          attachments: [],
        },
        saveToSentItems: 'false',
      };

      await graphClient.api('/me/sendMail')
        .version('beta')
        .post(sendMail);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export default GraphManager;

/* eslint-enable */
