import PropTypes from 'prop-types';
import React from 'react';
import { WORK_CONFIRMATION } from '../../pages/work-confirm/work-confirm.strings';
import ImgRightArrow from '../../../assets/images/right-arrow.png';
import SectionHeader from '../section-header/index';
import imgAddAttachment from '../../../assets/images/add-attachment.png';
import ContentField from '../content-field';
import CustomButton from '../custom-button/index';
import styles from './operations.module.scss';
import { operationsStrings, orderStrings } from '../../../constants/strings';

export default function Operations({
  mnWrkCtrId, operations, onOperationClick, addOperation,
}) {
  const [myWcCollapse, setMyWcCollapse] = React.useState(true);
  const [myOtherWcCollapse, setOtheryWcCollapse] = React.useState(true);

  const onMyWCClick = () => setMyWcCollapse(!myWcCollapse);
  const onOtherWCClick = () => setOtheryWcCollapse(!myOtherWcCollapse);
  return (
    <div className="row no-gutters gx-0 gy-0">
      <div className={`${styles.woinfosWrapper} col`}>
        <div className={`${styles.selectOpHeader}`}>
          <div>
            <h4 className={`${styles.titleSection} row mt-4 ml-4`}>
              {WORK_CONFIRMATION.OPERATIONS_TITLE}
            </h4>
          </div>
          {
            addOperation
            && (
              <div className="pr-3 d-flex align-items-center">
                <CustomButton
                  outline
                  onPress={addOperation}
                  icon={imgAddAttachment}
                  value="Add Operation"
                />
              </div>
            )
          }
        </div>
        <SectionHeader text={orderStrings.MY_WC} hasCollapse isOpen={myWcCollapse} onClick={onMyWCClick} />
        {
          myWcCollapse
            && (
              <>
                {
                  operations
                    .filter(({ workCenter }) => workCenter === mnWrkCtrId)
                    .map((operation, operationIndex) => (
                      (
                        <div
                          key={operation.description + operationIndex}
                          className={`${styles.operationsSingularwrapper} d-flex justify-content-between align-items-center pt-3 pb-3 `}
                          onClick={() => onOperationClick(operation)}
                          role="presentation"
                        >
                          <div className="col-11">
                            <span className={`${styles.operationsSingulardescription} row gx-0 no-gutters  pl-4 `}>
                              {operation.description}
                            </span>
                            <span className="row gx-0 no-gutters pl-4 mt-1">
                              <ContentField>
                                {operation.workCenter}
                              </ContentField>
                            </span>
                          </div>
                          <div className="col-1">
                            <img src={ImgRightArrow} alt="" />
                          </div>
                        </div>
                      )
                    ))
                }
              </>
            )
        }

        {
          operations
            .filter(({ workCenter }) => workCenter !== mnWrkCtrId).length > 0
            && <SectionHeader subHeading text={operationsStrings.OTHER_WORK_CENTERS} hasCollapse isOpen={myOtherWcCollapse} onClick={onOtherWCClick} />
        }
        {myOtherWcCollapse && operations
          .filter(({ workCenter }) => workCenter !== mnWrkCtrId)
          .map((operation) => (
            (
              <div
                key={operation.description}
                className={`${styles.operationsSingularwrapper} d-flex justify-content-between align-items-center pt-3 pb-3 `}
                onClick={() => onOperationClick(operation)}
                role="presentation"
              >
                <div className="col-11">
                  <span className={`${styles.operationsSingulardescription} row gx-0 no-gutters  pl-4 `}>
                    {operation.description}
                  </span>
                  <span className="row gx-0 no-gutters pl-4 mt-1">
                    <ContentField>
                      {operation.workCenter}
                    </ContentField>
                  </span>
                </div>
                <div className="col-1">
                  <img src={ImgRightArrow} alt="" />
                </div>
              </div>
            )
          ))}
      </div>
    </div>

  );
}

Operations.propTypes = {
  addOperation: PropTypes.func,
  mnWrkCtrId: PropTypes.string,
  onOperationClick: PropTypes.func,
  operations: PropTypes.arrayOf(PropTypes.shape({})),
};

Operations.defaultProps = {
  mnWrkCtrId: '',
  onOperationClick: () => { },
  operations: [],
  addOperation: () => { },
};
